import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Assignment as AssignmentIcon,
  ExpandLess,
  ExpandMore,
  FilterList as FilterListIcon,
  Add as AddIcon,
  MoreHoriz as MoreHorizIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

import axios from "axios";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";
import AddEquipment from "./AddEquipment";
import ViewEquipment from "./ViewEquipment";
import FacilityFilter from "../../Roles and Permissions/Filters/FacilityFilter";
import DateRangePickerComponent from "../../Roles and Permissions/Filters/Date";
import ApplyButton from "../../Roles and Permissions/Filters/ApplyButton";
import ResetButton from "../../Roles and Permissions/Filters/ResetButton";
import CountryFilter from "../../Roles and Permissions/Filters/UserFilters/CountryFilter";
import { api } from "../../../../utils/api";
import FilterSidebar from "../../../../Components/Masters/Filter/FilterSidebar";
import LocationFilter from "../../Roles and Permissions/Filters/UserFilters/LocationFilter";
import EquipmentTypeFilter from "../../Roles and Permissions/Filters/UserFilters/EquipmentTypeFilter";
import FuelTypeFilter from "../../Roles and Permissions/Filters/UserFilters/FuelTypeFilte";

const ListEquipment = () => {
  const [facilities, setEquipment] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addFacility, setAddFacility] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Country: false,
    Location: false,
    EquipmentType: false,
    FuelType: false,
  });
  const [viewFacility, setViewFacility] = useState(false);
  const [dataView, setdataView] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected facility name
  const [equipmentType, setEquipmentType] = useState([]); // Store selected facility name
  const [fuelType, setFuelType] = useState([]); // Store selected facility name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [errors, setErrors] = useState({
    Date: false,
    Facility: false,
    Country: false,
    EquipmentType: false,
    FuelType: false,
    Location: false,
  });

  const filters = ["Date", "Location", "EquipmentType", "FuelType"];

  // Function to render the selected filter component based on user's choice

  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllIndustrialEquipment`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              errors: errors.Date,
            }}
          />
        );
      case "Location":
        return (
          <LocationFilter
            url={`${api}/master/getAllIndustrialEquipment`}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            errors={errors.Location}
          />
        );
      case "EquipmentType":
        return (
          <EquipmentTypeFilter
            url={`${api}/master/getAllEquipmentType`}
            selectedEquipment={equipmentType}
            setSelectedEquipment={setEquipmentType}
            errors={errors.EquipmentType}
          />
        );

      case "FuelType":
        return (
          <FuelTypeFilter
            url={`${api}/master/getAllIndustrialEquipment`}
            selectedFuelType={fuelType}
            setSelectedFuelType={setFuelType}
            errors={errors.FuelType}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            setDrawerFilterOpen={setDrawerFilterOpen}
            // setFilteredRoles={setFilteredRoles}
          />
        );
    }
  };
  const handleMenuClick = (event, facility) => {
    setAnchorEl(event.currentTarget);
    setdataView(facility);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (e) => {
    handleMenuClose();
    setAddFacility(true);
  };

  const handleView = () => {
    handleMenuClose();
    setViewFacility(true);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = () => {
    if (dataView && dataView._id) {
      axios
        .put(
          `${api}/master/deleteEquipmentProcess`,
          { deactivated: true },
          {
            params: {
              id: dataView._id, // Using the correct facility ID
            },
          }
        )
        .then((res) => {
          setDeleteDialog(false);
          setsnackOpen({
            open: true,
            message: res.data.message,
            severity: "success",
          });
          setEquipment(
            facilities.filter((item) => item._id !== res.data.data._id)
          );
        })
        .catch((err) => {
          console.log(err);
          setsnackOpen({
            open: true,
            message: err.response?.data?.message ?? "Network Error",
            severity: "error",
          });
        });
    } else {
      setsnackOpen({
        open: true,
        message: "No facility selected for deletion",
        severity: "error",
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // fetching facility data
  const fetchFacilities = async (filters = filtersToApply) => {
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      const response = await axios.get(
        `${api}/master/getAllIndustrialEquipment?${queryParams}`
      );

      setEquipment(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, [page, rowsPerPage, sortConfig]);

  // default filters to apply
  const filtersToApply = {
    location: selectedCountries.join(","),
    equipmentType: equipmentType.join(","),
    fuelType: fuelType.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {
      Date: "",
      Location: "",
      FuelType: "",
      EquipmentType: "",
    };

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date

      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate >= endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }

    if (selectedFilter === "EquipmentType" && equipmentType.length === 0) {
      newErrors.EquipmentType = "Please select EquipmentType";
      isValid = false;
    }

    if (selectedFilter === "FuelType" && fuelType.length === 0) {
      newErrors.FuelType = "Please select FuelType";
      isValid = false;
    }

    if (selectedFilter === "Location" && selectedCountries.length === 0) {
      newErrors.Location = "Please select Location";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;

    fetchFacilities();
    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  // Applying reset filters
  const handleResetFilters = () => {
    if (selectedFilter === "Date") {
      setStartDate(null);
      setEndDate(null);
      setErrors({ ...errors, Date: false });
      setAppliedFilters({ ...appliedFilters, Date: false });
    }
    if (selectedFilter === "Location") {
      setSelectedCountries([]);
      setErrors({ ...errors, Location: false });
      setAppliedFilters({ ...appliedFilters, Location: false });
    }

    if (selectedFilter === "EquipmentType") {
      setEquipmentType([]);
      setErrors({ ...errors, EquipmentType: false });
      setAppliedFilters({ ...appliedFilters, EquipmentType: false });
    }
    if (selectedFilter === "FuelType") {
      setFuelType([]);
      setErrors({ ...errors, FuelType: false });
      setAppliedFilters({ ...appliedFilters, FuelType: false });
    }

    fetchFacilities({
      location:
        selectedFilter === "Location" ? [] : selectedCountries.join(","),
      equipmentType:
        selectedFilter === "EquipmentType" ? [] : equipmentType.join(","),
      fuelType: selectedFilter === "FuelType" ? [] : fuelType.join(","),
      startDate:
        selectedFilter === "Date"
          ? ""
          : startDate
          ? startDate.format("YYYY-MM-DD")
          : "",
      endDate:
        selectedFilter === "Date"
          ? ""
          : endDate
          ? endDate.format("YYYY-MM-DD")
          : "",
    });
  };
  // handle the applied filters for styling
  const handleAppliedFilters = () => {
    setAppliedFilters({
      ...appliedFilters,
      Date: startDate || endDate ? true : false,
      Country: selectedCountries.length > 0 ? true : false,
      Location: selectedCountries.length > 0 ? true : false,
      EquipmentType: equipmentType.length > 0 ? true : false,
      FuelType: fuelType.length > 0 ? true : false,
    });
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({ Date: false, Role: false });
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setsnackOpen({ ...snackOpen, open: false })}
      >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>

      <AddEquipment
        addFacility={addFacility}
        setAddFacility={setAddFacility}
        selectedFacility={dataView}
        setsnackOpen={setsnackOpen}
        fetchFacilities={fetchFacilities}
      />
      <ViewEquipment
        viewFacility={viewFacility}
        setViewFacility={setViewFacility}
        selectedFacility={dataView}
        setsnackOpen={setsnackOpen}
        fetchFacilities={fetchFacilities}
      />
      {deleteDialog && (
        <Dialog
          open={deleteDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => setDeleteDialog(false)}
            >
              Cancle
            </Button>
            <Button sx={{ textTransform: "none" }} onClick={handleDeleteAggree}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => handleFilterDrawer()}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{
                    setSelectedFilter,
                    selectedFilter,
                    filters,
                    appliedFilters,
                  }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                  }}
                >
                  {renderSelectedFilter()}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: "3%",
                      position: "fixed",
                      bottom: "20px",
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    <ApplyButton handleApply={handleApplyFilters} />
                    <ResetButton handleReset={handleResetFilters} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                margin: "8px 0px 20px 0px",
                textDecoration: "underline",
              }}
            >
              Masters
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {(selectedCountries.length > 0 ||
                selectedFacilities.length > 0 ||
                startDate ||
                endDate) && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>

            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                setAddFacility(true);
                setdataView(null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "16px",
          }}
        >
          Industrial Equipment Master
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "12% 15% 15% 18% 15% 15% 10%",
            width: "100%",
            border: "1px solid #ddd",
          }}
        >
          {[
            { label: "Month", key: "createdAt" },
            { label: "Location", key: "location" },
            { label: "Facility", key: "facility.facilityName" },
            { label: "Equipment Name", key: "name" },
            { label: "Equipment Type", key: "equipmentType.name" },
            { label: "Qty", key: "noOfEquipment" },
          ].map(({ label, key }) => (
            <Box
              key={key}
              sx={{
                padding: "12px",
                fontWeight: "bold",
                textAlign: "left",
                borderRight: "1px solid #ddd",
                borderBottom: "1px solid #ddd",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {label}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <IconButton size="small" onClick={() => handleSort(key, "asc")}>
                  <ExpandLess
                    sx={{
                      color:
                        sortConfig.key === key && sortConfig.direction === "asc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleSort(key, "desc")}
                >
                  <ExpandMore
                    sx={{
                      color:
                        sortConfig.key === key &&
                        sortConfig.direction === "desc"
                          ? "blue"
                          : "black",
                      margin: "-10px",
                      fontSize: "20px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              padding: "20px",
              fontWeight: "bold",
              textAlign: "center",
              borderBottom: "1px solid #ddd",
            }}
          >
            Action
          </Box>

          {/* Table Body */}
          {facilities.map((facility, index) => (
            <React.Fragment key={index}>
              <Typography
                sx={{
                  padding: "2px 5px",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "black",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  marginRight: "9px",
                  marginLeft: "14px",
                }}
              >
                {new Date(facility.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>

              {[
                { value: facility.location, maxWidth: "200px" },
                { value: facility.facility.facilityName, maxWidth: "143px" },
                { value: facility.name, maxWidth: "171px" },
                { value: facility.equipmentType.name, maxWidth: "143px" },
                { value: facility.noOfEquipment, maxWidth: "142.5px" },
              ].map(({ value, maxWidth }, idx) => (
                <Box
                  key={idx}
                  sx={{
                    padding: "2px 0px",
                    borderRight: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: maxWidth,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "black",
                      lineHeight: "1.5",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "block",
                      maxWidth: "100%",
                      margin: "9px",
                    }}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
              <Typography
                sx={{
                  padding: "2px 8px 2px 8px",
                  textAlign: "center",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <IconButton
                  onClick={(event) => handleMenuClick(event, facility)}
                >
                  <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
                </IconButton>
              </Typography>
            </React.Fragment>
          ))}
        </Box>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
                // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ListEquipment;
