import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button,Checkbox,
  Chip ,IconButton,OutlinedInput,Select}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from '../../../utils/companyId';

function AddRole(props) {
      const [moduleName, setmoduleName] = useState([
        "Select All",
        "Environment Dashboard",
"Environment | Scope 1",
"Environment | Scope 2",
"Environment | Scope 3",
"Social Dashboard",
"Governance Dashboard",
"Setup",
"Roles & Permission",
"Facility Master",
"Masters",
"ESG Reporting",
"GRI",
"BRSR",
"CDP",
"Value Chain",
"Water Management"
      ]);
      const [openSelect, setOpen] = useState(false);
      const [selectedModuleName,setselectedModuleName] = useState([]);
      const [PermissionsName, setPermissionsName] = useState([]);
      const [alreadyRole,setAlreadyUser] = useState(false);
      const [errors,setErrors]=useState({});
      const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [fromValue,setFromValue]= useState({
    company:companyId,
    remark:"",
    name:"",
    allowed_permissions:PermissionsName,
    moduleName:selectedModuleName
  })

  useEffect(() => {
axios.get(`${api}/auth/getPermissions`).then((res)=>{
  console.log(res.data.data,"resres")
  setPermissionsName(res.data.data)
}).catch((err)=>{
  console.log(err,"err")
})
  }, []); 
  useEffect(()=>{
    const fetchUserData = async () => {
 await   axios.get(`${api}/auth/getRoleById`,{
      params: {
        Id: props.index // Pass as query parameter
      }
    }).then((res)=>{
      console.log("data after fetch roleId",res.data.data)
      setFromValue({
        company:res.data.data.company,
        remark:"",
        name:res.data.data.name,
        allowed_permissions:res.data.data.allowed_permissions.map((item) => item.name).join(',')       
      })
      setAlreadyUser(props.index===0? false: true)
    }).catch((err)=>{
      console.log(err)
    })}
    if (props.index !== 0) {
      // Fetch user data when props.index is not 0
      fetchUserData();
    } else {
      setFromValue({
      company:companyId,
      remark:"",
      name:"",
      allowed_permissions:[]
    })
    }
  },[props.index])
  
  console.log("role",props.index)
  // const handleModuleSelection = (id) => {
  //   // Toggle selection of the module
  //   let newSelected = [...fromValue.allowed_permissions];
  //   if (newSelected.includes(id)) {
  //     // If the id is already selected, remove it
  //     newSelected = newSelected.filter((selectedId) => selectedId !== id);
  //   } else {
  //     // If not selected, add it to the list
  //     newSelected.push(id);
  //   }
  //   // Update the state
  //   console.log("After selection:", newSelected);

  //   setFromValue({
  //     ...fromValue,
  //     allowed_permissions: newSelected,
  //   });
  // };
  


  const handleModuleModleChange = (event) => {
    const selectedValues = event.target.value; // MUI returns an array of selected values
    console.log("Selected values:", selectedValues); // Log the selected values
  
    // Update the state with the new selected values
    setFromValue({
      ...fromValue,
      moduleName: selectedValues, // Set the updated array
    });
    setOpen(false);
  };
  

  const handleModuleModleSelection = (id) => {
    // Toggle selection of the module
    let newSelected = [...selectedModuleName];
    console.log("newSelected====",newSelected)
    // if (newSelected.includes(id)) {
      // If the id is already selected, remove it
      
      // newSelected = newSelected.filter((selectedId) => selectedId !== id);
      console.log("newSelected====",newSelected)
    // } else {
      // If not selected, add it to the list
      newSelected.push(id);
      // console.log("newSelected====",newSelected)
    // }
    // Update the state
    // setFromValue({
    //   ...fromValue,
    //   moduleName   : newSelected,
    // });
    setFromValue({
      ...fromValue,
      // allowed_permissions: newSelected,
    });
    setselectedModuleName(newSelected)
  };


  const handleModuleChange = (event) => {
    const selectedValues = event.target.value; // MUI returns an array of selected values
    console.log("Selected values:", selectedValues); // Log the selected values
  
    // Update the state with the new selected values
    //  setFromValue({
    // ...fromValue,
    // //   allowed_permissions: selectedValues, // Set the updated array
    //  });
    setPermissionsName(selectedValues)
  };
  

  // const handleModuleSelection = (id) => {
  //   // Toggle selection of the module
  //   let newSelected = [...fromValue.allowed_permissions];
  //   console.log("newSelected====",newSelected)
  //   if (newSelected.includes(id)) {
  //     // If the id is already selected, remove it
      
  //     // newSelected = newSelected.filter((selectedId) => selectedId !== id);
  //     console.log("newSelected====",newSelected)
  //   } else {
  //     // If not selected, add it to the list
  //     newSelected.push(id);
  //     console.log("newSelected====",newSelected)
  //   }
  //   // Update the state
  //   setFromValue({
  //     ...fromValue,
  //     allowed_permissions: newSelected,
  //   });
  // };
  

  const handleModuleSelection = (id) => {
    setSelectedPermissions((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };
  
  const handleChange =(e)=>{
    const {name,value}=e.target
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }
  // const handleModule =(e)=>{
  //   const {name,value}=e.target
  // console.log(e.key,value,"forModule")
  //   setFromValue({
  //     ...fromValue,
  //     [name]:value
  //   })

  // }
  const handleModule = (event) => {
    const {
      target: { value },
    } = event;
    setFromValue({
      ...fromValue,
      allowed_permissions: typeof value === 'string' ? value.split(',') : value,
    });
  };
  

  const validateForm = () => {
    const newErrors = {};
    
    const { 
      name,
      allowed_permissions
    } = fromValue;
  
  
    // Validate `name`
    if (!name || name.trim() === "") {
      newErrors.name = "Name is required.";
    }
  
    // Validate `allowed_permissions`
    if (!allowed_permissions || allowed_permissions === "Tap to Select") {
      newErrors.allowed_permissions = "Please select the any option";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  //  console.log("propssetsnackOpen",props.setsnackOpen)
const Save =()=>{
  if (!validateForm()) {
    return;
  }
  console.log(fromValue)
  axios({
    method: alreadyRole ? 'put' : 'post',
    url: alreadyRole?`${api}/auth/updateRole`:`${api}/auth/createRole`,
    data:  fromValue,  // Send data only if it's a POST request
    params: alreadyRole ? { Id: props.index } : null  // Send params only for PUT request
  })
  // axios.post(`${api}/auth/createRole`,fromValue)
  .then((res)=>{
    console.log(res,res.data.data)
    props.setValue(false)
    console.log("res.data.data.message",res.data.message,res.data)
    props.setsnackOpen({open:true,message:res.data.message,severity:"success"});
    props.fetchRoles()
    if (!alreadyRole) {
      localStorage.setItem("roleId", res.data.data._id);
    }
    
  }).catch((err)=>{
    console.log(err.response.data.message,"props",props.setsnackOpen,"errShowForRole")
    if (err?.response?.data?.message) {
      // If there is a message in the error response, use it
      props.setsnackOpen({ open: true, message: err.response.data.message, severity: "error" });
    }
    //  err?.response?.data?. props.setsnackOpen({open:true,message:err?.response?.data?.message,severity:"error"})
  })
}


  const list = () => (
    <Box display="flex" gap={3}sx={{width:400,
      // maxWidth:"100%",
      height: '100vh',
      display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>props.setValue(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box  sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll",
}}>
      <Typography  variant="h6" sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>{props.index !== 0?"Edit Role":"Add New Role"}</Typography>  
       
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Name of Role     
          </Typography>
         
          <CustomTextField
        //   select
      variant="outlined"
      value={fromValue.name}
      onChange={handleChange}
      fullWidth
      name="name"
      placeholder="Enter Name"
      error={!!errors.name} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.name ? '#D34040' : undefined },
      }}
    >
    </CustomTextField>
    {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
       Module's Name    
          </Typography>
     {selectedModuleName.map((item)=>{
      return  <Chip
        key={item}
        label={item}
        // onDelete={() => handleDelete(item)}
        sx={{
          bgcolor: "#1C486B",
          color: "white",
          height:"28px",
          "& .MuiChip-deleteIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        }}
        deleteIcon={
          <IconButton sx={{ color: "white"}}>
            <CancelIcon sx={{height:"18px",width:"18px"}}/>
          </IconButton>
        }
      />
     })}  
       
          <Select
          // select
           multiple
           open={openSelect}
        
          //  input={<OutlinedInput />}
         variant="outlined"
        //  value={fromValue.moduleName}
        value={selectedModuleName}
          onChange={handleModuleModleChange}
         fullWidth
         name="moduleName"
         onClose={() => setOpen(false)} 
         onOpen={() => setOpen(true)}  
        placeholder="Tap to Select"
      renderValue={(selected) => {
        console.log("selectedselected",selected)
        if (!Array.isArray(selected)) return "";
        if (selected.length === 0) return "Tap to Select";
    
        return selected.join(", ");; // Join selected values with a comma
      }}
      error={!!errors.moduleName}
      displayEmpty
      InputLabelProps={{
        'aria-label': 'Without label',
        style: { color: errors.moduleName ? '#D34040' : undefined },
      }}
      sx={{height:"40px"}}
    >
       <MenuItem disabled value="">
    {/* Tap to Select */}
  </MenuItem>
      {/* <MenuItem value={fromValue.moduleName===""?"Tap to Select":fromValue.moduleName} disabled>
      {fromValue.moduleName===""?"Tap to Select":fromValue.moduleName} 
  </MenuItem> */}
       {moduleName.map((item,i) => (
        // console.log("item",item.name,i)
        <MenuItem key={i} 
        value={item}
        onClick={()=>handleModuleModleSelection(item)}
        //  onClick={() => handleModuleSelection(i)} 
        >
          {item}
        </MenuItem>
      ))}
   

    </Select>
    {errors.moduleName && (
          <Typography variant="body2" color="#D34040">
            {errors.moduleName}
          </Typography>
        )}


<Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
  Permissions
</Typography>
{console.log("console.log(fromValue.allowed_permissions)", fromValue.allowed_permissions)}

<Select
   multiple // Enables multi-select functionality
  variant="outlined"
  input={<OutlinedInput />}
  value={PermissionsName} // Ensure this is always an array
  onChange={handleModuleChange}
  fullWidth
  name="allowed_permissions"
  renderValue={(selected) => {
    if (!Array.isArray(selected)) return ""; // Prevent error if not an array
    if (selected.length === 0) return "Tap to Select"; // Placeholder

    // Return a comma-separated string of selected item names
    return selected
      .map((id) => {
        const selectedItem = PermissionsName.find((item) => item?._id === id);
        return selectedItem ? selectedItem.name : null; // Ensure we return null instead of undefined
      })
      .filter(Boolean) // Filter out null values before joining
      .join(", ");
  }}
  error={!!errors.allowed_permissions}
  displayEmpty
  inputProps={{ 'aria-label': 'Without label' }}
  sx={{ mt: 1, height: "40px" }}
>
  <MenuItem disabled value="">
    {/* Tap to Select */}
  </MenuItem>
  {PermissionsName.map((item) => (
    <MenuItem
      key={item._id}
      value={item._id}
      // onClick={() => handleModuleSelection(i)} // Custom selection handler
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {item.name}
      <Checkbox
        checked={handleModuleSelection(item._id)} // Check if the item is selected
        sx={{ ml: 2}} // Adds margin to the left for spacing
      color="success"
      />
    </MenuItem>
  ))}
</Select>






{errors.allowed_permissions && (
  <Typography variant="body2" color="#D34040">
    {errors.allowed_permissions}
  </Typography>
)}




    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Remark  
          </Typography>
         
          <TextField
          // select
          rows={2}
      variant="outlined"
      value={fromValue.remark}
       onChange={handleChange}
      fullWidth
      name="remark"
      placeholder="Brief"
      multiline
    >
      
    </TextField>
 
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
             onClick={Save}

            >
              Submit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
            

              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.value}
                onClose={()=>props.setValue(false)}
              >
                {list()}
              </Drawer>
              
      
      
        </div>
      );
}

export default AddRole;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  