import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'

const ColumnHeadings = ({title,handleSort,field,sortConfig}) => {
  return (
         <Typography sx={{ fontWeight: "bold", padding: "8px 12px", marginBottom:"-10px", textAlign: "left", borderRight: "1px solid #ddd", display: "flex", flexDirection: "row" }}>
{title}    
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2px",
      marginLeft: "10px"
    }}
  >
  <IconButton
    size="small"
    onClick={() => handleSort(field, "asc")}
  >
    <ExpandLess
      sx={{
        color: sortConfig.key === field && sortConfig.direction === "asc" ? "blue" : "black",
        margin: "-10px",
        fontSize: "20px",
        color: "black",
      }}
    />
  </IconButton>
  <IconButton
    size="small"
    onClick={() => handleSort(field,"desc")}
  >
  <ExpandMore
    sx={{
      color: sortConfig.key === field && sortConfig.direction === "desc" ? "blue" : "black",
      margin: "-10px",
      fontSize: "20px",
      color: "black",
    }}
    />
  </IconButton>
  </Box>
  </Typography>
  )
}

export default ColumnHeadings