


import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Stack,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  Drawer,
  Pagination,
  PaginationItem,
  Alert,
  Snackbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Category, ExpandLess, ExpandMore, MoreHoriz } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api } from "../../../../utils/api";
import FilterPage from "../../../../Components/Masters/Filter/FilterPage";
import FilterSidebar from "../../../../Components/Masters/Filter/FilterSidebar";
import AddMachinery from "./AddMachinery";
import DateFilter from "./Filters/Date";
import MachineTypeFilter from "./Filters/MachineTypeFilter";

import CategoryFilter from "./Filters/CategoryFilter";
import ApplyButton from "../../Roles and Permissions/Filters/ApplyButton";
import ResetButton from "../../Roles and Permissions/Filters/ResetButton";
import MachineryNameFilter from "./Filters/MachineryName";
import FuelTypeFilter from "./Filters/FuelType";
import View from "./View";



import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Machinery = () => {
const [anchorEl, setAnchorEl] = useState(null);
const [selectedUserIndex, setSelectedUserIndex] = useState(null);
const [machinery, setMachinery] = useState([]);
const [filteredMachinery, setFilteredMachinery] = useState([]);
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(10);
const [totalPages, setTotalPages] = useState(0);
const [isDrawerOpen, setIsDrawerOpen] = useState(false);
const [index, setIndex] = useState(0);
const [deleteDialog, setDeleteDialog] = useState(false);
const [indexMachinery, setIndexMachinery] = useState(null);
const [appliedFilters, setAppliedFilters] = useState({
  Date: false,
  Category: false,
  MachineryName: false,
  FuelType: false,
  MachineType: false,
});
const [selectedFilter, setSelectedFilter] = useState("Date");
const filters = [ "Date", "Category", "MachineType", "MachineryName", "FuelType" ];
const [DeleteIndex, setDeleteIndex] = useState(null);
const [startDate, setStartDate] = useState(null);
const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
const [endDate, setEndDate] = useState(null);
const [snackOpen,setsnackOpen]= useState({open:false,message:"",severity:""});
const[errors, setErrors] = useState({
  Date: false,
  MachineryName: false,
  Category: false,
  FuelType: false,
  MachineType: false,
  // CompanyName: false,
  // Facility: false,
  // FunctionalityOfRole: false,
});
const [selectedCategories, setSelectedCategories] = useState([]);
const [categoryFilter, setCategoryFilter] = useState([]);
const [selectedMachineryNames, setSelectedMachineryNames] = useState([]);
const [machineryNames, setMachineryNames] = useState([]);
const [selectedFuelTypes, setSelectedFuelTypes] = useState([]);
const [fuelTypes, setFuelTypes] = useState([]);
const [machineTypes, setMachineTypes] = useState([]);
const [selectedTypes, setSelectedTypes] = useState([]);

const [viewMachinery, setViewMachinery] = useState(false);
const [dataView,setDataView]=useState(null);
const [sortConfig, setSortConfig] = useState({
  key: "createdAt",
  direction: "desc",
});
const [selectedMachinery, setSelectedMachinery] = useState(null);

const fetchMachinery = async (filters = filtersToApply) => {
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        // sortField: "name", // Sort configuration
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams); // Debugging line

      const response = await axios.get(`${api}/master/getAllMachinery?${queryParams}`);

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setMachinery(response.data.data);
      setTotalPages(response.data.totalPage);
      setFilteredMachinery(response.data.data);
    //   setFilteredRoles(response.data.data);
    //   setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

// const fetchMachinery = async (filters = filtersToApply) => {
//   try {
//     const queryParams = new URLSearchParams({
//       ...filters,
//       page,
//       limit: rowsPerPage,
//     }).toString();

//     console.log("Query Params sent to API:", queryParams);

//     const response = await axios.get(`${api}/master/getAllMachinery?${queryParams}`);
//     console.log("Fetched data after filtering:", response.data.data); // Log the data here

//     setMachinery(response.data.data);
//     setTotalPages(response.data.totalPage);
//     setFilteredMachinery(response.data.data);
//   } catch (error) {
//     console.error("Error fetching machinery:", error);
//   }
// };

  useEffect(() => {
    fetchMachinery(); 
  }, [page, rowsPerPage, sortConfig]);

  const handleAddRole = () => {
    setIsDrawerOpen(true);
    setIndexMachinery(undefined);
  };


const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };
  const open = Boolean(anchorEl);

  const handleMenuClick = (event, machinery) => {

    setAnchorEl(event.currentTarget);
    setSelectedUserIndex(machinery);
    setDataView(machinery)
    // setRoleEdit(data._id);
    // setDeleteIndexEdit(
    //   // data.allowed_permissions.map((item) => item.name).join("") ===
    //   //   "delete Access"
    //   //   ? data._id
    //   //   : 0
    //   data._id
    // );
  };


  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };
  // const handleView=(index)=>{
  //   setindexUserView(index)
  //  console.log("hii",index)
  //   setAnchorEl(null);
  //   setSelectedUserIndex(null);
  //    setIsDrawerOpenEdit(true)
  //    setViewInner(true)
  //   // openDrawerEdit();
  // }
  
  
  const handleView = () => {
    handleMenuClose();
    setViewMachinery(true);
  };

  const handleEdit=()=>{
    setIndexMachinery(dataView)
     console.log("hii",dataView)
      setAnchorEl(null);
      setSelectedUserIndex(null);
      setIsDrawerOpen(true);
      
     //  setIsDrawerOpenEdit(false)
      // openDrawerEdit();
    }

//   const handleEdit = (machinery) => { 
//     setSelectedMachinery(machinery); // Set the machinery object to be edited
//     setIsDrawerOpen(true);  // Open the drawer to show the edit form
// };

    const handleDelete=()=>{
     // setindexUserView(index)
     setDeleteIndex(dataView._id)
     console.log(dataView._id,"index++")
    
     setDeleteDialog(true);
     setAnchorEl(null);
     setSelectedUserIndex(null);
   
       setDeleteDialog(true);
       setAnchorEl(null);
       setSelectedUserIndex(null);
     };

     const handleDeleteAggree = () => {
      console.log("indexhandleDeleteAggree",DeleteIndex)
      axios.put(`${api}/master/deleteMachinery`,{active:false},{
        params: {
          id: DeleteIndex // Pass as query parameter
        }
    }).then((res)=>{
      console.log(res,"res++")
      setDeleteDialog(false);
      setsnackOpen({open:true,message:"user deleted successfully",severity:"success"})
      setAnchorEl(null);
      console.log("delete validate",res.data.data)
      setMachinery(machinery.filter((item)=>item._id !== res.data.data._id));
      console.log(machinery, "dvdwghx")
      setSelectedUserIndex(null);
    }).catch((err)=>{
      console.log(err)
    })
     
    };

  
  const handleFilterChange = (selectedCategories) => {
    setCategoryFilter(selectedCategories);
  };
  

  const handleNameFilterChange = (selectedMachineryNames) => {
    setMachineryNames(selectedMachineryNames);
  };


  const  handleFuelFilterChange = (selectedFuelTypes) => {
    setFuelTypes(selectedFuelTypes);
  };

  const  handleTypeFilterChange = (selectedTypes) => {
    setMachineTypes(selectedTypes);
  };

  const handleApplyFilters = () => {
    if(!validateFilters())
      return;
    console.log("Selected categories:", selectedCategories);
    console.log("Selected names:", selectedMachineryNames);
    console.log("Selected names:", selectedFuelTypes);
    console.log("Selected Types:", selectedTypes);


    // setPage(1); // Reset to first page on filter application

    fetchMachinery({
      filterMachineCategoryType: selectedCategories.join(","),
      filterMachineTypeName: selectedTypes.join(","),
      filterName: selectedMachineryNames.join(","),
      filterFuelType: selectedFuelTypes.join(","),
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    });

    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

  const filtersToApply = {
    filterMachineCategoryType: selectedCategories.join(","),
    filterMachineTypeName: selectedTypes.join(","),
    filterName: selectedMachineryNames.join(","),
    filterFuelType: selectedFuelTypes.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };

  // Applying reset filters
  const handleResetFilters = () => {
    if (selectedFilter === "Date") {
      setStartDate(null);
      setEndDate(null);
      setAppliedFilters({ ...appliedFilters, Date: false });
    }
    if (selectedFilter === "Category") {
      setSelectedCategories([]);
      setAppliedFilters({ ...appliedFilters, Category: false });
    }

    if (selectedFilter === "MachineType") {
      setSelectedTypes([]);
      setAppliedFilters({ ...appliedFilters, MachineType: false });
    }

    if (selectedFilter === "MachineryName") {
      setSelectedMachineryNames([]);
      setAppliedFilters({ ...appliedFilters, MachineryName: false });
    }

    if (selectedFilter === "FuelType") {
      setSelectedFuelTypes([]);
      setAppliedFilters({ ...appliedFilters, FuelType: false });
    }


    fetchMachinery({
      category: selectedFilter === "Category" ? [] : selectedCategories.join(","),
      machineType:selectedFilter === "MachineType" ? [] : selectedTypes.join(","),
      machineryName: selectedFilter === "MachineryName" ? [] : selectedMachineryNames.join(","),
      FuelType: selectedFilter === "FuelType" ? [] : selectedFuelTypes.join(","),
      startDate:
        selectedFilter === "Date"
          ? null
          : startDate
          ? startDate.format("YYYY-MM-DD")
          : "",
      endDate:
        selectedFilter === "Date"
          ? null
          : endDate
          ? endDate.format("YYYY-MM-DD")
          : "",
    });
  };

  const handleAppliedFilters = () => {
    setAppliedFilters({
      ...appliedFilters,
      Date: startDate || endDate ? true : false,
      Category: selectedCategories.length > 0 ? true : false,
      MachineType: selectedTypes.length > 0 ? true : false,
      MachineryName: selectedMachineryNames.length > 0 ? true : false,
      FuelType: selectedFuelTypes.length > 0 ? true : false,
    });
  };


  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: "", Role: ""};
    if (selectedFilter === "Date") {
      if (startDate >= endDate) {
        console.log(startDate, endDate, Date.now());
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
      if (!startDate && !endDate) {
        newErrors.Date = "Please select date";
        isValid = false;
      }
    }

    if (selectedFilter === "Category" && selectedCategories.length===0) {
      newErrors.Category = "Please select Category";
    isValid = false;
  }

  if (selectedFilter === "MachineType" && selectedTypes.length===0) {
    newErrors.MachineType = "Please select MachineType";
  isValid = false;
}

  if (selectedFilter === "MachineryName" && selectedMachineryNames.length===0) {
    newErrors.MachineryName = "Please select MachineryName";
  isValid = false;
  }

  if (selectedFilter === "FuelType" && selectedFuelTypes.length===0) {
    newErrors.FuelType = "Please select FuelType";
  isValid = false;
  }
    
    
  


    console.log(newErrors);

    setErrors(newErrors);
    return isValid;
  };


  const renderSelectedFilter = () => {
    switch (selectedFilter) { 
      case "Date":
        return (
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            errors={errors.Date}
          />
        );

        case "Category":
          return (
            <CategoryFilter
              url={`${api}/master/getAllMachinery`}
              onFilterChange={handleFilterChange}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              errors={errors.Category}
  
            />
          );

          case "MachineType":
            return (
              <MachineTypeFilter
                url={`${api}/master/getAllMachinery`}
                onFilterChange={handleTypeFilterChange}
                selectedTypes={selectedTypes}
                setSelectedTypes={setSelectedTypes}
                errors={errors.Category}
    
              />
            );

          case "MachineryName":
            return (
              <MachineryNameFilter
                url={`${api}/master/getAllMachinery`}
                onFilterChange={handleNameFilterChange}
                selectedMachineryNames={selectedMachineryNames}
                setSelectedMachineryNames={setSelectedMachineryNames}
                errors={errors.MachineryName}
    
              />
            );
            
          
            case "FuelType":
              return (
                <FuelTypeFilter
                  url={`${api}/master/getAllMachinery`}
                  onFilterChange={handleFuelFilterChange}
                  selectedFuelTypes={selectedFuelTypes}
                  setSelectedFuelTypes={setSelectedFuelTypes}
                  errors={errors.FuelType}
      
                />
              );

      default:
        return (
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            errors={errors.Date}
          />
        );
    }
  };
  

    const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
        "&.Mui-selected": {
          backgroundColor: "green",
          color: "#fff",
          fontWeight: "bold",
        },
        "&:hover": {
          backgroundColor: "#ededed",
        },
      }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

  return (
    <Box
    sx={{
      padding: "20px",
      backgroundColor: "#ededed",
      height: "100vh",
      overflowY: "scroll",
      overflowX: "hidden",
      // margin: " 0px 0px 0px 50px",
      zIndex: 1000, 
    }}
  >
     <Box sx={{ width: 600 }}>
     
     <Snackbar open={snackOpen.open} autoHideDuration={3000}
      anchorOrigin={{ vertical:"top", horizontal:"center" }}
       onClose={()=>setsnackOpen({...snackOpen,open:false})}

      >
     <Alert
       // onClose={handleClose}
       severity={snackOpen.severity}
       variant="filled"
       sx={{ width: '100%' }}
     >
       {snackOpen.message}
       {/* Add user Successfully */}
     </Alert>
   </Snackbar>
   <AddMachinery
          value={isDrawerOpen}
          setValue={setIsDrawerOpen}
          index={index}
          fetchMachinery={fetchMachinery}
          setsnackOpen = {setsnackOpen}
          machinery={selectedMachinery}
          indexMachinery={indexMachinery}
        />
         <View
        viewFacility={viewMachinery}
        setViewFacility={setViewMachinery}
        selectedFacility={dataView}
        setsnackOpen={setsnackOpen}
        fetchFacilities={fetchMachinery}
      />
      {/* <ViewUser
        values={isDrawerOpenEdit}
        // values ={ViewInner}
        setValues={setIsDrawerOpenEdit}
        indexView={indexUserView}
      /> */}
 </Box>
 {drawerFilterOpen && (
        <Drawer
          anchor="right"
          open={drawerFilterOpen}
          onClose={() => setDrawerFilterOpen(false)}
          sx={{
            backgroundColor: "transparent",
            "& .MuiDrawer-paper": {
              // This targets the paper component within the Drawer
              backgroundColor: "transparent", // Make paper transparent
              boxShadow: "none", // Optional: Remove any shadow
              width: "50%",
            },
          }}
        >
          <Box
            display="flex"
            gap={3}
            sx={{
              width: "100%",
              display: "flex",
              overflowX: "hidden",
              maxWidth: "100%",
              justifyContent: "space-btween",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                height: "3%",
                padding: "13px",
                margin: "30px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "80%",
                width: "4%",
              }}
            >
              <IconButton
                onClick={() => setDrawerFilterOpen(false)}
                sx={{ color: "black" }}
              >
                <CloseIcon sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
              {/* Sidebar for selecting the filter */}
              <FilterSidebar
                {...{
                  setSelectedFilter,
                  selectedFilter,
                  filters,
                  appliedFilters,
                }}
              />

              {/* Filter content area */}
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundColor: "#F9F9F9",
                  padding: "20px",
                }}
              >
                {renderSelectedFilter()}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "3%",
                    position: "fixed",
                    bottom: "20px",
                    ml: 3,
                    mr: 3,
                  }}
                >
                  <ApplyButton handleApply={handleApplyFilters} />
                  <ResetButton handleReset={handleResetFilters} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}{" "}
       {/* <AddMachinery
        value={isDrawerOpen}
        setValue={setIsDrawerOpen}
        index={indexUser}
        setindexUser={setindexUser}
        fetchUsers={fetchUsers}
        setsnackOpen={setsnackOpen}
      /> */}
      {/* <ViewUser
        values={isDrawerOpenEdit}
        // values ={ViewInner}
        setValues={setIsDrawerOpenEdit}
        indexView={indexUserView}
      /> */}
      {deleteDialog && (
        <Dialog 
          open={deleteDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => setDeleteDialog(false)}
            >
              Cancle
            </Button>
            <Button sx={{ textTransform: "none" }} onClick={handleDeleteAggree}>
            Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
       

    <Grid
          container
          alignItems="center"
          sx={{ width: "90%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "18px",
                marginLeft: "10px",
                textDecoration: "underline",
              }}
            >
              Machinery Master
            </Typography>
            
          </Grid>
          <Grid item style={{ textAlign: "right", marginLeft:"245px" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "40px 10px 18px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {/* {(selectedRoles.length > 0 || startDate || endDate) && ( */}
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              {/* )} */}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "40px -36px 18px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              endIcon={<AddIcon />}
              onClick={handleAddRole}
            >
              Add Data
            </Button>
          </Grid>
        </Grid>
     </Toolbar>
        
      

 
       

      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
      
      <Box 
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "left",
          padding: "16px",
          }}>Machinery Master</Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '12% 15% 15% 18% 15% 15% 10%', width: '100%', border: '1px solid #ddd' }}>
  
      <Box 
      sx={{ 
        padding: '20px', 
        fontWeight: 'bold', 
        textAlign: 'left', 
        borderRight: '1px solid #ddd', 
        borderBottom: '1px solid #ddd',
        width: '100px',
        display: "flex",  flexDirection: "row",
        }}>
        Date
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort( "createdAt", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("createdAt","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
      </Box>
      <Box 
      sx={{ 
        padding: '12px', 
        fontWeight: 'bold', 
        textAlign: 'left', 
        borderRight: '1px solid #ddd', 
        borderBottom: '1px solid #ddd',
        display: "flex",  
        flexDirection: "row",
        marginLeft:"-14px"
        }}>   
        Quantity of Machines
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("numberOfMachines", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "numberOfMachines" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            marginTop: "3px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("numberOfMachines","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "numberOfMachines" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
      </Box>
      <Box 
      sx={{ 
        padding: '12px', 
        fontWeight: 'bold', 
        textAlign: 'left', 
        borderRight: '1px solid #ddd', 
        borderBottom: '1px solid #ddd',
        display: "flex",  
        flexDirection: "row",
        }}>
        Machine Category
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort( "machineCategory","asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "machineCategory" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            marginTop: "3px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("machineCategory", "desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "machineCategory" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
      </Box>
      <Box 
      sx={{ 
        padding: '20px',
        fontWeight: 'bold', 
        textAlign: 'left', 
        borderRight: '1px solid #ddd', 
        borderBottom: '1px solid #ddd',
        display: "flex",  
        flexDirection: "row",
        }}>
        Machine Type
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort( "machineType","asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "machineType" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("machineType", "desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "machineType" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
      </Box>
      <Box 
      sx={{ 
        padding: '12px', 
        fontWeight: 'bold', 
        textAlign: 'left', 
        borderRight: '1px solid #ddd', 
        borderBottom: '1px solid #ddd',
        display: "flex",  
        flexDirection: "row",
        }}>
        Machinery Name
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort( "name","asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "name" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            marginTop: "3px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("name", "desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "name" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
      </Box>
      <Box 
      sx={{ 
        padding: '20px',
        fontWeight: 'bold', 
        textAlign: 'left', 
        borderRight: '1px solid #ddd', 
        borderBottom: '1px solid #ddd',
        display: "flex",  
        flexDirection: "row",
        }}>
        Fuel Type
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort( "fuelType","asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "fuelType" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("fuelType", "desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "fuelType" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
      </Box>
      <Box 
      sx={{ 
        padding: '20px',
        fontWeight: 'bold', 
        textAlign: 'center', 
        borderBottom: '1px solid #ddd'
        }}>
        Action
      </Box>

          {/* Table Body */}
          {filteredMachinery.map((machinery, index) => (
            <>
              <Typography
                key={`date-${index}`}
                sx={{
                  padding: "2px 5px",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "black",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                  marginRight: "9px",
                }}
              >
                
                {new Date(machinery.createdAt).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
                
              </Typography>

              <Box
                key={`uniqueID-${index}`}
                sx={{
                  padding: "2px 0px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                  marginLeft:"-14px",
                 
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "100%",
                    margin: '9px',
                  }}
                >
                  {machinery.numberOfMachines}
                
                </Typography>
              </Box>
              <Box
                key={`MachineCategory-${index}`}
                sx={{
                  padding: "2px 0px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "143px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "100%",
                    margin: '9px',
                  }}
                >
               
               {/* {machinery.machineCategory} */}
               {machinery.machineCategory?.type || 'N/A'}

              </Typography>
              </Box>
              <Box
                key={`MachineType-${index}`}
                sx={{
                  padding: "2px 0px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "171px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "100%",
                    margin: '9px',
                  }}
                >
                {/* {machinery.machineType} */}
                {machinery.machineType?.name || 'N/A'}
                
              </Typography>
              </Box>
              <Box
                key={`MachineType-${index}`}
                sx={{
                  padding: "2px 0px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "142.5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "100%",
                    margin: '9px',
                  }}
                >
               {machinery.name}
              </Typography>
              </Box>
             
              <Box
                key={`MachineType-${index}`}
                sx={{
                  padding: "2px 0px",
                  borderRight: "1px solid #ddd",
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "143px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    lineHeight: "1.5",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    maxWidth: "100%",
                    margin: '9px',
                  }}
                >
                
                {machinery.fuelType}
              </Typography>
              </Box>
              <Typography
                key={`action-${index}`}
                sx={{
                  padding: "2px 8px 2px 8px",
                  textAlign: "center",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <IconButton
                  onClick={(event) => handleMenuClick(event,machinery)}
                >
                  <MoreHorizIcon sx={{ fontWeight: "bold", color: "black" }} />
                </IconButton>
              </Typography>
            </>
          ))} 
        </Box>
      </TableContainer>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
       
        <MenuItem
         onClick={() => handleView(machinery)}
          sx={{ fontSize: "14px" }}
        >
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem
          // onClick={() => handleEdit(roleEdit)}
          onClick={() => handleEdit()}
          sx={{ fontSize: "14px" }}
        >
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>

        <MenuItem
          onClick={() => handleDelete()}
          sx={{ fontSize: "14px" }}
        >
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
              // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
    
  );
};

export default Machinery;
