import React, {  useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId } from "../../../../utils/companyId";
import InputField from "../../../../Components/Masters/InputField";
import RadioField from "../../../../Components/Masters/RadioField";
import FileField from "../../../../Components/Masters/FileField";
import SelectField from "../../../../Components/Masters/SelectField";
import DateField from "../../../../Components/Masters/DateField";

function AddData({ inputData,fetchData, id,data, setAddData, addData, setsnackOpen }) {
  const [errors, setErrors] = useState({});
  const [upload, setUpload] = useState("yes");
  const [transportType, setTransportType] = useState("");

  const initialState={
    transportType: "",
    ownership: "",
    associatedVendor: "",
    vehicleNumber: "",
    brand: "",
    model: "",
    emissionType: "",
    purchaseDate: "",
    lastService: "",
    fuelType: "",
    declaredEfficiency: "",
    bharatStageEmissionType:"",
    loadCapacity: "",
    passengerCapacity: "",
    uploadDocuments: "yes",
    pucDocs: [],
    pollutionDocs: [],
    rcDocs:[],
    insuranceDocs: [],
    company: companyId,
  }

  const [uploadProgress, setUploadProgress] = useState({}); // Holds progress for each file
  const [formData, setFormData] = useState(initialState);
  const [uploadedFiles, setUploadedFiles] = useState({
    pucDocs: [],
    pollutionDocs: [],
    rcDocs: [],
    insuranceDocs: [],
  });

  const [docError, setDocError] = useState({
    pucDocs: [],
    pollutionDocs: [],
    rcDocs: [],
    insuranceDocs: [],
  });


  useEffect(() => {
    if (id && data) {
      setFormData((prevState) => ({
        ...prevState,
        ...data,
        brand: data.brand?._id || "",
        model: data.model?._id || "",
      }));
      setUploadedFiles({
        pucDocs: data.pucDocs || [],
        pollutionDocs: data.pollutionDocs || [],
        rcDocs: data.rcDocs || [],
        insuranceDocs: data.insuranceDocs || [],
      });
    } else if (!id) {
      setFormData(initialState);
    }
  }, [data, id]);
  
  const handleChange = (e) => {
    const { name, value } = e.target; // Correctly extract name and value from e.target
  
    console.log(`Input changed: ${name}, Value: ${value}`);

    // Clear the specific error for the field being changed
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined, // Remove error for the specific field
    }));

    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }));

    // Handling specific conditions based on field names
    if (name === "transportType") {
        setTransportType(value);
        if (value === "Passenger") {
            setFormData((prev) => ({ ...prev, loadCapacity: "", passengerCapacity: "" }));
        } else {
            setFormData((prev) => ({ ...prev, passengerCapacity: "", associatedVendor: "" }));
        }
    }

    if (name === "ownership" && value === "Company owned") {
        setFormData((prev) => ({ ...prev, associatedVendor: "" }));
    }
};


const handleDateChange = (e) => {
  const { name, value } = e; // Correctly extract name and value from e.target

  console.log(`Input changed: ${name}, Value: ${value}`);

  // Clear the specific error for the field being changed
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined, // Remove error for the specific field
  }));

  setFormData((prevState) => ({
      ...prevState,
      [name]: value,
  }));

};

  
const handleFileChange = async (e) => {
  const { name, files } = e.target;

  console.log(name,files)
  // Clear previous errors
  setDocError((prevErrors) => ({
    ...prevErrors,
    [name]: undefined,
  }));

  if (!files || files.length === 0) return;

  const newErrors = {
    pucDocs: "",
    pollutionDocs: "",
    rcDocs:"",
    insuranceDocs:"",
  };
  const uploadedFiles = []; // Store the uploaded files
  const progressTracker = Array(files.length).fill(0); // Initialize an array for tracking progress

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (files.length > 5) {
      newErrors[name]="You can only upload a maximum of 5 files.";
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls,
      "image/png",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        newErrors[name]=`File type not allowed: ${file.name}`;
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        newErrors[name]=`File size exceeds 5MB: ${file.name}`;
        return;
      }
    }
    // Create a FormData object
    console.log(newErrors)
    const formData = new FormData();
    formData.append("files", file);
    setDocError(newErrors);
    if(newErrors[name])
      return;
  
    try {
      // Upload the file
     const res= await axios.post(`${api}/cloud/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          progressTracker[i] = percent; // Update progress for the specific file
          setUploadProgress((prev) => ({ ...prev, [name]: progressTracker })); // Update state
        },
      });
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: res.data.urls,
      }));
      console.log(res.data.urls)
      uploadedFiles.push(file); // Store the file after successful upload
    } catch (error) {
      newErrors[name] = `Failed to upload ${file.name}. Please try again.`;
    }
  }

  console.log(uploadedFiles)
  // Update uploaded files state
  setUploadedFiles((prev) => ({ ...prev, [name]: [...prev[name], ...uploadedFiles] }));
  setUploadProgress((prev) => ({ ...prev, [name]: [] })); // Reset progress after upload

  setDocError(newErrors);
  
    return Object.keys(newErrors).length === 0;
};

  const handleClose=()=>{
    setAddData(false);
    setErrors({});
    setDocError({});
    // setFormData(initialState)
  }
  
  const handleRadioChange = (e) => {
    setUpload(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};

    inputData.forEach((input) => {
        const value = formData[input.fieldName];

        // Skip validation for upload documents fields if not required
        if (upload === "yes" && input.fieldName.endsWith("Docs")) {
            // Check if files have been uploaded for this field
            if (!uploadedFiles[input.fieldName] || uploadedFiles[input.fieldName].length === 0) {
                newErrors[input.fieldName] = `Please upload ${input.title} `;
            }
            return; // Skip other validations for this input
        }

        // Handle transport type specific validations
        if (formData.transportType === "Passenger" && input.fieldName === "loadCapacity") {
            return;
        }
        if (formData.transportType === "Freight" && input.fieldName === "passengerCapacity") {
            return;
        }
        if (formData.ownership === "Company owned" && input.fieldName === "associatedVendor") {
            return;
        }

        // Check for empty strings for fields that should be filled
        if (typeof value === 'string' && (!value || value.trim() === "")) {
            newErrors[input.fieldName] = `${input.title} is required.`;
            return; // Skip other validations for this input
        }

        // Additional validations based on transport type
        if (formData.transportType === "Passenger") {
            if (input.fieldName === "ownership" && !formData.ownership) {
                newErrors.ownership = "Ownership is required.";
            }
            if (input.fieldName === "passengerCapacity" && !formData.passengerCapacity) {
                newErrors.passengerCapacity = "Passenger capacity is required.";
            }
            if (input.fieldName === "associatedVendor" && formData.ownership === "Leased" && !formData.associatedVendor) {
                newErrors.associatedVendor = "Associated vendor is required when ownership is leased.";
            }
        } else if (formData.transportType === "Freight") {
            if (input.fieldName === "loadCapacity" && !formData.loadCapacity) {
                newErrors.loadCapacity = "Load capacity is required for freight vehicles.";
            }
        }
    });

    console.log(newErrors); // Log newErrors to see current validation results
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

  

const filterFormData = (data) => {
  const filteredData = { ...data };
  
  // Remove unnecessary fields based on conditions
  if (data.transportType === "Passenger") {
    delete filteredData.loadCapacity; // Don't send loadCapacity
  }

  if (data.transportType === "Freight") {
    delete filteredData.passengerCapacity; // Don't send passengerCapacity
  }

  if (data.ownership === "Company owned") {
    delete filteredData.associatedVendor; // Don't send associatedVendor
  }

  // Optionally remove uploadDocuments if not necessary
  if (upload === "no") {
    Object.keys(filteredData).forEach(key => {
      if (key.endsWith("Docs")) {
        delete filteredData[key]; // Don't send document fields
      }
    });
  }

  return filteredData;
};

const handleDeleteFile = (fieldName, index) => {
  setUploadedFiles((prev) => {
    const newFiles = [...prev[fieldName]];
    newFiles.splice(index, 1); // Remove the file at the specified index
    return {
      ...prev,
      [fieldName]: newFiles,
    };
  });
};

const Save = () => {
  if (!validateForm()) return;
  
  const dataToSend = filterFormData(formData); // Filter form data based on conditions

  console.log(dataToSend); // Log the data being sent

  setErrors({});
  setDocError({});
  axios({
    method: id ? "put" : "post",
    url: id ? `${api}/master/updateVehicle` : `${api}/master/addVehicle`,
    data: dataToSend, // Use filtered data
    params: id ? { id } : null,
  })
    .then((res) => {
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
      handleClose()
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        setsnackOpen({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      }
    });
};

const renderUploadedFiles = (fieldName) => {
  return uploadedFiles[fieldName].map((url, index) => (
    <Typography key={index} variant="body2">
      <a href={url} target="_blank" rel="noopener noreferrer">
        Document {index + 1}
      </a>
    </Typography>
  ));
};

  const renderInputField = (el) => {
    if (el.fieldName === "uploadDocuments") {
      return (
        <RadioField
          handleChange={handleRadioChange}
          title={el.title}
          formData={formData}
          value={upload}
          fieldName={el.fieldName}
          errors={errors}
        />
      );
    }
  
    if (el.options || el.type==="select" ) {
      return (
        <SelectField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleChange}
          options={el.options}
          errors={errors[el.fieldName]}
        />
      );
    }

    
    if (el.type==="date") {
      return (
        <DateField
          title={el.title}
          name={el.fieldName}
          value={formData[el.fieldName]}
          handleChange={handleDateChange}
          errors={errors[el.fieldName]}
        />
      );
    }
    if (el.fieldName.endsWith("Docs")) {
      return (
        <FileField
        title={el.title}
        name={el.fieldName}
        handleFileChange={handleFileChange}
        errors={docError[el.fieldName]}
        // Assuming uploadedFiles[el.fieldName] contains URLs
        fileUrls={uploadedFiles[el.fieldName] || []} 
        progress={uploadProgress[el.fieldName] || []}
        onDelete={(index) => handleDeleteFile(el.fieldName, index)}
      >
        {uploadedFiles[el.fieldName]?.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Already Uploaded Documents:</Typography>
            {renderUploadedFiles(el.fieldName)}
          </Box>
        )}
      </FileField>
      );
    }
    
  
    return (
      <InputField
        title={el.title}
        handleChange={handleChange}
        placeholder={el.placeholder}
        value={formData[el.fieldName] || ""}         errors={errors[el.fieldName]}
        fieldName={el.fieldName}
      />
    );
  };
  
  const list = () => (
    <Box display="flex" gap={3} sx={{ width: "420px" }}>
      <Box sx={{
        backgroundColor: "#fff",
        height: "20px",
        padding: "13px",
        margin: "30px 0 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "80%",
        width: "5%",
      }}>
        <IconButton onClick={() => {setAddData(false);setErrors({})}} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "90%", p: 3, backgroundColor: "#fff" }}>
        <Typography sx={{ textDecoration: "underline", fontWeight: "bold", mb: 2 }}>
          {id? "Edit Vehicle" : "Add Vehicle"}
        </Typography>
        {inputData.map((el) => {
          const shouldRender = el.condition === undefined || el.condition;
          if (el.fieldName === "ownership" && transportType === "Freight") {
            return null;
          }
          if (el.fieldName === "loadCapacity" && transportType === "Passenger") {
            return null;
          }
          if (el.fieldName === "passengerCapacity" && transportType === "Freight") {
            return null;
          }
          if (el.fieldName === "associatedVendor" && formData.ownership === "Company owned") {
            return null;
          }
          if(el.fieldName.endsWith("Docs") && upload==="no")
            return null;

          if (shouldRender) {
            return <div key={el.fieldName}>{renderInputField(el)}</div>;
          }
          return null;
        })}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0a3f73", textTransform: "none", marginTop: "10%" }}
            onClick={Save}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
     <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": { backgroundColor: "transparent", boxShadow: "none" },
        }}
        anchor="right"
        open={addData}
        onClose={() => {handleClose()}}
      >
        {list()}
      </Drawer>
  );
}

export default AddData;
