import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import AddFacility from "./AddIndustrialProcess";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function ViewIndusrialProcess(props) {
  const [edit, setEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(false);
  const [addFacility, setAddFacility] = useState(false);
  useEffect(() => {}, [props]);

  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const Edit = () => {
    setAddFacility(true);
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: "420px",
        maxWidth: "100%",
      }}
    >
      <AddFacility
        addFacility={addFacility}
        setAddFacility={setAddFacility}
        selectedFacility={props.selectedFacility}
        setsnackOpen={props.setsnackOpen}
        fetchFacilities={props.fetchFacilities}
      />
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewFacility(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{ p: 3, width: "100%", backgroundColor: "#fff", height: "100%" }}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          View Industrial Process Master
        </Typography>
        <Box>
          <Box display="flex" gap={10}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Date
              </Typography>

              <p>{props.selectedFacility?.createdAt.split("T")[0] ?? ""}</p>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Location
              </Typography>
              <p>{props.selectedFacility?.location ?? ""}</p>
            </Box>
          </Box>
        </Box>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Facility
        </Typography>
        <p>
          {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
          {props.selectedFacility?.facility?.facilityName ?? ""}
        </p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Industry Process
        </Typography>
        <p>{props.selectedFacility?.industryProcess ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Final Manufactured Product
        </Typography>
        <p>{props.selectedFacility?.finalManufacturedProduct ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Remark
        </Typography>
        <p>{props.selectedFacility?.remark ?? ""}</p>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Documents
        </Typography>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {props.selectedFacility?.documents?.map((doc, index) => (
            <Box key={index} sx={{ position: "relative" }}>
              <img
                src={doc}
                alt={`Document ${index + 1}`}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              <IconButton
                onClick={() => handleDownload(doc)}
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
          ))}
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Edit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.viewFacility}
        onClose={() => props.setViewFacility(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default ViewIndusrialProcess;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
