


// import React, { useState, useEffect } from "react";
// import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
// import axios from "axios";
// // import "../Scrollbar.css";
// // import "../../../Roles and Permissions/Filters/"

// const CategoryFilter = ({ selectedCategories = [], setSelectedCategories, url, errors }) => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     // Fetch all machinery categories from the backend
//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get(url);
//         console.log(response);
//         // Extract unique category names from the machinery data
//         const uniqueCategories = response.data.data
//           ? [...new Set(response.data.data.map((machinery) => machinery.machineCategory?.name))]
//           : [];
//         setCategories(uniqueCategories);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, [url]);

//   const handleCategoryChange = (event) => {
//     const selectedCategory = event.target.value;
//     if (event.target.checked) {
//       // Add category to the selected list
//       setSelectedCategories((prevSelected) => [...prevSelected, selectedCategory]);
//     } else {
//       // Remove category from the selected list
//       setSelectedCategories((prevSelected) =>
//         prevSelected.filter((category) => category !== selectedCategory)
//       );
//     }
//   };

//   return (
//     <Box sx={{ m: "5%", mt: 0 }}>
//       <Typography
//         variant="h5"
//         gutterBottom
//         sx={{ fontWeight: "bold", marginBottom: "30px" }}
//       >
//         Machinery Categories
//       </Typography>
//       <Box className="scrollable-container">
//         {categories.length > 0 ? (
//           categories.map((category, index) => (
//             <FormControlLabel
//               key={index}
//               control={
//                 <Checkbox
//                   value={category}
//                   checked={selectedCategories?.includes(category)} // Ensure selectedCategories is an array
//                   onChange={handleCategoryChange}
//                 />
//               }
//               label={category || "Unknown Category"} // Ensure there's a label
//             />
//           ))
//         ) : (
//           <Typography>Loading...</Typography>
//         )}
//       </Box>
//       {errors && (
//         <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
//           {errors}
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default CategoryFilter;





import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import axios from "axios";
// import "../Scrollbar.css";

const CategoryFilter = ({ selectedCategories = [], setSelectedCategories, url, errors }) => {
  const [categories, setCategories] = useState([]);

  // Fetch all machine categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(url);  // Fetch machine data from the given URL
        console.log("Response from backend:", response);
        
        // Extract unique machine categories from the response data
        const uniqueCategories = response.data.data
          ? [...new Set(response.data.data.map((machinery) => machinery.machineCategory?.type))]
          : [];
          
        console.log("Unique Categories:", uniqueCategories);
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [url]);  // Reload categories when the URL changes

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    if (event.target.checked) {
      // Add selected category to the state
      setSelectedCategories((prevSelected) => [...prevSelected, selectedCategory]);
    } else {
      // Remove the category from the state
      setSelectedCategories((prevSelected) =>
        prevSelected.filter((category) => category !== selectedCategory)
      );
    }
  };

  return (
    <Box sx={{ m: "5%", mt: 0 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", marginBottom: "30px" }}
      >
        Machinery Categories
      </Typography>
      <Box className="scrollable-container">
        {categories.length > 0 ? (
          categories.map((category, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={category}
                  checked={selectedCategories?.includes(category)}
                  onChange={handleCategoryChange}
                />
              }
              label={category || "Unknown Category"} // Fallback for missing category names
            />
          ))
        ) : (
          <Typography>Loading categories...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default CategoryFilter;
