import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
// import AddEquipment from "./AddEquipment";
// import AddEquipment from "../IndustrialEquipment/AddEquipment";
import AddMachinery from "./AddMachinery";


function View(props) {
  const [addFacility, setAddEquipment] = useState(false);

  useEffect(() => {}, [props]);

  const Edit = () => {
    setAddEquipment(true);
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 450,
        maxWidth: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "transparent",
      }}
    >
      <AddMachinery
        value={addFacility}
        setValue={setAddEquipment}
        indexMachinery={props.selectedFacility}
        setsnackOpen={props.setsnackOpen}
        fetchFacilities={props.fetchFacilities}
      />

      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setViewFacility(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          p: 3,
          width: "100%",
          backgroundColor: "#fff",
          height: "100%",
          overflow: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          View Machinery Details
        </Typography>

        {/* Displaying selected facility information */}
        <Box display="flex" gap={13}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Date
            </Typography>
            <p>{props.selectedFacility?.createdAt.split("T")[0] ?? ""}</p>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Location
            </Typography>
            <p>{props.selectedFacility?.location ?? ""}</p>
          </Box>
        </Box>

        <Box display="flex" gap={10}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Facility
            </Typography>
            <p>{props.selectedFacility?.facility?.facilityName ?? ""}</p>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Machine Name
            </Typography>
            <p >{props.selectedFacility?.name ?? ""}</p>
          </Box>
        </Box>
        <Box display="flex" gap={10}>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
            Machine Type
            </Typography>
            <p>{props.selectedFacility?.machineType?.name ?? ""}</p>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
            Machine Category
            </Typography>
            <p>{props.selectedFacility?.machineCategory?.type ?? ""}</p>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
              Fuel Type
            </Typography>
            <p>{props.selectedFacility?.fuelType ?? ""}</p>
          </Box>
        </Box>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          No of Equipment
        </Typography>
        <p>{props.selectedFacility?.numberOfMachines ?? ""}</p>

        {/* Displaying equipmentForms if they exist */}
        {props.selectedFacility?.equipments &&
          props.selectedFacility.equipments.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mt: 2, textDecoration: "underline" }}
              >
                Equipment Details
              </Typography>

              {props.selectedFacility?.equipments.length > 0 &&
                props.selectedFacility.equipments.map((form, index) => (
                  <Box
                    key={form.uniqueID}
                    sx={{
                      // p: 2,
                      // border: "1px solid #ccc",
                      // borderRadius: "8px",
                      mb: 2,
                      // backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                    >
                      Equipment {index + 1}:
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <strong>Unique ID:</strong>
                          <br />
                          {form.uniqueID}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <strong>Brand:</strong> <br />
                          {form.brand}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <strong>Model:</strong> <br />
                          {form.model}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          <strong>Rated Fuel Consumption:</strong> <br />
                          {form.ratedFuelConsumption}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body2">
                          <strong>Unit of Fuel Consumption:</strong> <br />
                          {form.unitOfFuelConsumption}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </>
          )}

        

        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Edit}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        anchor="right"
        open={props.viewFacility}
        onClose={() => props.setViewFacility(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default View;
