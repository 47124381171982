import  React ,{useState,useEffect,useContext} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AddUser from './AddUser';
import axios from 'axios';
import { api } from '../../../utils/api';
import { ProgressContext } from "../../../Components/Quetionary/ContextAPI";

function Edit(props) {
  const [edit,setEdit]= useState(false);
  const [valueEdit,setValueEdit] = useState(false);
  const [modulename,setModuleName]=useState(null);
  const { setViewInner,ViewInner } = useContext(ProgressContext);
  useEffect(() => {
    console.log('ViewUser props `values` updated:', props.indexView,"allowed_permissions",props.indexView?.role.allowed_permissions);
    
    axios.put(`${api}/auth/getPermissionsById`,
      {},
      {
        params: {
          Id: props.indexView?.role.allowed_permissions, // Pass as query parameter
        },
      }).then((res)=>{console.log("resforPermission",res.data.data.name)
        setModuleName(res.data.data.name)
      }).catch((err)=>{console.log("err",err)})
  }, [valueEdit,props.indexView]);
  
console.log(props.values,"propsvalue")
  const Edit =()=>{
    setEdit(true);
    setValueEdit(true);
    //  props.setValues(false);
  }
  console.log("setViewInnerViewUser",ViewInner)
console.log(props.indexView,props.indexView,"for view user")
  const list = () => (
    <Box display="flex" gap={3} sx={{width:400,
      maxWidth:"100%",
      height: '100vh',
    // maxHeight: '140vh',
       justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
      <AddUser setValue={setValueEdit}
          value={valueEdit}
        index={props.indexView?._id}/>
        <Box sx={{backgroundColor:"#fff",height:"3%",
        padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>
           props.setValues(false)
          // setViewInner(false)
        } 
          sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflow:"scroll",
}}>
      <Typography  variant="h6" sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>View User Details</Typography>  
       
        <Box display="flex" gap={10}>
          <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
            Date
          </Typography>
         
          <p>
              {props.indexView?props.indexView.createdAt.split('T')[0]:""}
          </p>
          </Box>
        <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
            Country
          </Typography>
          <p>
          {props.indexView?.country ?? ""}
          </p>
        </Box>
         
        </Box>
      
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Company Name    
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?.company.companyName??""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Employee Name    
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?.name??""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Company Email    
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?.email??""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Mobile No.    
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?` ${props.indexView.countryCode??""}${props.indexView.phone}` :""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Country of Operation     
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?props.indexView.countryOperation.map(item=>item):""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Facility    
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?props.indexView.facility.map(item=>item.facilityName):""}

          </p>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Assign Role     
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?props.indexView.role.name:""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
          Assigned Permission    
          </Typography>
       <p>{props.indexView?.role.allowed_permissions.map((item)=>item.name)}</p>
       {/* modulename */}
       <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Remarks   
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.indexView?props.indexView.name:""}

          </p>
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
            onClick={()=>Edit()}

            >
              Edit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
        
             {/* <Drawer
               sx={{
                // height:"106vh",
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.values}
                onClose={() => props.setValues(false)}
              >
                // {list()}
              </Drawer> */}
               {ViewInner && (
        <Drawer
          sx={{
            backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
              backgroundColor: "transparent", // Make paper transparent
              boxShadow: 'none', // Optional: Remove any shadow
            },
          }}
          anchor="right"
          open={props.values} // Use ViewInner to control the open state
          onClose={() => props.setValues(false)} // Close the drawer
        >
          {list()}
        </Drawer>
      )}
              
        </div>
      );
}

export default Edit;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  