import { Box, Typography, IconButton } from '@mui/material';
import React from 'react';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from "@mui/icons-material/Close";

const FileField = ({ title, name, handleFileChange, errors, fileUrls, progress, onDelete }) => {
  
    return (
    <Box>
      <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1, mt: 2 }}>
        {title}
      </Typography>
      
      {fileUrls && fileUrls.length > 0 && (
        <Box mb={2} sx={{ width: "100%" }}>
            
          <Box display="flex" flexWrap="wrap" gap={1}>
            {fileUrls.map((fileUrl, index) => (
              <Box key={index} sx={{ position: "relative" }}>
                <img
                  src={fileUrl}
                  alt={`Document ${index + 1}`}
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
                <IconButton
                  onClick={() => onDelete(index)} // Call the onDelete function when clicked
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "10px" }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      )}
{progress > 0 ? ( // Display individual file progress
                  <Box sx={{ mt: 1,mb:1, width: "100%" }}>
                    <LinearProgress value={progress} sx={{ width: "100%" }} />
                  </Box>
                )
      :<Box
        sx={{
          border: "1px dashed grey",
          borderRadius: 1,
          p: 2,
          textAlign: "center",
          cursor: "pointer",
          width: "100%", // Ensure the upload box is full width
        }}
      >
        <input
          type="file"
          hidden
          id={`upload-document-${name}`}
          name={name}
          onChange={handleFileChange}
          multiple
        />
        <label htmlFor={`upload-document-${name}`}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CloudUploadIcon />
            <Typography>Drop files here or click to upload</Typography>
            <Typography variant="caption">
              Upload your file (CSV, Excel, PNG, max 5 MB, recommended size 1200x800 pixels).
            </Typography>
          </Box>
        </label>
      </Box>}

      {errors && (
        <Typography variant="body2" color="#D34040">
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default FileField;
