import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

const MultiSelect = ({ 
  title, 
  data = [], // Default to an empty array
  selectedField,
  selectedFields,
  setSelectedFields, 
  field,
  errors 
}) => {

    // console.log(data)
  
    const handleFieldChange = (event) => {
        const { value } = event.target;
        console.log(selectedFields)
        // setSelectedFields(prev => ({
        //   ...prev,
        //   [field]: value // Update the specific field
        // }));
        setSelectedFields({...selectedFields,[field]:value})
      };
      
  return (
    <Box sx={{ m: "5%", mt: 0 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", marginBottom: "30px" }}>
        {title}
      </Typography>
      <Box className="scrollable-container">
        {data && data.length > 0 ? (
          data.map((Field, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={Field}
                  checked={selectedField?.includes(Field)}
                  onChange={handleFieldChange}
                />
              }
              label={Field}
            />
          ))
        ) : (
          <Typography>No options available</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
};

export default MultiSelect;
