import React, { createContext, useState } from 'react';

export const ProgressContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    // const esgdata =["intial Kickoff","Matarial Topics","ESG Baseline","Enviornment","Energy Management","Greenhouse Gas Emissions","Water Management","Biodiversity and Land Use"]
 const [update,setUpdate]=useState("Inactive");
 const [triggerGetRequest, setTriggerGetRequest] = useState(false);
 const [ViewInner,setViewInner] = useState(false);


  return (
    <ProgressContext.Provider value={{update, setUpdate,ViewInner,setViewInner, triggerGetRequest, setTriggerGetRequest}}>
      {children}
    </ProgressContext.Provider>
  );
};
