import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
 import AddFacility from './AddFacility';

function ViewFacility(props) {
  const [edit,setEdit]= useState(false);
  const [valueEdit,setValueEdit] = useState(false);
  const [addFacility,setAddFacility] = useState(false);
  useEffect(() => {
    console.log('ViewUser props `values` updated:', props);
    
  }, [props]);
  
console.log(props.selectedFacility?.address,props.selectedFacility?._id,"propsvalue")
  const Edit =()=>{
    // setEdit(true);
    // props.setViewFacility(false)
    // setValueEdit(true);
    setAddFacility(true);
    // props.setValues(false)
  }

  const list = () => (
    <Box display="flex" gap={3} sx={{width:400,
      maxWidth:"100%", height:"100vh", display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <AddFacility addFacility={addFacility} setAddFacility={setAddFacility} selectedFacility={props.selectedFacility}/>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>props.setViewFacility(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box sx={{p:3,width:"100%", backgroundColor:"#fff",height:"100%",overflow:"scroll"}}>
      <Typography  variant="h6" sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>View Facility Details</Typography>  
       <Box>
        <Box display="flex" gap={10}>
          <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
            Date
          </Typography>
         
          <p>
              {props.selectedFacility?.createdAt.split('T')[0]??""}
          </p>
          </Box>
        <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
            Country
          </Typography>
          <p>
          {props.selectedFacility?.country ?? ""}
          </p>
        </Box>
         
        </Box>
      
          </Box>
               
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
        Name of Facility    
          </Typography>
          <p>
            {/* {props.index?.role?.map((item) => item.name).join(', ') ?? ""} */}
            {props.selectedFacility?.facilityName??""}

          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
         Address    
          </Typography>
         <p>
         {props.selectedFacility?.address??""}
            </p> 
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
         Nickname   
          </Typography>
         <p>
         {props.selectedFacility?.nickname??""}
            </p> 
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
         Incharge  
          </Typography>
         <p>
         {props.selectedFacility?.incharge??""}
            </p> 
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
         OutsourceFacility 
          </Typography>
         <p>
         {props.selectedFacility?.outsourceFacility??""}
            </p> 
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
            onClick={Edit}

            >
              Edit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
        
              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.viewFacility}
                onClose={() => props.setViewFacility(false)}
              >
                {list()}
              </Drawer>
              
        </div>
      );
}

export default ViewFacility;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  