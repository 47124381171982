import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'

const DeleteDialog = ({deleteDialog,setDeleteDialog,handleDeleteAggree}) => {
  return (
    <Dialog
          open={deleteDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => setDeleteDialog(false)}
            >
              Cancle
            </Button>
            <Button sx={{ textTransform: "none" }} onClick={handleDeleteAggree}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
  )
}

export default DeleteDialog