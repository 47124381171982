


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
// import { api } from '../../../../../utils/api';

// const RoleFilter = ({ onRoleFilterChange }) => {
//   const [roles, setRoles] = useState([]);
//   const [selectedRoles, setSelectedRoles] = useState([]);

//   useEffect(() => {
//     const loadRoles = async () => {
//       try {
//         const response = await axios.get(`${api}/auth/getAllUsers`); // Adjust endpoint as needed
//         const usersData = response.data.users || [];
        
//         // Extract unique role names
//         const uniqueRoles = [...new Set(usersData.map(user => user.role?.name || 'N/A'))];
//         setRoles(uniqueRoles);
//       } catch (error) {
//         console.error('Error fetching roles:', error);
//         setRoles([]);
//       }
//     };
//     loadRoles();
//   }, []);


 
  
  

//   const handleCheckboxChange = (event) => {
//     const { value, checked } = event.target;
//     setSelectedRoles((prevSelectedRoles) =>
//       checked ? [...prevSelectedRoles, value] : prevSelectedRoles.filter((role) => role !== value)
//     );
//   };

//   useEffect(() => {
//     onRoleFilterChange(selectedRoles);
//   }, [selectedRoles, onRoleFilterChange]);

//   return (
//     <div>
//       <h5 style={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}>Role</h5>
//       <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
//       {roles.length > 0 ? (
//         <FormGroup>
//           {roles.map((role) => (
//             <FormControlLabel
//               key={role}
//               control={
//                 <Checkbox
//                   value={role}
//                   checked={selectedRoles.includes(role)}
//                   onChange={handleCheckboxChange}
//                 />
//               }
//               label={role}
//             />
//           ))}
//         </FormGroup>
//       ) : (
//         <p>No roles available.</p>
//       )}
//     </div>
//     </div>
//   );
// };

// export default RoleFilter;






import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  Box, Typography,Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { api } from '../../../../../utils/api';


const RoleFilter = ({ onRoleFilterChange, selectedRoles, errors }) => {
  const [roles, setRoles] = useState([]);
  // const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        const response = await axios.get(`${api}/auth/getAllUsers`);
        const usersData = response.data.users || [];
        console.log(usersData)
        
        // Extract unique role names
        const uniqueRoles = [...new Set(usersData.map(user => user.role?.name || 'N/A'))];
        setRoles(uniqueRoles);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setRoles([]);
      }
    };
    loadRoles();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    onRoleFilterChange(
      checked ? [...selectedRoles, value] : selectedRoles.filter((role) => role !== value)
    );
  };

  return (
    <Box>
      {/* Role Title */}
      <Typography variant="h5" sx={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}>
        Role
      </Typography>

      {/* Roles List */}
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
        {roles.length > 0 ? (
          <FormGroup>
            {roles.map((role) => (
              <FormControlLabel
                key={role}
                control={
                  <Checkbox
                    value={role}
                    checked={selectedRoles.includes(role)} // Check if the role is selected
                    onChange={handleCheckboxChange}
                  />
                }
                label={role}
              />
            ))}
          </FormGroup>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      {errors &&  <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
        {errors}
      </Typography>}
    </Box>
  );
};

export default RoleFilter;