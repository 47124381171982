import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Toolbar,
  Typography,
  Box,
  PaginationItem,
} from "@mui/material";
import axios from "axios";
import { api } from "../../../../utils/api";
import { styled } from "@mui/material/styles";
import AddVehicle from "./AddVehicle";
import ViewVehicle from "./ViewVehicle";
// import VehicleFilter from "../../Roles and Permissions/Filters/VehicleFilter";
import DateRangePickerComponent from "../../Roles and Permissions/Filters/Date";
import PaginationBox from "../../../../Components/Masters/Pagination";
import DeleteDialog from "../../../../Components/Masters/DeleteDialog";
import ColumnHeadings from "../../../../Components/Masters/ColumnHeadings";
import MenuActions from "./MenuActions";
import TableContent from "../../../../Components/Masters/TableContent";
import FilterDrawer from "../../../../Components/Masters/FilterDrawer";
import TopMenu from "../../../../Components/Masters/TopMenu";
import MessagePopup from "../../../../Components/Masters/MessagePopup";
import TableHeading from "../../../../Components/Masters/TableHeading";
import MenuIcon from "../../../../Components/Masters/MenuIcon";
import MultiSelect from "../../Roles and Permissions/Filters/MultiSelect";

const Vehicle = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addVehicle, setAddVehicle] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    "Transport Type": false,
    Ownership: false,
    "Associated Vendor": false,
    Brand: false,
    Model: false,
  });
  const [viewVehicle, setViewVehicle] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackOpen, setsnackOpen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [uniqueValues, setUniqueValues] = useState({});
  const [errors, setErrors] = useState({
    Date: false,
    "Transport Type": false,
    Ownership: false,
    "Associated Vendor": false,
    Brand: false,
    Model: false,
  });
const[isFiltersApplied,setIsFiltersApplied]=useState(false);
  const [selectedFields, setSelectedFields] = useState({
    transportType: [],
    ownership: [],
    associatedVendor: [],
    brand: [],
    model: [],
  });
  const filters = [
    "Date",
    "Transport Type",
    "Ownership",
    "Associated Vendor",
    "Brand",
    "Model",
  ];
  

  const inputData = [
    {
      fieldName: "transportType",
      title: "Transport type",
      options: ["Passenger", "Freight"],
      placeholder: "Tap to select",
    },
    {
      fieldName: "ownership",
      title: "Ownership",
      options: ["Company owned", "Leased"],
      placeholder: "Tap to select",
    },
    {
      fieldName: "associatedVendor",
      title: "Associated vendor",
      placeholder: "Type here",
    },
    {
      fieldName: "vehicleNumber",
      title: "Vehicle number",
      placeholder: "Type here",
    },
    {
      fieldName: "brand",
      title: "Brand (make)",
      type: "select",
      placeholder: "Type here",
    },
    {
      fieldName: "model",
      title: "Model",
      type: "select",
      placeholder: "Type here",
    },
    {
      fieldName: "purchaseDate",
      title: "Purchase Date",
      type: "date",
      placeholder: "dd/mm/yyyy",
    },
    {
      fieldName: "lastService",
      title: "Last service",
      type: "date",
      placeholder: "dd/mm/yyyy",
    },
    {
      fieldName: "fuelType",
      title: "Fuel type",
      options: [
        "Diesel (average biofuel blend)",
        "Diesel (100% mineral diesel)",
        "Petrol (average biofuel blend)",
        "Petrol (100% mineral petrol)",
        "CNG",
        "Electricity",
      ],
    },
    {
      fieldName: "bharatStageEmissionType",
      title: "Bharat Stage Emission Type",
      options: [
        "India 2000 (Euro 1)",
        "BS II (Euro 2)",
        "BS III (Euro 3)",
        "BS IV (Euro 4)",
        "BS V (Euro 5)",
        "BS VI (Euro 6)",
      ],
    },
    {
      fieldName: "declaredEfficiency",
      title: "Declared efficiency",
      placeholder: "%",
    },
    {
      fieldName: "loadCapacity",
      title: "Load capacity (tons)",
      placeholder: "Enter Number",
    },
    {
      fieldName: "passengerCapacity",
      title: "Passenger capacity (persons)",
      placeholder: "Enter Number",
    },
    { fieldName: "uploadDocuments", title: "Upload documents" },
    { fieldName: "pucDocs", title: "PUC docs" },
    { fieldName: "pollutionDocs", title: "Pollution docs" },
    { fieldName: "rcDocs", title: "RC docs" },
    { fieldName: "insuranceDocs", title: "Insurance docs" },
  ];

  const fetchUniqueValues = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllVehicle`);
      const vehicles = response.data.data;
  
      const uniqueModels = [
        ...new Set(vehicles
          .map(vehicle => vehicle.model?.name)
          .filter(Boolean) // Filter out undefined values
        )
      ];
      const uniqueBrands = [
        ...new Set(vehicles
          .map(vehicle => vehicle.brand?.name)
          .filter(Boolean) // Filter out undefined values
        )
      ];
      const uniqueTransportTypes = [
        ...new Set(vehicles
          .map(vehicle => vehicle.transportType)
          .filter(Boolean) // Filter out undefined values
        )
      ];
      const uniqueOwnerships = [
        ...new Set(vehicles
          .map(vehicle => vehicle.ownership)
          .filter(Boolean) // Filter out undefined values
        )
      ];
      const uniqueVendors = [
        ...new Set(vehicles
          .map(vehicle => vehicle.associatedVendor?.name)
          .filter(Boolean) // Filter out undefined values
        )
      ];
  
      return {
        models: uniqueModels,
        brands: uniqueBrands,
        transportTypes: uniqueTransportTypes,
        ownerships: uniqueOwnerships,
        vendors: uniqueVendors,
      };
    } catch (error) {
      console.error("Error fetching unique values:", error);
      return {};
    }
  };
  


   // fetching Vehicle data
   const fetchData = async (filters = filtersToApply) => {
    try {
      console.log(filters,"filters")
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line
      const response = await axios.get(
        `${api}/master/getAllVehicle?${queryParams}`
      );

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setData(response.data.data); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      const uniqueValues = await fetchUniqueValues();
      console.log(uniqueValues)
    setUniqueValues(uniqueValues);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortConfig]);

  
  const handleMenuClick = (event, vehicle) => {
    setAnchorEl(event.currentTarget);
    setSelectedVehicle(vehicle);
    setVehicle(vehicle);
    console.log(vehicle);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedVehicle(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setAddVehicle(true);
    setViewVehicle(false);
  };

  const handleView = () => {
    console.log("View Vehicle:", selectedVehicle);
    handleMenuClose();
    setViewVehicle(true);
    setAddVehicle(false);
  };

  const handleDelete = () => {
    console.log("Delete Vehicle:", selectedVehicle);
    handleMenuClose();
    setDeleteDialog(true);
  };

  const handleDeleteAggree = async () => {
    // console.log("indexhandleDeleteAggree", vehicle, vehicle._id);
    try {
      const res = await axios.delete(
        `${api}/master/deleteVehicle/${vehicle._id}`
      );
      console.log(res, "res++");
      setDeleteDialog(false);
      setsnackOpen({
        open: true,
        message: res.data.message,
        severity: "success",
      });
      fetchData();
    } catch (err) {
      console.log(err);
      setsnackOpen({
        open: true,
        message: err.data?.response.message ?? "network Error",
        severity: "error",
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

 
  // default filters to apply
  const filtersToApply = {
     startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    ...selectedFields
  };

  // function to validate filters
  const validateFilters = () => {
    let isValid = true;
    const newErrors = {   Date: false,
      "Transport Type": false,
      Ownership: false,
      "Associated Vendor": false,
      Brand: false,
      Model: false, };

      console.log(selectedFilter)

    if (selectedFilter === "Date") {
      const today = new Date(); // Get the current date

      // Ensure that startDate and endDate are not greater than today's date
      if (startDate && startDate > today) {
        newErrors.Date = "Start date cannot be in the future";
        isValid = false;
      }

      if (endDate && endDate > today) {
        newErrors.Date = "End date cannot be in the future";
        isValid = false;
      }

      // Ensure that the startDate is less than or equal to the endDate
      if (startDate && endDate && startDate >= endDate) {
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
    }
    if (selectedFilter === "Transport Type" && selectedFields.transportType.length===0) {
      newErrors["Transport Type"] = "Please select At least one Transport Type";
     isValid = false;
    }

    if (selectedFilter === "Ownership" && selectedFields.ownership.length===0) {
      newErrors.Ownership = "Please select At least one Ownership";
     isValid = false;
    }

    if (selectedFilter === "Brand" && selectedFields.brand.length===0) {
      newErrors.Brand = "Please select At least one Brand";
     isValid = false;
    }

    if (selectedFilter === "Model" && selectedFields.model.length===0) {
      newErrors.Model = "Please select At least one Model";
     isValid = false;
    }
    if (selectedFilter === "Associated Vendor" && selectedFields.associatedVendor.length===0) {
      newErrors["Associated Vendor"] = "Please select At least one Associate Vendor";
     isValid = false;
    }
    console.log(newErrors);

    setErrors(newErrors);
    return isValid;
  };

  // Applying selected filters
  const handleApplyFilters = () => {
    if (!validateFilters()) return;

    console.log("selected",selectedFields)
    fetchData();
    handleAppliedFilters();
    setDrawerFilterOpen(false);
  };

  const handleResetFilters =async () => {
    // Reset local state for date selection and selected fields
    setStartDate(null);
    setEndDate(null);
    setSelectedFields({
      transportType: [],
      ownership: [],
      associatedVendor: [],
      brand: [],
      model: [],
    });
  
    // Call fetchData without any filters
    await fetchData({
      startDate: "",
      endDate: "",
      transportType: [],
      ownership: [],
      associatedVendor: [],
      brand: [],
      model: [],
    });
  
    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: false,
      "Transport Type": false,
      Ownership: false,
      "Associated Vendor":false,
      Brand: false,
      Model:false,
    }));
  setIsFiltersApplied(false);
    // Close the filter drawer
    setDrawerFilterOpen(false);
  };
  
  const handleAppliedFilters = () => {
    const isDateApplied = startDate || endDate ?true:false;
    const isTransportTypeApplied = selectedFields.transportType?.length > 0?true:false;
    const isOwnershipApplied = selectedFields.ownership?.length > 0?true:false;
    const isAssociatedVendorApplied = selectedFields.associatedVendor?.length > 0?true:false;
    const isBrandApplied = selectedFields.brand?.length > 0?true:false;
    const isModelApplied = selectedFields.model?.length > 0?true:false;
  
    setIsFiltersApplied(isDateApplied || 
                             isTransportTypeApplied || 
                             isOwnershipApplied || 
                             isAssociatedVendorApplied || 
                             isBrandApplied || 
                             isModelApplied)
  
    setAppliedFilters((prevAppliedFilters) => ({
      ...prevAppliedFilters,
      Date: isDateApplied,
      "Transport Type": isTransportTypeApplied,
      Ownership: isOwnershipApplied,
      "Associated Vendor": isAssociatedVendorApplied,
      Brand: isBrandApplied,
      Model: isModelApplied,
    }));
  
     };
  

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "#ededed",
    },
  }));

  const handleFilterDrawer = () => {
    setDrawerFilterOpen(false);
    setErrors({ Date: false, Role: false });
  };

  const columnHeadings = [
    { title: "Date", field: "createdAt" },
    { title: "Transport Type", field: "transportType" },
    { title: "Ownership", field: "ownership" },
    { title: "Associated Vendor", field: "associatedVendor" },
    { title: "Brand", field: "brand" },
    { title: "Model", field: "model" },
  ];


  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllVehicle`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              errors: errors.Date,
            }}
          />
        );
      case "Model":
        return (
          <MultiSelect
            title="Model"
            url={`${api}/master/getAllVehicle`}
            selectedField={selectedFields.model}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.Model}
            field="model"
            data={uniqueValues.models}           />
        );
      case "Brand":
        return (
          <MultiSelect
            title="Brand"
            url={`${api}/master/getAllVehicle`}
            selectedFields={selectedFields}
            selectedField={selectedFields.brand}
            setSelectedFields={setSelectedFields}
            errors={errors.Brand}
            field="brand"
            data={uniqueValues.brands}           />
        );
      case "Associated Vendor":
        return (
          <MultiSelect
            title="Associated Vendor"
            url={`${api}/master/getAllVehicle`}
            selectedFields={selectedFields}
            selectedField={selectedFields.associatedVendor}
            setSelectedFields={setSelectedFields}
            errors={errors["Associated Vendor"]}
            field="associatedVendor"
            data={uniqueValues.vendors}           />
        );
      case "Ownership":
        return (
          <MultiSelect
            title="Ownership"
            url={`${api}/master/getAllVehicle`}
            selectedField={selectedFields.ownership}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors.Ownership}
            data={uniqueValues.ownerships}           
              field="ownership"
              
          />
        );
      case "Transport Type":
        return (
          <MultiSelect
            title="Transport Type"
            url={`${api}/master/getAllVehicle`}
            selectedField={selectedFields.transportType}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            errors={errors["Transport Type"]}
            field="transportType"
            data={uniqueValues.transportTypes} 
          />
        );
      default:
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllVehicle`}
            {...{
              startDate,
              endDate,
              setStartDate,
              setEndDate,
              errors: errors.Date,
            }}
          />
        );
    }
  };


  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <MessagePopup {...{ snackOpen, setsnackOpen }} />{" "}
      {/*Message popup for success or failure */}
      {/* View log, Edit, delete menus */}
      <AddVehicle
        id={vehicle ? vehicle._id : ""}
        addData={addVehicle}
        setAddData={setAddVehicle}
        data={vehicle}
        // details={vehicle}
        fetchData={fetchData}
        setsnackOpen={setsnackOpen}
        inputData={inputData}
      />
      <ViewVehicle
        id={vehicle ? vehicle._id : ""}
        data={vehicle}
        viewData={viewVehicle}
        setViewData={setViewVehicle}
        inputData={inputData}
        handleEdit={handleEdit}
      />
      {deleteDialog && (
        <DeleteDialog
          {...{ deleteDialog, setDeleteDialog, handleDeleteAggree }}
        />
      )}
      {/* Filter Drawer */}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <FilterDrawer
            {...{
              renderSelectedFilter,
              drawerFilterOpen,
              setDrawerFilterOpen,
              handleApplyFilters,
              handleFilterDrawer,
              setSelectedFilter,
              handleResetFilters,
              selectedFilter,
              filters,
              appliedFilters,
            }}
          />
        )}{" "}
        {/* Top Box for Heading, Filter Button, Add Data Button */}
        <TopMenu
          setData={setVehicle}
          setAddData={setAddVehicle}
          {...{
            heading: "Transportation & Distribution",
            setDrawerFilterOpen,
            filterApplied: isFiltersApplied, 
          }}
        />
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        <Box>
          <TableHeading heading={"Vehicle Master"} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "12% 17% 17% 20% 12% 13% 8%",
              borderBottom: "1px solid #ddd",
              borderTop: "1px solid #ddd",
            }}
          >
            {columnHeadings.map((el) => (
              <ColumnHeadings
                {...{
                  title: el.title,
                  handleSort,
                  field: el.field,
                  sortConfig,
                }}
              />
            ))}
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "8px 12px",
                textAlign: "center",
              }}
            >
              Action
            </Typography>
          </Box>
          {data.map((Vehicle) => (
            <Box
              key={Vehicle._id}
              sx={{
                display: "grid",
                gridTemplateColumns: "12% 17% 17% 20% 12% 13% 8%",
                borderBottom: "1px solid #ddd",
              }}
            >
              {columnHeadings.map((el) => (
                <TableContent
                  {...{
                    fieldData:
                      el.field === "associatedVendor" &&
                      typeof Vehicle[el.field] === "object" &&
                      Vehicle[el.field] !== null &&
                      Vehicle[el.field].name
                        ? Vehicle[el.field].name
                        : typeof Vehicle[el.field] === "object" &&
                          Vehicle[el.field] !== null
                        ? Vehicle[el.field]
                        : Vehicle[el.field],
                    dateField: el.field === "createdAt",
                  }}
                />
              ))}
              <MenuIcon {...{ handleMenuClick, field: Vehicle }} />
              <MenuActions
                {...{
                  id: Vehicle._id,
                  handleView,
                  handleEdit,
                  handleDelete,
                  anchorEl,
                  handleMenuClose,
                }}
              />
            </Box>
          ))}
        </Box>
      </TableContainer>
      {/* Pagination */}
      <PaginationBox
        {...{ totalPages, page, handleChangePage, StyledPaginationItem }}
      />
      {/* Menu for Actions */}
    </Box>
  );
};

export default Vehicle;
