import React from "react";
import { Routes, Route } from "react-router-dom";
import User from "../../../Pages/Masters/Roles and Permissions/User";
import Facility from "../../../Pages/Masters/Facility Master/Facility";
import Machinery from "../../../Pages/Masters/Scope1/Machinery Master/Machinery";
import ListIndustrialProcess from "../../../Pages/Masters/Scope1/IndustrialMaster/IndustrialProcess/ListIndustrialProcess";
import Vehicle from "../../../Pages/Masters/Scope3/Transportation&Distribution/Vehicle";
import Equipment from "../../../Pages/Masters/Scope2/EquipmentMaster";
import ListEquipment from "../../../Pages/Masters/Scope1/IndustrialEquipment/ListEquipment";
import VendorMaster from "../../../Pages/Masters/Scope2/VendorMaster/VendorMaster";

function MasterRoutes() {
  return (
    <Routes>
      {/* <Route path="/masters" element={<MasterFilterPage/>} /> */}
      <Route path="/setup/roles-permission" element={<User />} />
      {/* <Route path="/masters/roles-permission" element={<User/>} /> */}

      <Route path="/setup/facility-master" element={<Facility />} />
      {/* Scope1  */}
      <Route
        path="/setup/masters/scope1/industrial-process"
        element={<ListIndustrialProcess />}
      />
      <Route
        path="/setup/masters/scope1/industrial-equipment"
        element={<ListEquipment />}
      />
      <Route path="/setup/masters/scope1/vehicle-master" element={""} />
      <Route
        path="/setup/masters/scope1/machinery-master"
        element={<Machinery />}
      />
      {/* Scope2 */}
      <Route
        path="/setup/masters/scope2/electricity-master/equipment-master"
        element={""}
      />
      <Route
        path="/setup/masters/scope2/electricity-master/vendor-master"
        element={""}
      />
      <Route
        path="/setup/masters/scope2/heat-master/equipment-master"
        element={""}
      />
      <Route
        path="/setup/masters/scope2/heat-master/vendor-master"
        element={""}
      />
      <Route
        path="/setup/masters/scope2/steam-master/equipment-master"
        element={<Equipment />}
      />
      <Route
        path="/setup/masters/scope2/steam-master/vendor-master"
        element={<VendorMaster />}
      />
      {/* Scope3 */}
      <Route
        path="/setup/masters/scope3/transportation-distribution/logistics-vendor-master"
        element={""}
      />
      <Route
        path="/setup/masters/scope3/transportation-distribution/vehicle-master"
        element={<Vehicle />}
      />
      <Route
        path="/setup/masters/scope3/transportation-distribution/driver-master"
        element={""}
      />
      <Route path="/setup/masters/scope3/waste-generated" element={""} />
      <Route path="/setup/masters/scope3/purchased-goods" element={""} />
    </Routes>
  );
}

export default MasterRoutes;
