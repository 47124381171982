import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { api } from '../../../../../utils/api';

const FacilityFilter = ({ onRoleFilterChange, selectedFacility, errors }) => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    console.log("facility filter");

    const loadFacility = async () => {
      try {
        const response = await axios.get(`${api}/auth/getAllUsers`);
        console.log(response);
        const usersData = response.data.users || [];

        // Extract unique role names while avoiding empty fields and duplicates
        const uniqueFacilities = [
          ...new Set(
            usersData
              .flatMap(user => user.facility.map(facility => facility?.facilityName || ''))
              .filter(facilityName => facilityName !== '') // Filter out empty names
          ),
        ];

        setFacilities(uniqueFacilities);
        console.log(uniqueFacilities);
      } catch (error) {
        console.error('Error fetching facilities:', error);
        setFacilities([]);
      }
    };

    loadFacility();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    onRoleFilterChange(
      checked ? [...selectedFacility, value] : selectedFacility.filter((facility) => facility !== value)
    );
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}>
        Facility
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
        {facilities.length > 0 ? (
          <FormGroup>
            {facilities.map((facility) => (
              <FormControlLabel
                key={facility}
                control={
                  <Checkbox
                    value={facility}
                    checked={selectedFacility.includes(facility)} // Reflect checked state
                    onChange={handleCheckboxChange}
                  />
                }
                label={facility}
              />
            ))}
          </FormGroup>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      {errors &&  <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
        {errors}
      </Typography>}
    </Box>
  );
};

export default FacilityFilter;
