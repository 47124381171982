import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const TopMenu = ({heading,setAddData,setData,setDrawerFilterOpen,filterApplied}) => {
  console.log("filter",filterApplied)
  return (
    <Grid
    container
    alignItems="center"
    sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
  >
    <Grid item xs={6}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          margin: "8px 0px 20px 0px",
          textDecoration: "underline",
        }}
      >
        {heading}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <Button
        variant="contained"
        onClick={() => setDrawerFilterOpen(true)}
        // endIcon={<FilterListIcon />}
        sx={{
          margin: "10px 10px -15px 0px",
          backgroundColor: "#ffffff",
          color: "black",
          "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
          "&:hover .dot-icon": {
            color: "#FFFFFF", // Change dot color on button hover
          },
          // display:"flex"
        }}
      >
        Filter
        <FilterListIcon />
        {filterApplied && (
          <FiberManualRecordIcon
            className="dot-icon"
            sx={{
              fontSize: "10px",
              color: "#488257",
              marginLeft: "5px",
            }}
          />
        )}
      </Button>
      <Button
        variant="contained"
        endIcon={<AddIcon />}
        sx={{
          margin: "10px -36px -15px 0px",
          backgroundColor: "#1c486b",
          fontSize: "14px",
          textTransform: "none",
        }}
        onClick={() => {
          setAddData(true);
          setData(null);
        }}
      >
        Add New
      </Button>
    </Grid>
  </Grid>
  )
}

export default TopMenu