





// import React, { useState } from 'react';
// import { Box, TextField, Typography, Grid } from '@mui/material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';

// // Import required plugins
// import weekOfYear from 'dayjs/plugin/weekOfYear';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import isBetween from 'dayjs/plugin/isBetween';
// import advancedFormat from 'dayjs/plugin/advancedFormat';

// // Extend dayjs with the required plugins
// dayjs.extend(weekOfYear);
// dayjs.extend(customParseFormat);
// dayjs.extend(localizedFormat);
// dayjs.extend(isBetween);
// dayjs.extend(advancedFormat);

// const UserDateFilter = ({ startDate, endDate, setStartDate, setEndDate }) => {

//   return (
//     <Box
//       sx={{
//         boxSizing: "border-box",
//         borderRadius: '8px',
//         width: '320px',
//       }}
//     >
//       <Typography
//         variant="h5"
//         gutterBottom
//         sx={{
//           fontWeight: 'bold',
//           marginBottom: '30px',
//         }}
//       >
//         Date
//       </Typography>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <Grid container spacing={1} alignItems="center" justifyContent="center">
//           <Grid item xs={5}>
//             <DatePicker
//               label="Start Date"
//               value={startDate}
//               onChange={(newValue) => setStartDate(newValue)}
//               renderInput={(params) => (
//                 <TextField
//                   fullWidth
//                   {...params}
//                   inputProps={{
//                     ...params.inputProps,
//                     placeholder: 'MM/DD/YYYY',
//                   }}
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item>
//             <Typography sx={{ margin: '0 3px', fontWeight: 'bold' }}>to</Typography>
//           </Grid>
//           <Grid item xs={5}>
//             <DatePicker
//               label="End Date"
//               value={endDate}
//               onChange={(newValue) => setEndDate(newValue)}
//               renderInput={(params) => (
//                 <TextField
//                   fullWidth
//                   {...params}
//                   inputProps={{
//                     ...params.inputProps,
//                     placeholder: 'MM/DD/YYYY',
//                   }}
//                 />
//               )}
//             />
//           </Grid>
//         </Grid>
//       </LocalizationProvider>
//     </Box>
//   );
// };

// export default UserDateFilter;


////////////////
// import React from 'react';
// import { Box, TextField, Typography, Grid } from '@mui/material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';

// // Import required plugins
// import weekOfYear from 'dayjs/plugin/weekOfYear';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import isBetween from 'dayjs/plugin/isBetween';
// import advancedFormat from 'dayjs/plugin/advancedFormat';

// // Extend dayjs with the required plugins
// dayjs.extend(weekOfYear);
// dayjs.extend(customParseFormat);
// dayjs.extend(localizedFormat);
// dayjs.extend(isBetween);
// dayjs.extend(advancedFormat);

// const UserDateFilter = ({ startDate, endDate, setStartDate, setEndDate }) => {

//   return (
//     <Box
//       sx={{
//         boxSizing: "border-box",
//         borderRadius: '8px',
//         width: '320px',  // Full width of the container
//       }}
//     >
//       <Typography
//         variant="h5"
//         gutterBottom
//         sx={{
//           fontWeight: 'bold',
//           marginBottom: '30px',
//         }}
//       >
//         Date
//       </Typography>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         {/* Flex container for better control */}
//         <Box 
//           display="flex" 
//           alignItems="center" 
//           justifyContent="space-between"
//         >
//           <Box width="45%">
//             <DatePicker
//               label="Start Date"
//               value={startDate}
//               onChange={(newValue) => setStartDate(newValue)}
//               format="DD/MM/YYYY"
//               renderInput={(params) => (
//                 <TextField
//                   fullWidth
//                   {...params}
//                   inputProps={{
//                     ...params.inputProps,
//                     placeholder: 'DD/MM/YYYY',
//                   }}
//                 />
//               )}
//             />
//           </Box>
          
//           <Typography sx={{ margin: '0 0px', fontWeight: 'bold' }}>to</Typography>
          
//           <Box width="45%" sx={{ marginRight: '-20px'}}>
//             <DatePicker
            
//               label="End Date"
//               value={endDate}
//               onChange={(newValue) => setEndDate(newValue)}
//               format="DD/MM/YYYY"
//               renderInput={(params) => (
//                 <TextField
//                   fullWidth
//                   {...params}
//                   inputProps={{
//                     ...params.inputProps,
//                     placeholder: 'DD/MM/YYYY',
//                   }}
//                 />
//               )}
//             />
//           </Box>
//         </Box>
//       </LocalizationProvider>
//     </Box>
//   );
// };

// export default UserDateFilter;





import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

// Import required plugins
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Extend dayjs with the required plugins
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const UserDateFilter = ({ startDate, endDate, setStartDate, setEndDate, errors }) => {
  // Get today's date for future date validation
  const today = dayjs();

  // Function to handle start date change
  const handleStartDateChange = (newValue) => {
    if (newValue && newValue.isAfter(today)) {
      return; // Prevent start date from being in the future
    }
    setStartDate(newValue);

    // If end date is earlier than new start date, update end date to be the same as start date
    if (newValue && endDate && newValue.isAfter(endDate)) {
      setEndDate(newValue);
    }
  };

  // Function to handle end date change
  const handleEndDateChange = (newValue) => {
    if (newValue && newValue.isAfter(today)) {
      return; // Prevent end date from being in the future
    }
    if (newValue && startDate && newValue.isBefore(startDate)) {
      return; // Prevent end date from being before the start date
    }
    setEndDate(newValue);
  };

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        borderRadius: '8px',
        width: '320px',  // Full width of the container
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Date
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width="45%">
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              format="DD/MM/YYYY"
              maxDate={today}  
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'DD/MM/YYYY',
                  }}
                />
              )}
            />
          </Box>
          
          <Typography sx={{ margin: '0 0px', fontWeight: 'bold' }}>to</Typography>
          
          <Box width="45%" sx={{ marginRight: '-20px' }}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              format="DD/MM/YYYY"
              maxDate={today} 
              minDate={startDate}  
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'DD/MM/YYYY',
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </LocalizationProvider>
      {errors &&  <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
              {errors}
            </Typography>}
    </Box>
  );
};

export default UserDateFilter;
