





// CompanyFilter.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { api } from '../../../../../utils/api';

const CompanyFilter = ({ onFilterChange, selectedCompanies, errors }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const response = await axios.get(`${api}/auth/getAllUsers`);
        const users = response.data.users;

        const companyNames = users
          .map(user => user.company?.companyName)
          .filter(name => name);
        setCompanies([...new Set(companyNames)]); // Remove duplicates and set state
      } catch (error) {
        console.error('Error fetching companies:', error.response ? error.response.data : error.message);
      }
    };
    loadCompanies();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    onFilterChange(value, checked); // Update the parent component's state on checkbox change
  };

  return (
    // <div>
    //   <h5 style={{margin: '20px 0px 0px 30px', fontWeight: 'bold'}}>Company Name</h5>
    //   <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
    //   {companies.length > 0 ? (
    //     companies.map((company, index) => (
    //       <FormControlLabel
    //         key={index}
    //         control={
    //           <Checkbox
    //             value={company}
    //             onChange={handleCheckboxChange}
    //             checked={selectedCompanies.includes(company)} // Set checked based on selectedCompanies
    //           />
    //         }
    //         label={company || 'Unknown Company'} // Ensure there's a label
    //       />
    //     ))
    //   ) : (
    //     <p>Loading...</p>
    //   )}
    //   </div>
    // </div>
    <Box>
      <Typography 
        variant="h5" 
        sx={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}
      >
        Company Name
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
        {companies.length > 0 ? (
          <FormGroup>
            {companies.map((company, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    value={company}
                    onChange={handleCheckboxChange}
                    checked={selectedCompanies.includes(company)} // Set checked based on selectedCompanies
                  />
                }
                label={company || 'Unknown Company'} // Ensure there's a label
              />
            ))}
          </FormGroup>
        ) : (
          <CircularProgress /> // Use CircularProgress to indicate loading
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
        
      )}
    </Box>
  );
};

export default CompanyFilter;
