import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Collapse,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
// import  from "@mui/material/Drawer";
import axios from "axios";
import { api } from "../../../../utils/api";
import { companyId, userId } from "../../../../utils/companyId";
import { FuelTypeEnum } from "../../../../utils/constant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";

function AddEquipment(props) {
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [facilities, setFacilities] = useState(["Select"]); // Store list of facilities
  const [equipmentType, setEquipmentType] = useState(["Select"]); // Store list of facilities
  const [fuelType, setFuelType] = useState(["Select"]); // Store list of facilities

  const [equipments, setEquipments] = useState(
    props.selectedFacility ? props.selectedFacility.equipments : []
  );

  const [noOfEquipment, setNoOfEquipment] = useState(
    props.selectedFacility?.noOfEquipment || 0
  );
  // Dynamically toggle collapse for each equipment
  const [openMachines, setOpenMachines] = useState(
    new Array(equipments.length).fill(false)
  );

  const [errors, setErrors] = useState({});
  const [fromValue, setFromValue] = useState({
    location: "",
    facility: "",
    equipmentType: "",
    name: "",
    fuelType: "",
    noOfEquipment: "",
    remark: "",
    companyId: companyId,
    userId: userId,
    equipments: [],
  });

  useEffect(() => {
    fetchFacilities();
    setFromValue({
      location: props.selectedFacility?.location ?? "",
      facility: props.selectedFacility?.facility?._id ?? "",
      equipmentType: props.selectedFacility?.equipmentType?._id ?? "",
      name: props.selectedFacility?.name ?? "",
      fuelType: props.selectedFacility?.fuelType ?? "",
      equipments: props.selectedFacility?.equipments ?? [],
      noOfEquipment: props.selectedFacility?.noOfEquipment ?? "",
      remark: props.selectedFacility?.remark ?? "",
      companyId: companyId,
      userId: userId,
    });
    setAlreadyFacility(props.selectedFacility === null ? false : true);
    setEquipments(props.selectedFacility?.equipments ?? []);
  }, [props.selectedFacility]);

  useEffect(() => {
    setNoOfEquipment(equipments.length);
  }, [equipments]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({
      ...fromValue,
      [name]: name === "noOfEquipment" ? Number(value) : value, // Ensure noOfEquipment is stored as a number
    });

    // When noOfEquipment changes, dynamically create forms
    if (name === "noOfEquipment") {
      const numOfForms = parseInt(value);
      const equipmentArray = Array.from({ length: numOfForms }, (_, index) => ({
        uniqueID: "",
        brand: "",
        model: "",
        ratedFuelConsumption: "",
        unitOfFuelConsumption: "",
      }));
      setEquipments(equipmentArray);
    }
  };

  const deleteEquipment = (index) => {
    setEquipments((prevEquipments) => {
      const updatedEquipments = [...prevEquipments];
      updatedEquipments.splice(index, 1);
      return updatedEquipments;
    });

    // Update the number of equipment
    setNoOfEquipment((prevCount) => prevCount - 1);

    // Optionally update the collapse state if needed
    setOpenMachines((prevOpenMachines) => {
      const updatedOpenMachines = [...prevOpenMachines];
      updatedOpenMachines.splice(index, 1);
      return updatedOpenMachines;
    });
  };

  const toggleEquipment = (index) => {
    const updatedOpen = [...openMachines];
    updatedOpen[index] = !updatedOpen[index];
    setOpenMachines(updatedOpen); //
  };

  const handleFieldChange = (index, field, value) => {
    const updatedEquipments = [...equipments];
    updatedEquipments[index][field] = value;
    setEquipments(updatedEquipments);
  };

  const addMoreEquipment = () => {
    const newEquipment = {
      uniqueID: "",
      brand: "",
      model: "",
      ratedFuelConsumption: "",
      unitOfFuelConsumption: "",
    };

    // Add new equipment and update state
    setEquipments((prevEquipments) => [...prevEquipments, newEquipment]);
    // Update the number of equipment
    setNoOfEquipment((prevCount) => prevCount + 1);
    // Update openMachines state to include a new entry
    setOpenMachines((prevOpenMachines) => [...prevOpenMachines, false]);
    console.log(noOfEquipment, "noOfEquipmentnoOfEquipment", equipments);
  };

  const fetchFacilities = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllFacility`);
      const equipmentTypeResponse = await axios.get(
        `${api}/master/getAllEquipmentType`
      );

      setFacilities(response.data.data);
      setEquipmentType(equipmentTypeResponse.data.data);
      setFuelType(FuelTypeEnum);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };
  const Save = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const updatedNoOfEquipment = equipments.length;

    const dataToSubmit = {
      ...fromValue, // all the current form values
      equipments: equipments,
      noOfEquipment: updatedNoOfEquipment, // Use the updated value
    };

    console.log(dataToSubmit, "dataToSubmitdataToSubmitdataToSubmit");
    if (!validateForm()) {
      return;
    }

    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${api}/master/updateIndustrialEquipment`
        : `${api}/master/addIndustrialEquipment`,
      data: dataToSubmit, // Send data only if it's a POST request
      params: alreadyFacility ? { id: props.selectedFacility?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        props.setAddFacility(false);
        props.fetchFacilities();
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        // setNoOfEquipment(null);
        resetForm();

        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const validateForm = () => {
    const newErrors = {};
    const { location, facility, equipmentType, name, fuelType, noOfEquipment } =
      fromValue;

    // Validate main fields
    if (!location || location.trim() === "") {
      newErrors.location = "Location is required.";
    }

    if (!facility || facility === "Select") {
      newErrors.facility = "Please select a facility.";
    }

    if (!equipmentType || equipmentType === "Select") {
      newErrors.equipmentType = "Please select an equipment type.";
    }

    if (!name || name.trim() === "") {
      newErrors.name = "Equipment name is required.";
    }

    if (!fuelType || fuelType.trim() === "") {
      newErrors.fuelType = "Fuel type is required.";
    }

    console.log(
      noOfEquipment,
      "andddddddddddd",
      typeof noOfEquipment,
      equipments.length
    );

    if (
      (!noOfEquipment ||
        isNaN(noOfEquipment) ||
        parseInt(noOfEquipment) <= 0) &&
      equipments.length === 0
    ) {
      newErrors.noOfEquipment = "Number of equipment must be a valid number.";
    }

    // Validate each equipment form
    equipments.forEach((equipment, index) => {
      if (!equipment.uniqueID || equipment.uniqueID.trim() === "") {
        newErrors[
          `equipment_${index}_uniqueID`
        ] = `Unique ID is required for Equipment ${index + 1}.`;
      }
      if (!equipment.brand || equipment.brand.trim() === "") {
        newErrors[
          `equipment_${index}_brand`
        ] = `Brand is required for Equipment ${index + 1}.`;
      }
      if (!equipment.model || equipment.model.trim() === "") {
        newErrors[
          `equipment_${index}_model`
        ] = `Model is required for Equipment ${index + 1}.`;
      }
      if (
        !equipment.ratedFuelConsumption ||
        equipment.ratedFuelConsumption.trim() === ""
      ) {
        newErrors[
          `equipment_${index}_ratedFuelConsumption`
        ] = `Rated Fuel Consumption is required for Equipment ${index + 1}.`;
      }
      if (
        !equipment.unitOfFuelConsumption ||
        equipment.unitOfFuelConsumption.trim() === ""
      ) {
        newErrors[
          `equipment_${index}_unitOfFuelConsumption`
        ] = `Unit of Fuel Consumption is required for Equipment ${index + 1}.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFromValue({
      location: "",
      facility: "",
      equipmentType: "",
      name: "",
      fuelType: "",
      noOfEquipment: "",
      remark: "",
      companyId: companyId,
      userId: userId,
      equipments: [],
    });

    setEquipments([]); // Reset equipments
    setNoOfEquipment(0); // Reset number of equipment
    setOpenMachines([]); // Reset open machines state if necessary
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: 450,
        // maxWidth:"100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-btween",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setAddFacility(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",

            mb: 2,
          }}
        >
          {props.selectedFacility?._id === undefined
            ? "Add Indusrial Equipment Master"
            : "Edit Indusrial Equipment Master"}
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
          Location
        </Typography>

        <CustomTextField
          //   select
          variant="outlined"
          value={fromValue.location}
          onChange={handleChange}
          fullWidth
          name="location"
          placeholder="Enter Name"
          error={!!errors.location} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.location ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.location && (
          <Typography variant="body2" color="#D34040">
            {errors.location}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Facility
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="facility"
          value={fromValue.facility}
          onChange={handleChange}
          placeholder="Select Facility"
          error={!!errors.facility} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          {facilities.map((facility) => (
            <MenuItem
              key={`facility-${facility._id}`} // Ensure this is unique
              value={facility._id}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Change background on hover
                  width: "100%",
                },
                padding: "10px 20px", // Adjust padding as needed
                borderRadius: "4px", // Add some border radius for a smoother look
                width: "35px",
              }}
            >
              {facility.facilityName} - {facility.address}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Equipment Type
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="equipmentType"
          value={fromValue.equipmentType}
          onChange={handleChange}
          placeholder="Select equipmentType"
          error={!!errors.equipmentType} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.equipmentType ? "#D34040" : undefined },
          }}
        >
          {equipmentType.map((item) => (
            <MenuItem
              key={`equipment_${item._id}`}
              value={item._id}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Change background on hover
                  width: "100%",
                },
                padding: "10px 20px", // Adjust padding as needed
                borderRadius: "4px", // Add some border radius for a smoother look
                width: "35px",
              }}
            >
              {item.name} -{item.description}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.equipmentType && (
          <Typography variant="body2" color="#D34040">
            {errors.equipmentType}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Equipment Name
        </Typography>

        <CustomTextField
          //  select
          variant="outlined"
          value={fromValue.name}
          onChange={handleChange}
          fullWidth
          name="name"
          placeholder="name"
          error={!!errors.name} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.name ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Fuel type
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="fuelType"
          value={fromValue.fuelType}
          onChange={handleChange}
          placeholder="Select fuelType"
          error={!!errors.fuelType} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.fuelType ? "#D34040" : undefined },
          }}
        >
          {fuelType.map((item, index) => (
            <MenuItem
              key={`fuelType__${item.name}${index}`}
              value={item}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Change background on hover
                  width: "100%",
                },
                padding: "10px 20px", // Adjust padding as needed
                borderRadius: "4px", // Add some border radius for a smoother look
                width: "35px",
              }}
            >
              {item}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.fuelType && (
          <Typography variant="body2" color="#D34040">
            {errors.fuelType}
          </Typography>
        )}

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          No of Equipment
        </Typography>

        <CustomTextField
          //  select
          disabled={alreadyFacility} // Disable if editing an existing facility
          variant="outlined"
          // value={fromValue.noOfEquipment}
          value={noOfEquipment} // Dynamically updated by addMoreEquipment or deleteEquipment
          onChange={(e) => {
            setNoOfEquipment(Number(e.target.value)); // Update state on change
            handleChange(e); // Call your existing handleChange function
          }}
          fullWidth
          type="number"
          name="noOfEquipment"
          placeholder="Enter No of Equipment"
          error={!!errors.noOfEquipment} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.noOfEquipment ? "#D34040" : undefined },
          }}
        ></CustomTextField>
        <br />
        {errors.noOfEquipment && (
          <Typography variant="body2" color="#D34040">
            {errors.noOfEquipment}
          </Typography>
        )}
        {/* Dynamically Render Equipment Forms */}
        {equipments.map((equipment, index) => (
          <Box key={index} sx={{ mt: 2 }}>
            {/* Equipment Title with Expand/Collapse Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Machinery {index + 1}
              </Typography>
              <Box>
                {/* Collapse button */}
                <IconButton onClick={() => toggleEquipment(index)}>
                  {openMachines[index] ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>

                {/* Delete button */}
                <IconButton
                  onClick={() => deleteEquipment(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>

            {/* Collapse for Equipment Fields */}
            <Collapse in={openMachines[index]} timeout="auto" unmountOnExit>
              <Box sx={{ mt: 1 }}>
                {/* Unique ID */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Unique ID
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.uniqueID}
                  placeholder="Enter ID"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(index, "uniqueID", e.target.value)
                  }
                  error={!!errors[`equipment_${index}_uniqueID`]}
                  helperText={errors[`equipment_${index}_uniqueID`]}
                />

                {/* Brand */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Brand
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.brand}
                  placeholder="Enter Brand"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(index, "brand", e.target.value)
                  }
                  error={!!errors[`equipment_${index}_brand`]}
                  helperText={errors[`equipment_${index}_brand`]}
                />

                {/* Model */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Model
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.model}
                  placeholder="Enter Model"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(index, "model", e.target.value)
                  }
                  error={!!errors[`equipment_${index}_model`]}
                  helperText={errors[`equipment_${index}_model`]}
                />

                {/* Rated Fuel Consumption */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Rated Fuel Consumption
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.ratedFuelConsumption}
                  placeholder="Amount"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(
                      index,
                      "ratedFuelConsumption",
                      e.target.value
                    )
                  }
                  error={!!errors[`equipment_${index}_ratedFuelConsumption`]}
                  helperText={errors[`equipment_${index}_ratedFuelConsumption`]}
                />

                {/* Unit of Fuel Consumption */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                >
                  Unit of Fuel Consumption
                </Typography>
                <CustomTextField
                  variant="outlined"
                  value={equipment.unitOfFuelConsumption}
                  placeholder="ltr/kg per hour"
                  fullWidth
                  onChange={(e) =>
                    handleFieldChange(
                      index,
                      "unitOfFuelConsumption",
                      e.target.value
                    )
                  }
                  error={!!errors[`equipment_${index}_unitOfFuelConsumption`]}
                  helperText={
                    errors[`equipment_${index}_unitOfFuelConsumption`]
                  }
                />
              </Box>
            </Collapse>
          </Box>
        ))}

        {alreadyFacility && (
          <Button
            onClick={addMoreEquipment}
            variant="contained"
            sx={{ mt: 2, backgroundColor: "#0a3f73" }}
          >
            Add Equipment
          </Button>
        )}

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Remark
        </Typography>

        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={handleChange}
          placeholder="Brief"
        />
        {errors.incharge && (
          <Typography variant="body2" color="#D34040">
            {errors.incharge}
          </Typography>
        )}

        {/* <Box> */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0a3f73",
            textTransform: "none",
            marginTop: "10%",
          }}
          onClick={Save}
        >
          Submit
        </Button>
        {/* </Box> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addFacility}
        onClose={() => props.setAddFacility(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddEquipment;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));
