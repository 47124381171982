import { Box, Typography, TextField, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

// Styled TextField
const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "40px",
    },
    "& .MuiSelect-select": {
        height: "56px",
        display: "flex",
        alignItems: "center",
    },
}));

const DateField = ({ title, name,handleChange, placeholder, value, errors }) => {
    const today = dayjs();
    const formattedValue = value ? dayjs(value) : null;

   return (
                <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                        {title}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{height:"56px"}}>
                        <Box>
                            <DatePicker
                                sx={{width:"100%"}}
                                name={name}
                                value={formattedValue}
                                onChange={(newValue) => {
                                    if (newValue && newValue.isAfter(today)) {
                                        handleChange({ name, value: null }); // Reset value or handle as needed
                                    } else {
                                        handleChange({ name, value: newValue ? newValue.format("YYYY-MM-DD") : null });
                                    }
                                }}
                                format="DD/MM/YYYY"
                                maxDate={today}
                                renderInput={(params) => (
                                    <CustomTextField
                                        fullWidth 
                                        {...params}
                                        error={!!errors}
                                        InputLabelProps={{
                                            style: { color: errors ? "#D34040" : undefined },
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: placeholder || 'DD/MM/YYYY',
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </LocalizationProvider>
                    {errors && (
                        <Typography variant="body2" color="#D34040">
                            {errors}
                        </Typography>
                    )}
                </Box>
            );
};

export default DateField;
