import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function ViewVehicle({ inputData, data, viewData, setViewData, handleEdit }) {
  const handleDownload = (url) => {
    const downloadUrl = url.replace("/upload/", "/upload/fl_attachment/"); // Force download as an attachment
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  
  const list = () => (
    <Box display="flex" gap={3} sx={{
      width: 400,
      maxWidth: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "transparent"
    }}>
      <Box sx={{
        backgroundColor: "#fff",
        height: "3%",
        padding: "13px",
        margin: "30px 0 0 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "80%",
        width: "5%"
      }}>
        <IconButton onClick={() => setViewData(false)} sx={{ color: "black" }}>
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ p: 3, width: "100%", backgroundColor: "#fff", height: "100%", overflow: "scroll" }}>
        <Typography variant="h6" sx={{
          textDecoration: 'underline',
          fontWeight: 'bold',
          mb: 2
        }}>
          View Vehicle Details
        </Typography>

        {inputData.map((input) => {
          const value = data?.[input.fieldName];

          // Only render the field if it has a value
          if (value === undefined || value === null) return null;

          let displayValue;

          // Handle specific fields for displaying text or images
          if (Array.isArray(value)) {
            // If value is an array, check if it's for document URLs
            displayValue = value.map((url, index) => (
              <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Box key={index} sx={{ position: "relative" }}>
              <img
                src={url}
                alt={`Document ${index + 1}`}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
              />
              <IconButton
                onClick={() => handleDownload(url)}
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  color: "white",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  width:"10px",
                  height:"10px"
                }}
              >
                <ArrowDownwardIcon sx={{ width:"10px",
                  height:"10px"}}/>
              </IconButton>
            </Box>
            </Box>
            ))}
          else if (input.fieldName === "associatedVendor" && typeof value === 'object' && value !== null) {
            displayValue = value.associatedVendor; // Assuming associatedVendor has a name field
          } else if (input.fieldName === "brand" || input.fieldName === "model") {
            displayValue = value.name; // Directly use the value for brand and model
          } else {
            displayValue = value; // Fallback for other fields
          }

          return (
            <Box key={input.fieldName} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {input.title}
              </Typography>
              <div>
                {Array.isArray(input.options) 
                  ? (input.options.includes(displayValue) ? displayValue : "N/A") 
                  : (displayValue ?? "N/A")}
              </div>
              {Array.isArray(value) && value.length > 0 && !input.fieldName.endsWith("Docs") && (
                <div>{displayValue}</div>
              )}
            </Box>
          );
        })}

        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10%" }}>
          <Button variant="contained" sx={{ backgroundColor: "#0a3f73", textTransform: "none" }} onClick={handleEdit}>
            Edit
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={viewData}
      onClose={() => setViewData(false)}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: "transparent",
          boxShadow: 'none',
        },
      }}
    >
      {list()}
    </Drawer>
  );
}

export default ViewVehicle;
