


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, TextField, Box, Typography, InputAdornment } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import { companyId } from "../../../utils/companyId";
// import { api } from '../../../utils/api';

// const Community = () => {
//   const [csrSpending, setCsrSpending] = useState('');
//   const [communityPrograms, setCommunityPrograms] = useState([{ number: '', scope: '' }]);
//   const [impactPrograms, setImpactPrograms] = useState([{ beneficiaries: '', outcomes: '' }]);
//   const [volunteerHours, setVolunteerHours] = useState([{ total_hours: '', total_volunteers: '' }]);
  
//   const navigate = useNavigate();

//   // Fetch data from the backend when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${api}/social/community_engagement/${companyId}`);
//         const data = response.data;

//         // Populate the state with fetched data
//         setCsrSpending(data.csrSpending.value || '');
//         setCommunityPrograms(data.communityInvestmentPrograms || [{ number: '', scope: '' }]);
//         setImpactPrograms(data.impactOfCommunityPrograms || [{ beneficiaries: '', outcomes: '' }]);
//         setVolunteerHours(data.volunteerHours || [{ total_hours: '', total_volunteers: '' }]);
//       } catch (error) {
//         console.error("Error fetching community engagement data:", error.message);
//       }
//     };

//     fetchData();
//   }, [companyId]);

//   // Handlers for form fields
//   const handleAddImpactProgram = () => {
//     setImpactPrograms([
//       ...impactPrograms,
//       { beneficiaries: "", outcomes: "" }, // New empty program
//     ]);
//   };

//   const handleCommunityProgramChange = (index, field, value) => {
//     const updatedPrograms = [...communityPrograms];
//     updatedPrograms[index][field] = value;
//     setCommunityPrograms(updatedPrograms);
//   };

//   const handleImpactProgramChange = (index, field, value) => {
//     const updatedImpactPrograms = [...impactPrograms];
//     updatedImpactPrograms[index][field] = value;
//     setImpactPrograms(updatedImpactPrograms);
//   };

//   const handleVolunteerHoursChange = (index, field, value) => {
//     const updatedVolunteerHours = [...volunteerHours];
//     updatedVolunteerHours[index][field] = value;
//     setVolunteerHours(updatedVolunteerHours);
//   };

//   const Save = async () => {
//     // Prepare the data to be sent to the backend
//     const postData = {
//       csrSpending: { value: parseFloat(csrSpending), unit: '%' },
//       communityInvestmentPrograms: communityPrograms.map((program) => ({
//         number: parseInt(program.number, 10),
//         scope: program.scope,
//       })),
//       impactOfCommunityPrograms: impactPrograms.map((program) => ({
//         beneficiaries: program.beneficiaries,
//         outcomes: program.outcomes,
//       })),
//       volunteerHours: volunteerHours.map((hours) => ({
//         total_hours: parseInt(hours.total_hours, 10),
//         total_volunteers: parseInt(hours.total_volunteers, 10),
//       })),
//       companyId: companyId,
//     };
//     try {
//       // Check if the record exists by making a GET request
//       const existingRecordResponse = await axios.get(
//         `${api}/social/community_engagement/${companyId}`
//       );
  
//       if (existingRecordResponse.status === 200 && existingRecordResponse.data) {
//         // Record exists, perform PUT request to update
//         const putResponse = await axios.put(
//           `${api}/social/community_engagement/${companyId}`,
//           postData
//         );
  
//         if (putResponse.status === 200) {
//           console.log("Community Engagement record updated successfully");
//           window.alert("Community Engagement record updated successfully!"); // Show alert for update
//           navigate("/social/human-right");
//         }
//       } else {
//         // Record does not exist, perform POST request to create
//         const postResponse = await axios.post(
//           `${api}/social/community_engagement`,
//           postData
//         );
  
//         if (postResponse.status === 201) {
//           console.log("Community Engagement record created successfully");
//           window.alert("Community Engagement record saved successfully!"); // Show alert for save
//           navigate("/social/human-right");
//         }
//       }
//     } catch (error) {
//       console.error("Error saving community engagement data:", error.message);
  
//       // If the error is due to the record not existing, make a POST request
//       if (error.response && error.response.status === 404) {
//         try {
//           const postResponse = await axios.post(
//             `${api}/social/community_engagement`,
//             postData
//           );
  
//           if (postResponse.status === 201) {
//             console.log("Community Engagement record created successfully");
//             window.alert("Community Engagement record saved successfully!"); // Show alert for save
//             navigate("/social/human-right");
//           }
//         } catch (postError) {
//           console.error(
//             "Error creating community engagement data:",
//             postError.message
//           );
//         }
//       }
//     }
//   };
  

//   const Back = () => {
//     navigate("/social/diversity");
//   };

//   return (
//     <Box display="flex" sx={{ height: '100%', backgroundColor: '#EDEDED' }}>
//       <Sidebar title="Community Engagement" />
//       <Box sx={{ width: '100%', padding: '16px'}}>
//         <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '32px 0px' }}>
//           SOCIAL: COMMUNITY ENGAGEMENT
//         </Typography>

//         {/* CSR Spending */}
//         <Box sx={{ marginBottom: '16px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px' }}>
//             Corporate Social Responsibility (CSR) Spending
//           </Typography>
//           <TextField
//             variant="outlined"
//             placeholder="Please enter number"
//             value={csrSpending}
//             onChange={(e) => setCsrSpending(e.target.value)}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                     <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                     %
//                   </Box>
//                 </InputAdornment>
//               )
//             }}
//             fullWidth
//             sx={{ backgroundColor: '#EDEDED', marginBottom: '15px', borderRadius: '4px' }}
//           />
//         </Box>

//         {/* Community Investment Programs */}
//         <Box sx={{ marginBottom: '20px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Community Investment Programs
//           </Typography>
//           <Box display="flex" gap="11px">
//             <TextField
//               variant="outlined"
//               placeholder="Number"
//               value={communityPrograms[0].number}
//               onChange={(e) => handleCommunityProgramChange(0, 'number', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#EDEDED', borderRadius: '4px' }}
//             />
//             <TextField
//               variant="outlined"
//               placeholder="Scope"
//               value={communityPrograms[0].scope}
//               onChange={(e) => handleCommunityProgramChange(0, 'scope', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#EDEDED', borderRadius: '4px' }}
//             />
//           </Box>
//         </Box>

//         {/* Impact of Community Programs */}
//         <Box sx={{ marginBottom: '16px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Impact of Community Programs
//           </Typography>
//           <Box display="flex" flexDirection="column" gap="10px">
//             {impactPrograms.map((program, index) => (
//               <Box key={index} display="flex" gap="11px">
//                 <TextField
//                   variant="outlined"
//                   placeholder="Beneficiaries"
//                   value={program.beneficiaries}
//                   onChange={(e) => handleImpactProgramChange(index, 'beneficiaries', e.target.value)}
//                   fullWidth
//                   sx={{ backgroundColor: '#EDEDED', borderRadius: '4px' }}
//                 />
//                 <TextField
//                   variant="outlined"
//                   placeholder="Outcomes"
//                   value={program.outcomes}
//                   onChange={(e) => handleImpactProgramChange(index, 'outcomes', e.target.value)}
//                   fullWidth
//                   sx={{ backgroundColor: '#EDEDED', borderRadius: '4px' }}
//                 />
//               </Box>
//             ))}
//             <Button
//               variant="outlined"
//               startIcon={<AddIcon />}
//               sx={{
//                 alignSelf: 'flex-end',
//                 color: '#204769',
//                 borderColor: '#204769',
//                 '&:hover': {
//                   backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                   color: '#115293',
//                   borderColor: '#115293',
//                 },
//               }}
//               onClick={handleAddImpactProgram}
//             >
//               Add
//             </Button>
//           </Box>
//         </Box>

//         {/* Volunteer Hours */}
//         <Box sx={{ marginBottom: '16px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Volunteer Hours
//           </Typography>
//           <Box display="flex" gap="11px">
//             <TextField
//               variant="outlined"
//               placeholder="Total Hours"
//               value={volunteerHours[0].total_hours}
//               onChange={(e) => handleVolunteerHoursChange(0, 'total_hours', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#EDEDED', borderRadius: '4px' }}
//             />
//             <TextField
//               variant="outlined"
//               placeholder="Total Volunteers"
//               value={volunteerHours[0].total_volunteers}
//               onChange={(e) => handleVolunteerHoursChange(0, 'total_volunteers', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#EDEDED', borderRadius: '4px' }}
//             />
//           </Box>
//         </Box>

//         {/* Save Button */}
//         <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{
//               color: '#204769',
//               borderColor: '#204769',
//               '&:hover': {
//                 backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                 color: '#115293',
//                 borderColor: '#115293',
//               },
//             }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{
//               backgroundColor: '#204769',
//               '&:hover': {
//                 backgroundColor: '#115293',
//               },
//             }}
//           >
//             Save and Continue
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Community;



////////////////////////////////////////////////////
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, TextField, Box, Typography, InputAdornment, IconButton } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import Sidebar from '../sidebar';
// import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useNavigate } from 'react-router-dom';
// import { companyId } from "../../../utils/companyId";
// import { api } from '../../../utils/api';
// import { percentageError, textError, numberError } from "../../../utils/validationMessage";

// const Community = () => {
//   const [csrSpending, setCsrSpending] = useState('');
//   const [communityPrograms, setCommunityPrograms] = useState([{ number: '', scope: '' }]);
//   const [impactPrograms, setImpactPrograms] = useState([{ beneficiaries: '', outcomes: '' }]);
//   const [volunteerHours, setVolunteerHours] = useState([{ total_hours: '', total_volunteers: '' }]);
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${api}/social/community_engagement/${companyId}`);
//         const data = response.data;

//         setCsrSpending(data.csrSpending?.value ?? '');
//         setCommunityPrograms(data.communityInvestmentPrograms || [{ number: '', scope: '' }]);
//         setImpactPrograms(data.impactOfCommunityPrograms || [{ beneficiaries: '', outcomes: '' }]);
//         setVolunteerHours(data.volunteerHours || [{ total_hours: '', total_volunteers: '' }]);
//       } catch (error) {
//         console.error("Error fetching community engagement data:", error.message);
//       }
//     };

//     fetchData();
//   }, [companyId]);

//   const validateFields = () => {
//     let tempErrors = {};

//     // Validate CSR Spending
//     // if (!csrSpending) {
//       if (csrSpending === '' || csrSpending === null) {
//       tempErrors.csrSpending = textError;
//     } else if (isNaN(csrSpending) || csrSpending < 0 || csrSpending > 100) {
//       tempErrors.csrSpending = percentageError;
//     }

//     // Validate Community Investment Programs
//     communityPrograms.forEach((program, index) => {
//       // if (!program.number) {
//         if (program.number === ''  || program.number === null) {
//         tempErrors[`communityPrograms-${index}-number`] = textError;
//       } else if (isNaN(program.number) || program.number < 0) {
//         tempErrors[`communityPrograms-${index}-number`] = numberError;
//       }

//       if (!program.scope) {
//         tempErrors[`communityPrograms-${index}-scope`] = textError;
//       }
//     });

//     // Validate Impact of Community Programs
//     impactPrograms.forEach((program, index) => {
//       if (!program.beneficiaries) {
//         tempErrors[`impactPrograms-${index}-beneficiaries`] = textError;
//       }
//       if (!program.outcomes) {
//         tempErrors[`impactPrograms-${index}-outcomes`] = textError;
//       }
//     });

//     // Validate Volunteer Hours
//     volunteerHours.forEach((hours, index) => {
//       if (hours.total_hours === ''  || hours.total_hours === null) {
//         tempErrors[`volunteerHours-${index}-total_hours`] = textError;
//       } else if (isNaN(hours.total_hours) || hours.total_hours < 0) {
//         tempErrors[`volunteerHours-${index}-total_hours`] = numberError;
//       }

//       // if (!hours.total_volunteers) {
//         if (hours.total_volunteers === ''  || hours.total_volunteers === null) {
//         tempErrors[`volunteerHours-${index}-total_volunteers`] = textError;
//       } else if (isNaN(hours.total_volunteers) || hours.total_volunteers < 0) {
//         tempErrors[`volunteerHours-${index}-total_volunteers`] = numberError;
//       }
//     });

//     setErrors(tempErrors);

//     return Object.keys(tempErrors).length === 0;
//   };

//   const Save = async () => {
//     if (!validateFields()) return;

//     const postData = {
//       csrSpending: { value: parseFloat(csrSpending), unit: '%' },
//       communityInvestmentPrograms: communityPrograms.map((program) => ({
//         number: parseInt(program.number, 10),
//         scope: program.scope,
//       })),
//       impactOfCommunityPrograms: impactPrograms.map((program) => ({
//         beneficiaries: program.beneficiaries,
//         outcomes: program.outcomes,
//       })),
//       volunteerHours: volunteerHours.map((hours) => ({
//         total_hours: parseInt(hours.total_hours, 10),
//         total_volunteers: parseInt(hours.total_volunteers, 10),
//       })),
//       companyId: companyId,
//     };

//     try {
//       const existingRecordResponse = await axios.get(`${api}/social/community_engagement/${companyId}`);

//       if (existingRecordResponse.status === 200 && existingRecordResponse.data) {
//         await axios.put(`${api}/social/community_engagement/${companyId}`, postData);
//       } else {
//         await axios.post(`${api}/social/community_engagement`, postData);
        
//       }

//       navigate("/social/human-right");
//     } catch (error) {
//       console.error("Error saving community engagement data:", error.message);
//     }
//   };

//   const Back = () => {
//     navigate("/social/diversity");
//   };

//   // Handle Community Investment Program Changes
//   const handleCommunityProgramChange = (index, field, value) => {
//     const updatedPrograms = [...communityPrograms];
//     updatedPrograms[index] = {
//       ...updatedPrograms[index],
//       [field]: value,
//     };
//     setCommunityPrograms(updatedPrograms);
//   };

//   // Handle Impact of Community Program Changes
//   const handleImpactProgramChange = (index, field, value) => {
//     const updatedPrograms = [...impactPrograms];
//     updatedPrograms[index] = {
//       ...updatedPrograms[index],
//       [field]: value,
//     };
//     setImpactPrograms(updatedPrograms);
//   };

//   // Handle Volunteer Hours Changes
//   const handleVolunteerHoursChange = (index, field, value) => {
//     const updatedVolunteerHours = [...volunteerHours];
//     updatedVolunteerHours[index] = {
//       ...updatedVolunteerHours[index],
//       [field]: value,
//     };
//     setVolunteerHours(updatedVolunteerHours);
//   };

//   const handleDeleteImpactProgram = (index) => {
//     const updatedPrograms = impactPrograms.filter((_, i) => i !== index);
//     setImpactPrograms(updatedPrograms);
//   };

  

//   return (
//     <Box sx={{
//       display: "flex",
//       backgroundColor: "#EDEDED",
//       height: "100vh",
//       overflow: "hidden" // Ensure no content gets hidden by scrollbars
//     }}>
//       <Sidebar title="Community Engagement" />
//       <Box sx={{
//         p: 2,
//         mt: 3,
//         width: "100%",
//         height: "100vh", // Full viewport height
//         overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "flex-start", // Adjust to start at the top of the box
//       }}>
//         <Typography variant="h5" sx={{
//           fontWeight: "bold",
//           zIndex: 1,
//           padding: "10px",
//           borderRadius: "4px",
//           alignSelf: "flex-start",
//           ml: "5rem"
//         }}>
//         SOCIAL: COMMUNITY ENGAGEMENT
//         </Typography>

//         {/* CSR Spending */}
//         <Box sx={{ marginBottom: '16px', width: '80%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px' }}>
//             Corporate Social Responsibility (CSR) Spending
//           </Typography>
//           <TextField
//             variant="outlined"
//             placeholder="Please enter number"
//             value={csrSpending}
//             onChange={(e) => setCsrSpending(e.target.value)}
//             error={!!errors.csrSpending}
//             helperText={errors.csrSpending}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                     <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                     %
//                   </Box>
//                 </InputAdornment>
//               )
//             }}
//             fullWidth
//             sx={{ 
//               backgroundColor: '#EDEDED',
//               marginBottom: '15px',
//              borderRadius: '4px',
//             '& .MuiFormHelperText-root': {
//                     color: '#D34040',  // Change the error message color
//                     fontSize: '14px', 
//                     marginLeft: '0px'
//                   },
//                  }}
//           />
//         </Box>

//         {/* Community Investment Programs */}
//         <Box sx={{ marginBottom: '20px', width: '80%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Community Investment Programs
//           </Typography>
//           {communityPrograms.map((program, index) => (
//             <Box key={index} display="flex" gap="11px" flexDirection={'column'}>
              
//               <TextField
//                 variant="outlined"
//                 placeholder="Scope"
//                 value={program.scope}
//                 onChange={(e) => handleCommunityProgramChange(index, 'scope', e.target.value)}
//                 error={!!errors[`communityPrograms-${index}-scope`]}
//                 helperText={errors[`communityPrograms-${index}-scope`]}
//                 fullWidth
//                 sx={{ 
//                   backgroundColor: '#EDEDED',
//                   borderRadius: '4px',
//                 '& .MuiFormHelperText-root': {
//                     color: '#D34040',
//                     fontSize: '14px', 
//                     marginLeft: '0px'
//                   },
//                  }}
//               />

//               <TextField
//                 variant="outlined"
//                 placeholder="Number"
//                 value={program.number}
//                 onChange={(e) => handleCommunityProgramChange(index, 'number', e.target.value)}
//                 error={!!errors[`communityPrograms-${index}-number`]}
//                 helperText={errors[`communityPrograms-${index}-number`]}
//                 fullWidth
//                 sx={{ 
//                   backgroundColor: '#EDEDED',
//                   borderRadius: '4px',
//                   '& .MuiFormHelperText-root': {
//                     color: '#D34040',
//                     fontSize: '14px', 
//                     marginLeft: '0px'
//                   },
//                  }}
//               />
//             </Box>
//           ))}
//         </Box>

//         {/* Impact of Community Programs */}
//         <Box sx={{ marginBottom: '20px', width: '80%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Impact of Community Programs
//           </Typography>
//           {impactPrograms.map((program, index) => (
//             <Box key={index} display="flex" gap="11px" sx={{width: '100%'}}>
//               <TextField
//                 variant="outlined"
//                 placeholder="Beneficiaries"
//                 value={program.beneficiaries}
//                 onChange={(e) => handleImpactProgramChange(index, 'beneficiaries', e.target.value)}
//                 error={!!errors[`impactPrograms-${index}-beneficiaries`]}
//                 helperText={errors[`impactPrograms-${index}-beneficiaries`]}
//                 fullWidth
//                 sx={{
//                    backgroundColor: '#EDEDED',
//                    borderRadius: '4px',
//                    marginBottom: '10px',
//                    '& .MuiFormHelperText-root': {
//                     color: '#D34040',
//                     fontSize: '14px', 
//                     marginLeft: '0px',
//                   },
                  
//                   }}
//               />
//               <TextField
//                 variant="outlined"
//                 placeholder="Outcomes"
//                 value={program.outcomes}
//                 onChange={(e) => handleImpactProgramChange(index, 'outcomes', e.target.value)}
//                 error={!!errors[`impactPrograms-${index}-outcomes`]}
//                 helperText={errors[`impactPrograms-${index}-outcomes`]}
//                 fullWidth
//                 sx={{ 
//                   backgroundColor: '#EDEDED',
//                   borderRadius: '4px',
//                   '& .MuiFormHelperText-root': {
//                     color: '#D34040',  // Change the error message color
//                     fontSize: '14px', 
//                     marginLeft: '0px'
//                   },
//                 }}
//               />
               
//               {/* <IconButton onClick={() => handleDeleteImpactProgram(index)} sx={{color:'#D34040'}}>
//                 <DeleteIcon />
//               </IconButton> */}

//               {index > 0 && (
//                 <Button  color="error" sx={{border: 'none'}} onClick={() => handleDeleteImpactProgram(index)}>
//                   <DeleteIcon />
//                 </Button>
//                   )}
//             </Box>
//           ))}
//           <Button
//           variant="outlined"
//           startIcon={<AddIcon />}
//           onClick={() => setImpactPrograms([...impactPrograms, { beneficiaries: '', outcomes: '' }])}
//           sx={{
//             backgroundColor: 'transparent',
//             color: '#204769',
//             borderColor: '#204769',
//             borderRadius: '4px',
//             padding: '6px 16px',
//             fontSize: '0.875rem',
//             fontWeight: 500,
//             lineHeight: 1.75,
//             letterSpacing: '0.02857em',
//             textTransform: 'uppercase',
//             margin: '2px 0px 0px 670px',
//             '&:hover': {
//               backgroundColor: 'rgba(25, 118, 210, 0.04)',
//               color: '#115293',
//               borderColor: '#115293',
//             },
//             display: 'inline-flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             transition: 'background-color 0.3s, color 0.3s',
//           }}
//         >
//           Add
//         </Button>
//         </Box>

//         {/* Volunteer Hours */}
//         <Box sx={{ marginBottom: '20px', width: '80%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Volunteer Hours
//           </Typography>
//           {volunteerHours.map((hours, index) => (
//             <Box key={index} display="flex" gap="11px">
//               <TextField
//                 variant="outlined"
//                 placeholder="Total Hours"
//                 value={hours.total_hours}
//                 onChange={(e) => handleVolunteerHoursChange(index, 'total_hours', e.target.value)}
//                 error={!!errors[`volunteerHours-${index}-total_hours`]}
//                 helperText={errors[`volunteerHours-${index}-total_hours`]}
//                 fullWidth
//                 sx={{
//                    backgroundColor: '#EDEDED',
//                     borderRadius: '4px',
//                   '& .MuiFormHelperText-root': {
//                     color: '#D34040',  // Change the error message color
//                     fontSize: '14px', 
//                     marginLeft: '0px'
//                   },
//                  }}
//               />
//               <TextField
//                 variant="outlined"
//                 placeholder="Total Volunteers"
//                 value={hours.total_volunteers}
//                 onChange={(e) => handleVolunteerHoursChange(index, 'total_volunteers', e.target.value)}
//                 error={!!errors[`volunteerHours-${index}-total_volunteers`]}
//                 helperText={errors[`volunteerHours-${index}-total_volunteers`]}
//                 fullWidth
//                 sx={{ 
//                   backgroundColor: '#EDEDED',
//                   borderRadius: '4px',
//                 '& .MuiFormHelperText-root': {
//                     color: '#D34040',  // Change the error message color
//                     fontSize: '14px', 
//                     marginLeft: '0px'
//                   },
//                  }}
//               />
//             </Box>
//           ))}
//          <ActionButtons Back={Back} Move={Save} />
//         </Box>

//         {/* <Box display="flex" justifyContent="space-between">
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{ marginRight: '16px', fontSize: '14px' }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{ fontSize: '14px' }}
//           >
//             Save and Continue
//           </Button>
//         </Box> */}
        
//       </Box>
//     </Box>
//   );
// };

// export default Community;







import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { Button, TextField, IconButton, Box, Typography, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { companyId } from "../../../utils/companyId";
import { api } from '../../../utils/api';
import { percentageError, textError, numberError } from "../../../utils/validationMessage";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {ProgressContext} from "../ContextAPI";

const Community = () => {
  const { setUpdate } = useContext(ProgressContext);

  const [csrSpending, setCsrSpending] = useState('');
  const [communityPrograms, setCommunityPrograms] = useState([{ number: '', scope: '' }]);
  const [impactPrograms, setImpactPrograms] = useState([{ beneficiaries: '', outcomes: '' }]);
  const [volunteerHours, setVolunteerHours] = useState([{ total_hours: '', total_volunteers: '' }]);
  const [errors, setErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
const [deleteIndex, setDeleteIndex] = useState(null);
  
  const navigate = useNavigate();

  // Fetch data from the backend when the component mounts
  useEffect(() => {
    setUpdate("SOCIAL");
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/social/community_engagement/${companyId}`);
        const data = response.data;

        // Populate the state with fetched data
        // setCsrSpending(data.csrSpending.value || '');
        setCsrSpending(data.csrSpending?.value ?? '');
        setCommunityPrograms(data.communityInvestmentPrograms || [{ number: '', scope: '' }]);
        setImpactPrograms(data.impactOfCommunityPrograms || [{ beneficiaries: '', outcomes: '' }]);
        setVolunteerHours(data.volunteerHours || [{ total_hours: '', total_volunteers: '' }]);
      } catch (error) {
        console.error("Error fetching community engagement data:", error.message);
      }
    };

    fetchData();
  }, [companyId]);


  const validateFields = () => {
        let tempErrors = {};
    
        // Validate CSR Spending
        // if (!csrSpending) {
          if (csrSpending === '' || csrSpending === null) {
          tempErrors.csrSpending = textError;
        } else if (isNaN(csrSpending) || csrSpending < 0 || csrSpending > 100) {
          tempErrors.csrSpending = percentageError;
        }
    
        // Validate Community Investment Programs
        communityPrograms.forEach((program, index) => {
          // if (!program.number) {
            if (program.number === ''  || program.number === null) {
            tempErrors[`communityPrograms-${index}-number`] = textError;
          } else if (isNaN(program.number) || program.number < 0) {
            tempErrors[`communityPrograms-${index}-number`] = numberError;
          }
    
          if (!program.scope) {
            tempErrors[`communityPrograms-${index}-scope`] = textError;
          }
        });
    
        // Validate Impact of Community Programs
        impactPrograms.forEach((program, index) => {
          if (!program.beneficiaries) {
            tempErrors[`impactPrograms-${index}-beneficiaries`] = textError;
          }
          if (!program.outcomes) {
            tempErrors[`impactPrograms-${index}-outcomes`] = textError;
          }
        });
    
        // Validate Volunteer Hours
        volunteerHours.forEach((hours, index) => {
          if (hours.total_hours === ''  || hours.total_hours === null) {
            tempErrors[`volunteerHours-${index}-total_hours`] = textError;
          } else if (isNaN(hours.total_hours) || hours.total_hours < 0) {
            tempErrors[`volunteerHours-${index}-total_hours`] = numberError;
          }
    
          // if (!hours.total_volunteers) {
            if (hours.total_volunteers === ''  || hours.total_volunteers === null) {
            tempErrors[`volunteerHours-${index}-total_volunteers`] = textError;
          } else if (isNaN(hours.total_volunteers) || hours.total_volunteers < 0) {
            tempErrors[`volunteerHours-${index}-total_volunteers`] = numberError;
          }
        });
    
        setErrors(tempErrors);
    
        return Object.keys(tempErrors).length === 0;
      };

 
    // Handle Community Investment Program Changes
  const handleCommunityProgramChange = (index, field, value) => {
    const updatedPrograms = [...communityPrograms];
    updatedPrograms[index] = {
      ...updatedPrograms[index],
      [field]: value,
    };
    setCommunityPrograms(updatedPrograms);
  };

  // Handle Impact of Community Program Changes
  const handleImpactProgramChange = (index, field, value) => {
    const updatedPrograms = [...impactPrograms];
    updatedPrograms[index] = {
      ...updatedPrograms[index],
      [field]: value,
    };
    setImpactPrograms(updatedPrograms);
  };

  // Handle Volunteer Hours Changes
  const handleVolunteerHoursChange = (index, field, value) => {
    const updatedVolunteerHours = [...volunteerHours];
    updatedVolunteerHours[index] = {
      ...updatedVolunteerHours[index],
      [field]: value,
    };
    setVolunteerHours(updatedVolunteerHours);
  };

  // const handleDeleteImpactProgram = (index) => {
  //   const updatedPrograms = impactPrograms.filter((_, i) => i !== index);
  //   setImpactPrograms(updatedPrograms);
  // };

  const handleDeleteImpactProgram = (index) => {
    setDeleteIndex(index);
    setDialogOpen(true);
  };
  
  // Confirm delete action
  const confirmDelete = () => {
    const updatedPrograms = impactPrograms.filter((_, i) => i !== deleteIndex);
    setImpactPrograms(updatedPrograms);
    setDialogOpen(false);
    setDeleteIndex(null);
  };
  
  // Cancel delete action
  const cancelDelete = () => {
    setDialogOpen(false);
    setDeleteIndex(null);
  };

  const Save = async () => {
    if (!validateFields()) return;
    // Prepare the data to be sent to the backend
    const postData = {
      csrSpending: { value: parseFloat(csrSpending), unit: '%' },
      communityInvestmentPrograms: communityPrograms.map((program) => ({
        number: parseInt(program.number, 10),
        scope: program.scope,
      })),
      impactOfCommunityPrograms: impactPrograms.map((program) => ({
        beneficiaries: program.beneficiaries,
        outcomes: program.outcomes,
      })),
      volunteerHours: volunteerHours.map((hours) => ({
        total_hours: parseInt(hours.total_hours, 10),
        total_volunteers: parseInt(hours.total_volunteers, 10),
      })),
      companyId: companyId,
    };
    try {
      // Check if the record exists by making a GET request
      const existingRecordResponse = await axios.get(
        `${api}/social/community_engagement/${companyId}`
      );
  
      if (existingRecordResponse.status === 200 && existingRecordResponse.data) {
        // Record exists, perform PUT request to update
        const putResponse = await axios.put(
          `${api}/social/community_engagement/${companyId}`,
          postData
        );
  
        if (putResponse.status === 200) {
          console.log("Community Engagement record updated successfully");
          // window.alert("Community Engagement record updated successfully!"); // Show alert for update
          navigate("/social/human-right");
        }
      } else {
        // Record does not exist, perform POST request to create
        const postResponse = await axios.post(
          `${api}/social/community_engagement`,
          postData
        );
  
        if (postResponse.status === 201) {
          console.log("Community Engagement record created successfully");
          // window.alert("Community Engagement record saved successfully!"); // Show alert for save
          navigate("/social/human-right");
        }
      }
    } catch (error) {
      console.error("Error saving community engagement data:", error.message);
  
      // If the error is due to the record not existing, make a POST request
      if (error.response && error.response.status === 404) {
        try {
          const postResponse = await axios.post(
            `${api}/social/community_engagement`,
            postData
          );
  
          if (postResponse.status === 201) {
            console.log("Community Engagement record created successfully");
            // window.alert("Community Engagement record saved successfully!"); // Show alert for save
            navigate("/social/human-right");
          }
        } catch (postError) {
          console.error(
            "Error creating community engagement data:",
            postError.message
          );
        }
      }
    }
  };
  

  const Back = () => {
    navigate("/social/diversity");
  };

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={cancelDelete}>
  <DialogTitle>Confirm Delete</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to delete this field?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={cancelDelete} color="primary">
      Cancel
    </Button>
    <Button onClick={confirmDelete} color="secondary">
      Confirm
    </Button>
  </DialogActions>
</Dialog>
    <Box sx={{
            display: "flex",
            backgroundColor: "#EDEDED",
            height: "100vh",
            overflow: "hidden" // Ensure no content gets hidden by scrollbars
          }}>
            <Sidebar title="Community Engagement" />
            <Box sx={{
              p: 2,
              mt: 3,
              width: "100%",
              height: "100vh", // Full viewport height
              overflowY: "auto", // Allow vertical scrolling if content exceeds viewport
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start", // Adjust to start at the top of the box
            }}>
              <Typography variant="h5" sx={{
                fontWeight: "bold",
                zIndex: 1,
                padding: "10px",
                borderRadius: "4px",
                alignSelf: "flex-start",
                ml: "5rem"
              }}>
              SOCIAL: COMMUNITY ENGAGEMENT
              </Typography>
      
              {/* CSR Spending */}
              <Box sx={{ marginBottom: '16px', width: '80%' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px', marginTop: '20px' }}>
                  Corporate Social Responsibility (CSR) Spending
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Please enter number"
                  value={csrSpending}
                  onChange={(e) => setCsrSpending(e.target.value)}
                  error={!!errors.csrSpending}
                  helperText={errors.csrSpending}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                          %
                        </Box>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  sx={{ 
                    backgroundColor: '#EDEDED',
                    marginBottom: '15px',
                   borderRadius: '4px',
                  '& .MuiFormHelperText-root': {
                          color: '#D34040',  // Change the error message color
                          fontSize: '14px', 
                          marginLeft: '0px'
                        },
                       }}
                />
              </Box>
      
              {/* Community Investment Programs */}
              <Box sx={{ marginBottom: '20px', width: '80%' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
                  Community Investment Programs
                </Typography>
                {communityPrograms.map((program, index) => (
                  <Box key={index} display="flex" gap="11px" flexDirection={'column'}>
                    
                    <TextField
                      variant="outlined"
                      placeholder="Scope"
                      value={program.scope}
                      onChange={(e) => handleCommunityProgramChange(index, 'scope', e.target.value)}
                      error={!!errors[`communityPrograms-${index}-scope`]}
                      helperText={errors[`communityPrograms-${index}-scope`]}
                      fullWidth
                      sx={{ 
                        backgroundColor: '#EDEDED',
                        borderRadius: '4px',
                      '& .MuiFormHelperText-root': {
                          color: '#D34040',
                          fontSize: '14px', 
                          marginLeft: '0px'
                        },
                       }}
                    />
      
                    <TextField
                      variant="outlined"
                      placeholder="Number"
                      value={program.number}
                      onChange={(e) => handleCommunityProgramChange(index, 'number', e.target.value)}
                      error={!!errors[`communityPrograms-${index}-number`]}
                      helperText={errors[`communityPrograms-${index}-number`]}
                      fullWidth
                      sx={{ 
                        backgroundColor: '#EDEDED',
                        borderRadius: '4px',
                        '& .MuiFormHelperText-root': {
                          color: '#D34040',
                          fontSize: '14px', 
                          marginLeft: '0px'
                        },
                       }}
                    />
                  </Box>
                ))}
              </Box>
      
              {/* Impact of Community Programs */}
              <Box sx={{ marginBottom: '20px', width: '80%' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
                  Impact of Community Programs
                </Typography>
                {impactPrograms.map((program, index) => (
                  <Box
                  key={index}
                  sx={{ width: "110%", display: "flex", gap: 1 }}
                >
                  <Box key={index} display="flex" gap="11px" sx={{width: '91%'}}>
                    <TextField
                      variant="outlined"
                      placeholder="Beneficiaries"
                      value={program.beneficiaries}
                      onChange={(e) => handleImpactProgramChange(index, 'beneficiaries', e.target.value)}
                      error={!!errors[`impactPrograms-${index}-beneficiaries`]}
                      helperText={errors[`impactPrograms-${index}-beneficiaries`]}
                      fullWidth
                      sx={{
                         backgroundColor: '#EDEDED',
                         borderRadius: '4px',
                         marginBottom: '10px',
                         '& .MuiFormHelperText-root': {
                          color: '#D34040',
                          fontSize: '14px', 
                          marginLeft: '0px',
                        },
                        
                        }}
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Outcomes"
                      value={program.outcomes}
                      onChange={(e) => handleImpactProgramChange(index, 'outcomes', e.target.value)}
                      error={!!errors[`impactPrograms-${index}-outcomes`]}
                      helperText={errors[`impactPrograms-${index}-outcomes`]}
                      fullWidth
                      sx={{ 
                        backgroundColor: '#EDEDED',
                        borderRadius: '4px',
                        '& .MuiFormHelperText-root': {
                          color: '#D34040',  // Change the error message color
                          fontSize: '14px', 
                          marginLeft: '0px'
                        },
                      }}
                    />
                     
                    {/* <IconButton onClick={() => handleDeleteImpactProgram(index)} sx={{color:'#D34040'}}>
                      <DeleteIcon />
                    </IconButton> */}
                    </Box>
                    {index > 0 && (
                      <IconButton  sx={{border: 'none'}} onClick={() => handleDeleteImpactProgram(index)}>
                        <DeleteIcon />
                      </IconButton>
                        )}
                  </Box>
                ))}
                <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setImpactPrograms([...impactPrograms, { beneficiaries: '', outcomes: '' }])}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#204769',
                  borderColor: '#204769',
                  borderRadius: '4px',
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: 1.75,
                  letterSpacing: '0.02857em',
                  textTransform: 'uppercase',
                  margin: '2px 0px 0px 670px',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    color: '#115293',
                    borderColor: '#115293',
                  },
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'background-color 0.3s, color 0.3s',
                }}
              >
                Add
              </Button>
              </Box>
      
              {/* Volunteer Hours */}
              <Box sx={{ marginBottom: '20px', width: '80%' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
                  Volunteer Hours
                </Typography>
                {volunteerHours.map((hours, index) => (
                  <Box key={index} display="flex" gap="11px">
                    <TextField
                      variant="outlined"
                      placeholder="Total Hours"
                      value={hours.total_hours}
                      onChange={(e) => handleVolunteerHoursChange(index, 'total_hours', e.target.value)}
                      error={!!errors[`volunteerHours-${index}-total_hours`]}
                      helperText={errors[`volunteerHours-${index}-total_hours`]}
                      fullWidth
                      sx={{
                         backgroundColor: '#EDEDED',
                          borderRadius: '4px',
                        '& .MuiFormHelperText-root': {
                          color: '#D34040',  // Change the error message color
                          fontSize: '14px', 
                          marginLeft: '0px'
                        },
                       }}
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Total Volunteers"
                      value={hours.total_volunteers}
                      onChange={(e) => handleVolunteerHoursChange(index, 'total_volunteers', e.target.value)}
                      error={!!errors[`volunteerHours-${index}-total_volunteers`]}
                      helperText={errors[`volunteerHours-${index}-total_volunteers`]}
                      fullWidth
                      sx={{ 
                        backgroundColor: '#EDEDED',
                        borderRadius: '4px',
                      '& .MuiFormHelperText-root': {
                          color: '#D34040',  // Change the error message color
                          fontSize: '14px', 
                          marginLeft: '0px'
                        },
                       }}
                    />
                  </Box>
                ))}
               <ActionButtons Back={Back} Move={Save} />
              </Box>
      
              
              
            </Box>
          </Box>
          </Box>
  );
};

export default Community;
