import { Box, FormControl, TextField, InputLabel, styled, MenuItem, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { api } from '../../utils/api';

const SelectField = ({ title, value, handleChange, name, options, errors }) => {
    const [newOptions, setNewOptions] = useState([]);

    // Styled TextField
    const CustomTextField = styled(TextField)(({ theme }) => ({
        "& .MuiInputBase-root": {
            height: "40px", // Adjust height as needed
        },
        "& .MuiSelect-select": {
            height: "56px", // Adjust height as needed
            display: "flex",
            alignItems: "center",
        },
    }));

   // Fetch options
   const fetchOptions = async () => {
    try {
        const res = await axios.get(name === "brand" ? `${api}/master/getAllBrand` : `${api}/master/getAllModel`);
        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
            // Store both name and id in the state
            setNewOptions(res.data.data.map(item => ({
                id: item._id, // Assuming the ID is stored in _id
                name: item.name,
            })));
        }
        console.log(res);
    } catch (err) {
        console.log(err);
    }
};


    useEffect(() => {
        fetchOptions();
    }, [name]); // Add `name` to dependency array to refetch on name change

    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 1 }}>
                {title}
            </Typography>
            <FormControl fullWidth margin="normal" sx={{ mt: 0 }}>
                <CustomTextField
                    select
                    name={name}
                    value={value? value : "Tap to select"} // Set value to an empty string if undefined
                    onChange={handleChange}
                >
                    <MenuItem value={value? value : "Tap to select"}disabled>
                        Tap to select
                    </MenuItem>
                    {(options && options.length > 0 ? options : newOptions).map(option => (
                        <MenuItem key={option.id || option} value={option.id || option}>
                            {option.name || option}
                        </MenuItem>
                    ))}
                </CustomTextField>
                {errors && (
                    <Typography variant="body2" color="#D34040">
                        {errors}
                    </Typography>
                )}
            </FormControl>
        </Box>
    );
};

export default SelectField;
