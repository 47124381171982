import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { api } from "../../../../../utils/api";
import { companyId, userId } from "../../../../../utils/companyId";

function AddIndustrialProcess(props) {
  const [alreadyFacility, setAlreadyFacility] = useState(false);
  const [facilities, setFacilities] = useState(["Select"]); // Store list of facilities
  const [errors, setErrors] = useState({});
  const [fromValue, setFromValue] = useState({
    facility: "",
    location: "",
    industryProcess: "",
    finalManufacturedProduct: "",
    remark: "",
    documents: [],
    companyId: companyId,
    userId: userId,
  });

  useEffect(() => {
    fetchFacilities();
    setFromValue({
      facility: props.selectedFacility?.facility?.facilityName || "",
      location: props.selectedFacility?.location || "",
      industryProcess: props.selectedFacility?.industryProcess || "",
      finalManufacturedProduct:
        props.selectedFacility?.finalManufacturedProduct || "",
      remark: props.selectedFacility?.remark || "",
      documents: props.selectedFacility?.documents || [],
      companyId: companyId,
      userId: userId,
    });
    setAlreadyFacility(props.selectedFacility === null ? false : true);
  }, [props.selectedFacility]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({
      ...fromValue,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    const {
      location,
      industryProcess,
      finalManufacturedProduct,
      facility, // Add facility to validation
      documents,
    } = fromValue;

    // Validate `Location`
    if (!location || location.trim() === "") {
      newErrors.location = "Location is required.";
    }

    // Validate `address`
    if (!industryProcess || industryProcess.trim() === "") {
      newErrors.industryProcess = "Industry process is required.";
    }

    // Validate `finalManufacturedProduct`
    if (!finalManufacturedProduct || finalManufacturedProduct.trim() === "") {
      newErrors.finalManufacturedProduct =
        "Final Manufacture Product is required.";
    }

    // Validate `Facility`
    if (!facility || facility.trim() === "") {
      newErrors.facility = "Facility selection is required.";
    }

    // Validate `Documents`
    if (!documents || documents.length === 0) {
      newErrors.documents = "At least one document must be uploaded.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    // Limit to max 5 files
    if (files.length > 5) {
      alert("You can only upload a maximum of 5 files.");
      return;
    }

    const allowedTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls,
      "image/png",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert(`File type not allowed: ${file.name}`);
        return;
      }

      // Check file size
      if (file.size > maxFileSize) {
        alert(`File size exceeds 5MB: ${file.name}`);
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    console.log(formData)
    try {
      const uploadResponse = await axios.post(`${api}/cloud/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedUrls = uploadResponse.data.urls; // Assuming your backend sends back an array of URLs
      console.log(uploadedUrls)
      setFromValue((prev) => ({
        ...prev,
        documents: uploadedUrls,
      }));
    } catch (err) {
      console.error("Error uploading files:", err);
    }
  };

  // Fetch facilities data from API
  const fetchFacilities = async () => {
    try {
      const response = await axios.get(`${api}/master/getAllFacility`);
      setFacilities(response.data.data);
    } catch (error) {
      console.log("Error fetching facilities:", error);
    }
  };

  const Save = () => {
    const dataToSend = { ...fromValue };
    if (!validateForm()) {
      return;
    }
    axios({
      method: alreadyFacility ? "put" : "post",
      url: alreadyFacility
        ? `${api}/master/updateIndustrialProcess`
        : `${api}/master/addIndustrialProcess`,
      data: dataToSend, // Send data only if it's a POST request
      params: alreadyFacility ? { Id: props.selectedFacility?._id } : null, // Send params only for PUT request
    })
      .then((res) => {
        props.setAddFacility(false);
        props.setsnackOpen({
          open: true,
          message: res.data.message,
          severity: "success",
        });
        props.fetchFacilities(); // Call the fetchFacilities function after success

        //  localStorage.setItem("roleId",res.data.data._id)
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message) {
          // If there is a message in the error response, use it
          props.setsnackOpen({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        }
      });
  };

  const list = () => (
    <Box
      display="flex"
      gap={3}
      sx={{
        width: "420px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          height: "20px",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%",
        }}
      >
        <IconButton
          onClick={() => props.setAddFacility(false)}
          sx={{ color: "black" }}
        >
          <CloseIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
      <Box sx={{ width: "90%", p: 3, backgroundColor: "#fff" }}>
        <Typography
          sx={{
            textDecoration: "underline",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Add Industrial Process
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Location
        </Typography>
        <CustomTextField
          variant="outlined"
          fullWidth
          name="location"
          value={fromValue.location}
          onChange={handleChange}
          placeholder="Brief"
          error={!!errors.location} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.location ? "#D34040" : undefined },
          }}
        />
        {errors.location && (
          <Typography variant="body2" color="#D34040">
            {errors.location}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Facility
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          fullWidth
          name="facility"
          value={fromValue.facility}
          onChange={handleChange}
          placeholder="Select Facility"
          error={!!errors.facility} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.facility ? "#D34040" : undefined },
          }}
        >
          {facilities.map((facility) => (
            <MenuItem
              key={facility._id}
              value={facility._id}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Change background on hover
                  width: "100%",
                },
                padding: "10px 20px", // Adjust padding as needed
                borderRadius: "4px", // Add some border radius for a smoother look
                width: "35px",
              }}
            >
              {facility.facilityName} - {facility.address}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Industry Process
        </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          name="industryProcess"
          value={fromValue.industryProcess}
          onChange={handleChange}
          placeholder="Brief"
          error={!!errors.industryProcess} // Show error styling if there's an error
          InputLabelProps={{
            style: { color: errors.industryProcess ? "#D34040" : undefined },
          }}
        />
        {errors.industryProcess && (
          <Typography variant="body2" color="#D34040">
            {errors.industryProcess}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Final Manufactured Product
        </Typography>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          name="finalManufacturedProduct"
          value={fromValue.finalManufacturedProduct}
          onChange={handleChange}
          placeholder="Brief"
          error={!!errors.finalManufacturedProduct} // Show error styling if there's an error
          InputLabelProps={{
            style: {
              color: errors.finalManufacturedProduct ? "#D34040" : undefined,
            },
          }}
        />
        {errors.finalManufacturedProduct && (
          <Typography variant="body2" color="#D34040">
            {errors.finalManufacturedProduct}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Remark
        </Typography>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="remark"
          value={fromValue.remark}
          onChange={handleChange}
          placeholder="Brief"
        />
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
        >
          Upload Documents
        </Typography>
        <Box
          sx={{
            border: "1px dashed grey",
            borderRadius: 1,
            p: 2,
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            hidden
            id="upload-document"
            onChange={handleFileChange}
            multiple // Allow multiple file selection
          />
          <label htmlFor="upload-document">
            <Box display="flex" flexDirection="column" alignItems="center">
              <CloudUploadIcon />
              <Typography>Drop files here or click to upload</Typography>
              <Typography variant="caption">
                Upload your file (CSV, Excel, PNG, max 5 MB, recommended size
                1200x800 pixels).
              </Typography>
            </Box>
          </label>
        </Box>
        {errors.documents && (
          <Typography variant="body2" color="#D34040">
            {errors.documents}
          </Typography>
        )}

        {/* Uploaded Documents */}
        {/* {fromValue.documents.length > 0 && (
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
            >
              Uploaded Documents
            </Typography>
            <Box>
              {fromValue.documents.map((document, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                    {document}{" "}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )} */}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={() => props.setAddFacility(false)}
          >
            Cancel
          </Button> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0a3f73",
              textTransform: "none",
              marginTop: "10%",
            }}
            onClick={Save}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        sx={{
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            // This targets the paper component within the Drawer
            backgroundColor: "transparent", // Make paper transparent
            boxShadow: "none", // Optional: Remove any shadow
          },
        }}
        anchor="right"
        open={props.addFacility}
        onClose={() => props.setAddFacility(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default AddIndustrialProcess;

//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "40px", // Adjust height as needed
  },
  "& .MuiSelect-select": {
    height: "56px", // Adjust height as needed
    display: "flex",
    alignItems: "center",
  },
}));



