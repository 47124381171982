    import { Box, Drawer, IconButton } from '@mui/material';
    import React from 'react';
    import FilterSidebar from './Filter/FilterSidebar';
    import ApplyButton from '../../Pages/Masters/Roles and Permissions/Filters/ApplyButton';
    import ResetButton from '../../Pages/Masters/Roles and Permissions/Filters/ResetButton';
    import CloseIcon from "@mui/icons-material/Close";

    const FilterDrawer = ({
    renderSelectedFilter,
    setDrawerFilterOpen,
    drawerFilterOpen,
    handleApplyFilters,
    handleFilterDrawer,
    setSelectedFilter,
    handleResetFilters,
    selectedFilter,
    filters,
    appliedFilters,
    }) => {
    return (
        <Drawer
        anchor="right"
        open={drawerFilterOpen}
        onClose={handleFilterDrawer}
        sx={{
            backgroundColor: "transparent",
            "& .MuiDrawer-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "50%",
            },
        }}
        >
        <Box
            display="flex"
            gap={3}
            sx={{
            width: "100%",
            overflowX: "hidden",
            maxWidth: "100%",
            justifyContent: "space-between",
            // height: "100%",
            backgroundColor: "transparent",
            }}
        >
            <Box
            sx={{
                backgroundColor: "#f5f5f5",
                padding: "13px",
                margin: "30px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                width: "3%",
                height:"3%"
            }}
            >
            <IconButton
                onClick={() => setDrawerFilterOpen(false)}
                sx={{ color: "black" }}
            >
                <CloseIcon sx={{ fontSize: "1.2rem" }} />
            </IconButton>
            </Box>
            <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
            {/* Sidebar for selecting the filter */}
            <FilterSidebar
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                filters={filters}
                appliedFilters={appliedFilters}
            />
            
            {/* Filter content area */}
            <Box
                sx={{
                flexGrow: 1,
                backgroundColor: "#F9F9F9",
                padding: "20px",
                }}
            >
                {renderSelectedFilter()}

                <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    gap: "3%",
                    position: "fixed",
                    bottom: "20px",
                    marginLeft:"25px"
                    // left: "3%",
                    // right: "3%",
                }}
                >
                <ApplyButton handleApply={handleApplyFilters} />
                <ResetButton handleReset={handleResetFilters} />
                </Box>
            </Box>
            </Box>
        </Box>
        </Drawer>
    );
    };

    export default FilterDrawer;
