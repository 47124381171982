// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   IconButton,
//   Toolbar,
//   Typography,
//   Box,
//   Grid,
//   Stack,
//   Menu,
//   MenuItem,
//   Pagination,
//   PaginationItem,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   Drawer,
//   Select,
//   MenuItem as MuiMenuItem,
//   FormControl,
//   InputLabel,
// } from '@mui/material';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import AddIcon from '@mui/icons-material/Add';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import { styled } from '@mui/material/styles';
// import AddUsers from './AddUser';
// import ViewUser from './ViewUser';
// import Role from './Roles'; // Update the path as necessary
// import CountryFilter from './Filters/UserFilters/CountryFilter';
// import { api } from '../../../utils/api';
// import CompanyFilter from './Filters/UserFilters/CompanyFilter';
// import RoleFilter from './Filters/UserFilters/UserRoleFilter';
// import UserDateFilter from './Filters/UserFilters/UserDateFilter';
// import FilterSidebar from '../../../Components/Masters/Filter/FilterSidebar';

// const User = () => {
//   const [showRoles, setShowRoles] = useState(false);
//   const [page, setPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(6);
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedUserIndex, setSelectedUserIndex] = useState(null);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [indexUser, setindexUser] = useState(null);
//   const [indexUserView, setindexUserView] = useState(null);
//   const [isDrawerOpenEdit, setIsDrawerOpenEdit] = useState(false);
//   const [deleteDialog, setDeleteDialog] = useState(false);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [totalPages, setTotalPages] = useState(1);
//   const [users, setUsers] = useState([]);
//   const [countryFilter, setCountryFilter] = useState([]);
//   const [selectedCountries, setSelectedCountries] = useState([]);
//   const [userList, setUserList] = useState([]); // State to store filtered users
//   const [loading, setLoading] = useState(false);
//   const [companyFilterOpen, setCompanyFilterOpen] = useState(false);
//   const [selectedCompanies, setSelectedCompanies] = useState([]);
//   const [roleFilters, setRoleFilters] = useState([]);
//   const [DeleteIndex,setDeleteIndex]=useState(null);

//   const filters = ["Country", "Company Name", "Role", "Date"];

//   const fetchUsers = async (filters = {}) => {
//     setLoading(true); // Start loading

//     try {
//       const queryParams = new URLSearchParams({
//         ...filters,
//         page, // Add the current page
//         limit: rowsPerPage, // Add the number of rows per page
//         sortField:  'name', // Sort configuration
//         sortOrder: sortConfig.direction
//       }).toString();

//       console.log("Query Params sent to API:", queryParams); // Debugging line

//       const response = await fetch(`${api}/auth/getAllUsers?${queryParams}`);
//       const data = await response.json();

//       console.log("Fetched data after filtering:", data); // Debugging line

//       if (response.ok) {
//         // setUsers(usersData.filter((item)=>item.deactivated === false));
//         setUsers(data.users.filter((item)=>item.deactivated === false)); // Update the user list
//         setTotalPages(data.totalPages); // Update total pages for pagination
//       } else {
//         console.error("Error fetching users:", data.error);
//       }
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   useEffect(() => {
//     console.log("Fetching users without filters...");
//     fetchUsers(); // Fetch all users on mount
//   }, [page, rowsPerPage, sortConfig]);

//   const handleFilterChange = (selectedCountries) => {
//     setCountryFilter(selectedCountries);
//   };

//   const handleRowsPerPageChange = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(1); // Reset to first page on rows per page change
//   };

//   const toggleDrawer = (open) => () => {
//     setDrawerOpen(open);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleSort = (column, direction) => {
//     setSortConfig({ key: column, direction });
//   };

//   const handleMenuClick = (event, index) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedUserIndex(index);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedUserIndex(null);
//   };

//   const AddUser=()=>{
//     setIsDrawerOpen(true)
//   }
// const handleView=(index)=>{
//   setindexUserView(index)
//  console.log("hii",index)
//   setAnchorEl(null);
//   setSelectedUserIndex(null);
//   setIsDrawerOpenEdit(true)
//   // openDrawerEdit();
// }
// const handleEdit=(index)=>{
//  setindexUser(index)
//   console.log("hii",index)
//    setAnchorEl(null);
//    setSelectedUserIndex(null);
//    setIsDrawerOpen(true)
//    setIsDrawerOpenEdit(false)
//    // openDrawerEdit();
//  }
//  const handleDelete=(index)=>{
//   // setindexUserView(index)
//   setDeleteIndex(index)
//   console.log(index,"index++")

//   setDeleteDialog(true);
//   setAnchorEl(null);
//   setSelectedUserIndex(null);

//  }

//  const handleDeleteAggree = () => {
//   console.log("indexhandleDeleteAggree",DeleteIndex)
//   axios.put(`${api}/auth/DeleteUser`,{deactivated:true},{
//     params: {
//       Id: DeleteIndex // Pass as query parameter
//     }
// }).then((res)=>{
//   console.log(res,"res++")
//   setDeleteDialog(false);
//   setAnchorEl(null);
//   setUsers(users.filter((item)=>item._id !== res.data.data._id));
//   setSelectedUserIndex(null);
// }).catch((err)=>{
//   console.log(err)
// })

// };
//   // const handleFilterChange = (event) => {
//   //   const { name, value } = event.target;
//   //   setFilters({ ...filters, [name]: value });
//   // };

//   // const handleApplyFilters = () => {
//   //   console.log("Selected countries:", selectedCountries);
//   //   setPage(1); // Reset to first page on filter application
//   //   fetchUsers({
//   //     country: selectedCountries.join(',') // Pass selected countries
//   //   });
//   // };

//   const handleApplyFilters = () => {
//     console.log("Selected countries:", selectedCountries);
//     console.log("Selected companies:", selectedCompanies); // Log selected companies

//     setPage(1); // Reset to first page on filter application

//     fetchUsers({
//       country: selectedCountries.join(","),
//       companyName: selectedCompanies.join(","), // Add companyName filter
//       role: roleFilters.join(","),
//     });
//   };

//   const handleRoleFilterChange = (selectedRoles) => {
//     setRoleFilters(selectedRoles);
//   };

//   const open = Boolean(anchorEl);

//   const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
//     "&.Mui-selected": {
//       backgroundColor: "green",
//       color: "#fff",
//       fontWeight: "bold",
//     },
//     "&:hover": {
//       backgroundColor: "lightgray",
//     },
//   }));

//   const [selectedFilter, setSelectedFilter] = useState("Country"); // Default filter is "Date"

//   // Function to render the selected filter component based on user's choice
//   const renderSelectedFilter = () => {
//     switch (selectedFilter) {
//       case "Country":
//         return (
//           <CountryFilter
//             onFilterChange={handleFilterChange}
//             selectedCountries={selectedCountries}
//             setSelectedCountries={setSelectedCountries}
//           />
//         );
//       case "Company Name":
//         return (
//           <CompanyFilter
//             open={companyFilterOpen}
//             onClose={toggleCompanyFilterDrawer(false)}
//             onFilterChange={handleCompanyFilterChange} // Update selectedCompanies
//             handleApplyFilters={handleApplyFilters} // Apply the filters on click
//           />
//         );
//       case "Role":
//         return <RoleFilter onRoleFilterChange={handleRoleFilterChange} />;
//       case "Date":
//         return <UserDateFilter onRoleFilterChange={handleRoleFilterChange} />;

//       default:
//         return (
//           <CountryFilter
//             onFilterChange={handleFilterChange}
//             selectedCountries={selectedCountries}
//             setSelectedCountries={setSelectedCountries}
//           />
//         );
//     }
//   };

//   const handleAddUser = () => {
//     setIsDrawerOpen(true);
//   };

//   const handleCompanyFilterChange = (companyName, checked) => {
//     setSelectedCompanies(
//       (prevSelectedCompanies) =>
//         checked
//           ? [...prevSelectedCompanies, companyName] // Add company if checked
//           : prevSelectedCompanies.filter((company) => company !== companyName) // Remove company if unchecked
//     );
//   };

//   const toggleCompanyFilterDrawer = (open) => () => {
//     setCompanyFilterOpen(open);
//   };

//   if (showRoles) {
//     return <Role />;
//   }

//   return (
//     <Box
//       sx={{
//         padding: "20px",
//         backgroundColor: "#ededed",
//         height: "100vh",
//         overflowY: "scroll",
//         overflowX: "hidden",
//       }}
//     >
//       {/* Render the CountryFilter */}
//       {/* <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}> */}
//       <Drawer
//         anchor="right"
//         open={drawerOpen}
//         onClose={toggleDrawer(false)}
//         PaperProps={{
//           style: {
//             width: "45%",
//           },
//         }}
//       >
//         <Box sx={{ display: "flex",  width: "100%" }}>
//           {/* Sidebar for selecting the filter */}
//           <FilterSidebar filters={filters} onSelect={setSelectedFilter} />

//           {/* Filter content area */}
//           <Box
//             sx={{ flexGrow: 1, backgroundColor: "#F9F9F9", padding: "20px" }}
//           >
//             {renderSelectedFilter()}
//             {/* <ApplyButton handleApply={handleApplyFilters} /> */}
//             <Button
//           variant="contained"
//           color="primary"
//           onClick={handleApplyFilters}
//           sx={{ marginTop: '250px', width:'20%', backgroundColor: "#1c486b", color: "white", marginLeft:'5%', padding:'5px 40px'}}
//         >
//           Apply
//         </Button>
//           </Box>
//         </Box>
//       </Drawer>

//       <AddUsers
//         value={isDrawerOpen}
//         setValue={setIsDrawerOpen}
//         index={indexUser}
//       />
//       <ViewUser
//         values={isDrawerOpenEdit}
//         setValues={setIsDrawerOpenEdit}
//         indexView={indexUserView}
//       />

//       {deleteDialog && (
//         <Dialog
//           open={deleteDialog}
//           keepMounted
//           aria-describedby="alert-dialog-slide-description"
//           maxWidth="lg"
//         >
//           <DialogContent>
//             <DialogContentText id="alert-dialog-slide-description">
//               Are you sure you want to Delete?
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button
//               sx={{ textTransform: "none" }}
//               onClick={() => setDeleteDialog(false)}
//             >
//               Disagree
//             </Button>
//             <Button sx={{ textTransform: 'none' }} onClick={handleDeleteAggree}>
//               Agree
//             </Button>
//           </DialogActions>
//         </Dialog>
//       )}

//       <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
//         <Grid
//           container
//           alignItems="center"
//           sx={{ width: "80%", margin: "5% 4% 0% 22%" }}
//         >
//           <Grid item xs={6}>
//             <Typography
//               variant="h6"
//               sx={{ fontWeight: "bold", marginBottom: "8px" }}
//             >
//               Roles and Permission
//             </Typography>
//             <Stack direction="row" spacing={2}>
//               <Button
//                 variant="contained"
//                 onClick={() => setShowRoles(false)} // Show Users
//                 sx={{
//                   fontSize: "14px",
//                   color: "#FFFFFF",
//                   textTransform: "none",
//                   backgroundColor: "#1c486b",
//                 }}
//               >
//                 List of Users
//               </Button>
//               <Button
//                 variant="contained"
//                 onClick={() => setShowRoles(true)} // Show Roles
//                 sx={{
//                   backgroundColor: "#e0e0e0",
//                   fontSize: "14px",
//                   color: "black",
//                   textTransform: "none",
//                   "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
//                 }}
//               >
//                 List of Roles
//               </Button>
//             </Stack>
//           </Grid>
//           <Grid item xs={6} style={{ textAlign: "right" }}>
//             <Button
//               variant="contained"
//               endIcon={<FilterListIcon />}
//               onClick={toggleDrawer(true)}
//               sx={{
//                 borderRadius: "4px",
//                 padding: "6px 16px",
//                 marginRight: "8px",
//                 fontSize: "14px",
//                 textTransform: "none",
//                 backgroundColor: "#1c486b",
//                 color: "#FFFFFF",
//               }}
//             >
//               Filter
//             </Button>
//             <Button
//               variant="contained"
//               onClick={handleAddUser}
//               endIcon={<AddIcon />}
//               sx={{
//                 borderRadius: "4px",
//                 padding: "6px 16px",
//                 backgroundColor: "#1c486b",
//                 fontSize: "14px",
//                 textTransform: "none",
//                 color: "#FFFFFF",
//               }}
//             >
//               Add New User
//             </Button>
//           </Grid>
//         </Grid>
//       </Toolbar>

//       <TableContainer
//         component={Paper}
//         sx={{ maxWidth: "74%", margin: "0% 5% 0% 23%" }}
//       >
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell
//                 colSpan={6}
//                 sx={{
//                   fontWeight: "bold",
//                   fontSize: "20px",
//                   textAlign: "left",
//                   padding: "16px",
//                 }}
//               >
//                 List of Users
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               {[
//                 "date",
//                 "Country",
//                 "Company Name",
//                 "Name of Employee",
//                 "Name of Role",
//                 "action",
//               ].map((header) => (
//                 <TableCell
//                   key={header}
//                   sx={{
//                     padding: "12px",
//                     textAlign: "center",
//                     borderRight: "1px solid #ddd",
//                     "&:first-of-type": {
//                       marginLeft: "20px",
//                       paddingLeft: "16px",
//                     },
//                     "&:not(:first-of-type)": {
//                       paddingLeft: "16px",
//                     },
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Typography
//                       sx={{ fontWeight: "bold", marginRight: "10px" }}
//                     >
//                       {header.charAt(0).toUpperCase() + header.slice(1)}
//                     </Typography>
//                     {header !== "action" && (
//                       <Box
//                         sx={{
//                           display: "flex",
//                           flexDirection: "column",
//                           alignItems: "center",
//                         }}
//                       >
//                         <IconButton
//                           size="small"
//                           onClick={() => handleSort(header, "asc")}
//                         >
//                           <ExpandLess
//                             sx={{
//                               color:
//                                 sortConfig.key === header &&
//                                 sortConfig.direction === "asc"
//                                   ? "blue"
//                                   : "inherit",
//                               margin: "-10px",
//                               fontSize: "20px",
//                               color: "black",
//                             }}
//                           />
//                         </IconButton>
//                         <IconButton
//                           size="small"
//                           onClick={() => handleSort(header, "desc")}
//                         >
//                           <ExpandMore
//                             sx={{
//                               color:
//                                 sortConfig.key === header &&
//                                 sortConfig.direction === "desc"
//                                   ? "blue"
//                                   : "inherit",
//                               margin: "-10px",
//                               fontSize: "20px",
//                               color: "black",
//                             }}
//                           />
//                         </IconButton>
//                       </Box>
//                     )}
//                   </Box>
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {users.map((user, index) => (
//               <TableRow key={user._id}>
//                 <TableCell sx={{ borderRight: "1px solid #ddd", width: "10%" }}>
//                   {new Date(user.createdAt).toLocaleDateString()}
//                 </TableCell>
//                 <TableCell sx={{ borderRight: "1px solid #ddd", width: "15%" }}>
//                   {user.country}
//                 </TableCell>
//                 <TableCell sx={{ borderRight: "1px solid #ddd", width: "20%" }}>
//                   {user.company ? user.company.companyName : "No Company"}
//                 </TableCell>
//                 <TableCell sx={{ borderRight: "1px solid #ddd", width: "30%" }}>
//                   {user.name}
//                 </TableCell>
//                 <TableCell sx={{ borderRight: "1px solid #ddd", width: "20%" }}>
//                   {user.role?.name || "N/A"}
//                 </TableCell>
//                 <TableCell>
//                   <IconButton
//                     onClick={(event) => handleMenuClick(event, index)}
//                   >
//                     <MoreHorizIcon
//                       sx={{
//                         fontSize: "large",
//                         color: "black",
//                         marginLeft: "10px",
//                       }}
//                     />
//                   </IconButton>
//                   <Menu
//                     anchorEl={anchorEl}
//                     open={open && selectedUserIndex === index}
//                     onClose={handleMenuClose}
//                     PaperProps={{
//                       sx: {
//                         width: "120px",
//                         border: "1px solid #ddd",
//                         marginLeft: "-45px",
//                       },
//                     }}
//                   >
//                     <MenuItem
//                       sx={{ fontSize: "14px" }}
//                       onClick={() => handleEdit(user._id)}
//                     >
//                       <EditIcon
//                         sx={{ marginRight: "10px", fontSize: "large" }}
//                       />
//                       Edit
//                     </MenuItem>
//                     <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleDelete(user._id)}>
//                       <DeleteIcon sx={{ marginRight: '10px', fontSize: 'large' }} />
//                       Delete
//                     </MenuItem>
//                     <MenuItem
//                       sx={{ fontSize: "14px" }}
//                       onClick={() => handleView(index)}
//                     >
//                       <AssignmentIcon
//                         sx={{ marginRight: "10px", fontSize: "large" }}
//                       />
//                       View Logs
//                     </MenuItem>
//                   </Menu>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       <Box sx={{ display: "flex", justifyContent: "center", padding: "16px" }}>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             mt: 3,
//             backgroundColor: "#ffffff",
//             width: "80%",
//             margin: "2% 5% 0% 23%",
//             borderRadius: "2%",
//             padding: "3px",
//           }}
//         >
//           <Pagination
//             count={totalPages}
//             page={page}
//             onChange={handleChangePage}
//             renderItem={(item) => (
//               <StyledPaginationItem
//                 {...item}
//                 components={{
//                   previous: () => (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         mr: 33,
//                       }}
//                     >
//                       <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
//                       Previous
//                     </Box>
//                   ),
//                   next: () => (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         ml: 33,
//                       }}
//                     >
//                       Next
//                       <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
//                     </Box>
//                   ),
//                 }}
//                 sx={{
//                   "& .MuiPaginationItem-root": {
//                     mx: 1,
//                   },
//                   "& .MuiPagination-ul": {
//                     display: "flex",
//                     alignItems: "center",
//                   },
//                 }}
//               />
//             )}
//           />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default User;

import React, { useState, useEffect,useContext} from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Stack,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  Select,
  MenuItem as MuiMenuItem,
  FormControl,
  InputLabel,
    Alert,
  Snackbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import AddUsers from "./AddUser";
import ViewUser from "./ViewUser";
import Role from "./Roles"; // Update the path as necessary
import CountryFilter from "./Filters/UserFilters/CountryFilter";
import { api } from "../../../utils/api";
import CompanyFilter from "./Filters/UserFilters/CompanyFilter";
import RoleFilter from "./Filters/UserFilters/UserRoleFilter";
import UserDateFilter from "./Filters/UserFilters/UserDateFilter";
import FilterSidebar from "../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "./Filters/ApplyButton";
import ResetButton from "./Filters/ResetButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FacilityFilter from "./Filters/UserFilters/FacilityFilter";
import FunctionalityOfRole from "./Filters/UserFilters/FunctionOfRole";
import { ProgressContext } from "../../../Components/Quetionary/ContextAPI";

const User = () => {
  const [showRoles, setShowRoles] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [indexUser, setindexUser] = useState(null);
  const [indexUserView, setindexUserView] = useState(null);
  const [isDrawerOpenEdit, setIsDrawerOpenEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [userList, setUserList] = useState([]); // State to store filtered users
  const [loading, setLoading] = useState(false);
  const [companyFilterOpen, setCompanyFilterOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [roleFilters, setRoleFilters] = useState([]);
  const [facilityFilter, setFacilityFilter] = useState([]);
  const [functionalityOfRoleFilter, setFunctionalityOfRoleFilter] = useState([]);
  const [DeleteIndex, setDeleteIndex] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [snackOpen,setsnackOpen]= useState({open:false,message:"",severity:""});
  const[errors, setErrors] = useState({
    Date: false,
    Role: false,
    Country: false,
    CompanyName: false,
    Facility: false,
    FunctionalityOfRole: false,
  });


  const [appliedFilters, setAppliedFilters] = useState({
    Date: false,
    Country: false,
    Role: false,
    "Company Name": false,
    Facility: false,
    FunctionalityOfRole: false,

  });
  // const filters = ["Country", "Company Name", "Role", "Date", "Facility" ,"FunctionalityOfRole"];
  const filters = ["Country", "Company Name", "Role", "Date", "Facility" ];

  const [selectedFilter, setSelectedFilter] = useState("Country"); // Default filter is "Date"
  const {ViewInner,setViewInner} = useContext(ProgressContext);
  
  const fetchUsers = async (filters = filtersToApply) => {
    setLoading(true); // Start loading

    try {
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        // sortField: "name", // Sort configuration
        sortField: sortConfig.key,
        sortOrder: sortConfig.direction,
      }).toString();

      console.log("Query Params sent to API:", queryParams); // Debugging line

      const response = await fetch(`${api}/auth/getAllUsers?${queryParams}`);
      const data = await response.json();

      console.log("Fetched data after filtering:", data); // Debugging line

      if (response.ok) {
        // setUsers(usersData.filter((item)=>item.deactivated === false));
        setUsers(data.users); // Update the user list
        setTotalPages(data.totalPages); // Update total pages for pagination
      } else {
        console.error("Error fetching users:", data.error);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    console.log("Fetching users without filters...");
    fetchUsers(); // Fetch all users on mount
  }, [page, rowsPerPage, sortConfig]);

  const handleFilterChange = (selectedCountries) => {
    setCountryFilter(selectedCountries);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page on rows per page change
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

const handleView=(index)=>{
  setindexUserView(index)
 console.log("hii",index)
  setAnchorEl(null);
  setSelectedUserIndex(null);
   setIsDrawerOpenEdit(true)
   setViewInner(true)
  // openDrawerEdit();
}
const handleEdit=(index)=>{
 setindexUser(index)
  console.log("hii",index)
   setAnchorEl(null);
   setSelectedUserIndex(null);
   setIsDrawerOpen(true);
   
  //  setIsDrawerOpenEdit(false)
   // openDrawerEdit();
 }
 const handleDelete=(index)=>{
  // setindexUserView(index)
  setDeleteIndex(index)
  console.log(index,"index++")
 
  setDeleteDialog(true);
  setAnchorEl(null);
  setSelectedUserIndex(null);

    setDeleteDialog(true);
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

 const handleDeleteAggree = () => {
  console.log("indexhandleDeleteAggree",DeleteIndex)
  axios.put(`${api}/auth/DeleteUser`,{deactivated:true},{
    params: {
      Id: DeleteIndex // Pass as query parameter
    }
}).then((res)=>{
  console.log(res,"res++")
  setDeleteDialog(false);
  setsnackOpen({open:true,message:"user deleted successfully",severity:"success"})
  setAnchorEl(null);
  setUsers(users.filter((item)=>item._id !== res.data.data._id));
  setSelectedUserIndex(null);
}).catch((err)=>{
  console.log(err)
})
 
};
  

  const handleApplyFilters = () => {
    if(!validateFilters())
      return;
    console.log("Selected functionalities:", functionalityOfRoleFilter);
    console.log("Selected countries:", selectedCountries);
    console.log("Selected companies:", selectedCompanies); // Log selected companies

    // setPage(1); // Reset to first page on filter application

    fetchUsers({
      country: selectedCountries.join(","),
      companyName: selectedCompanies.join(","),
      role: roleFilters.join(","),
      facility: facilityFilter.join(","),
      // FunctionalityOfRole: functionalityOfRoleFilter.join(","),
      functionalityOfRole: functionalityOfRoleFilter.join(","),
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    });

    setDrawerFilterOpen(false);
    handleAppliedFilters();
  };

 console.log("setViewInnerUser",ViewInner)
  const filtersToApply = {
    country: selectedCountries.join(","),
    companyName: selectedCompanies.join(","),
    role: roleFilters.join(","),
    facility: facilityFilter.join(","),
    FunctionalityOfRole: functionalityOfRoleFilter.join(","),
    startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
    endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
  };
  // Applying reset filters
  const handleResetFilters = () => {
    if (selectedFilter === "Date") {
      setStartDate(null);
      setEndDate(null);
      setAppliedFilters({ ...appliedFilters, Date: false });
    }
    if (selectedFilter === "Country") {
      setSelectedCountries([]);
      setAppliedFilters({ ...appliedFilters, Country: false });
    }
    if (selectedFilter === "Role") {
      setRoleFilters([]);
      setAppliedFilters({ ...appliedFilters, Role: false });
    }

    if (selectedFilter === "Facility") {
      setFacilityFilter([]);
      setAppliedFilters({ ...appliedFilters, Facility: false });
    }

    if (selectedFilter === "FunctionalityOfRole") {
      setFunctionalityOfRoleFilter([]);
      setAppliedFilters({ ...appliedFilters, FunctionalityOfRole: false });
    }

    if (selectedFilter === "Company Name") {
      setSelectedCompanies([]);
      setAppliedFilters({ ...appliedFilters, "Company Name": false });
    }

    fetchUsers({
      country: selectedFilter === "Country" ? [] : selectedCountries.join(","),
      companyName:
        selectedFilter === "Company Name" ? [] : selectedCompanies.join(","),
      role: selectedFilter === "Role" ? [] : roleFilters.join(","),
      facility: selectedFilter === "Facility" ? [] : facilityFilter.join(","),  
      FunctionalityOfRole: selectedFilter === "FunctionalityOfRole" ? [] : functionalityOfRoleFilter.join(","),  
      startDate:
        selectedFilter === "Date"
          ? null
          : startDate
          ? startDate.format("YYYY-MM-DD")
          : "",
      endDate:
        selectedFilter === "Date"
          ? null
          : endDate
          ? endDate.format("YYYY-MM-DD")
          : "",
    });
  };

  // handle the applied filters styling
  const handleAppliedFilters = () => {
    setAppliedFilters({
      ...appliedFilters,
      Date: startDate || endDate ? true : false,
      Country: selectedCountries.length > 0 ? true : false,
      Role: roleFilters.length > 0 ? true : false,
      Facility: facilityFilter.length > 0 ? true : false,
      FunctionalityOfRole: functionalityOfRoleFilter.length > 0 ? true: false,
      "Company Name": selectedCompanies.length > 0 ? true : false,
    });
  };

  const handleRoleFilterChange = (selectedRoles) => {
    setRoleFilters(selectedRoles);
  };

  const handleFacilityFilterChange = (selectedFacility) => {
    setFacilityFilter(selectedFacility);
  };

  // const handleFunctionalityOfRoleFilterChange = (selectedFunctionalityOfRole) => {
  //   setFunctionalityOfRoleFilter(selectedFunctionalityOfRole);
  // };

  const handleFunctionalityOfRoleFilterChange = (selectedFunctionalityOfRole) => {
    setFunctionalityOfRoleFilter(selectedFunctionalityOfRole);
    // Trigger user fetching every time the filter changes
    fetchUsers({
      ...filtersToApply,
      functionalityOfRole: selectedFunctionalityOfRole.join(","),
    });
  };


  const open = Boolean(anchorEl);

  const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "green",
      color: "#fff",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: "lightgray",
    },
  }));

  const validateFilters = () => {
    let isValid = true;
    const newErrors = { Date: "", Role: ""};
    if (selectedFilter === "Date") {
      if (startDate >= endDate) {
        console.log(startDate, endDate, Date.now());
        newErrors.Date = "End date must be greater than Start date";
        isValid = false;
      }
      if (!startDate && !endDate) {
        newErrors.Date = "Please select date";
        isValid = false;
      }
    }

    if (selectedFilter === "Role" && roleFilters.length === 0) {
      newErrors.Role = "Please select Role";
      isValid = false;
    }

    if (selectedFilter === "Facility" && facilityFilter.length === 0) {
      newErrors.Facility = "Please select Facility";
      isValid = false;
    }

    if (selectedFilter === "FunctionalityOfRole" && functionalityOfRoleFilter.length === 0) {
      newErrors.FunctionalityOfRole = "Please select Functionality Of Role";
      isValid = false;
    }

    if (selectedFilter === "Country" && selectedCountries.length===0) {
      newErrors.Country = "Please select Country";
    isValid = false;
  }
    
  
  if (selectedFilter === "Company Name" && selectedCompanies.length===0) {
    newErrors.CompanyName = "Please select CompanyName";
  isValid = false;
}

    console.log(newErrors);

    setErrors(newErrors);
    return isValid;
  };


  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Country":
        return (
          <CountryFilter
            url={`${api}/auth/getAllUsers`}
            onFilterChange={handleFilterChange}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            errors={errors.Country}

          />
        );
      case "Company Name":
        return (
          <CompanyFilter
            open={companyFilterOpen}
            onClose={toggleCompanyFilterDrawer(false)}
            onFilterChange={handleCompanyFilterChange} // Update selectedCompanies
            handleApplyFilters={handleApplyFilters} // Apply the filters on click
            selectedCompanies={selectedCompanies}
            errors={errors.CompanyName}
          />
        );
      case "Role":
        return <RoleFilter 
        onRoleFilterChange={handleRoleFilterChange}  selectedRoles={roleFilters} errors={errors.Role} />;

      case "Facility":
          return <FacilityFilter 
          onRoleFilterChange={handleFacilityFilterChange}  selectedFacility={facilityFilter} errors={errors.Facility} />;

      case "FunctionalityOfRole":
        return <FunctionalityOfRole 
        onRoleFilterChange={handleFunctionalityOfRoleFilterChange}  selectedFunctionalityOfRole={functionalityOfRoleFilter} errors={errors.FunctionalityOfRole} />;

      case "Date":
        return (
          <UserDateFilter
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            errors={errors.Date}
          />
        );

      default:
        return (
          <CountryFilter
            url={`${api}/auth/getAllUsers`}
            onFilterChange={handleFilterChange}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            errors={errors.Country}
          />
        );
    }
  };

  const handleAddUser = (i) => {
    setIsDrawerOpen(true);
    setindexUser(i);
  };

  const handleCompanyFilterChange = (companyName, checked) => {
    setSelectedCompanies(
      (prevSelectedCompanies) =>
        checked
          ? [...prevSelectedCompanies, companyName] // Add company if checked
          : prevSelectedCompanies.filter((company) => company !== companyName) // Remove company if unchecked
    );
  };


  
  

  const toggleCompanyFilterDrawer = (open) => () => {
    setCompanyFilterOpen(open);
  };

  if (showRoles) {
    return <Role />;
  }

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      //  opacity: deleteDialog ?0.9:1
      zIndex: 1000, 
      }}
    >
       <Box sx={{ width: 600 }}>
     
        <Snackbar open={snackOpen.open} autoHideDuration={3000}
         anchorOrigin={{ vertical:"top", horizontal:"center" }}
          onClose={()=>setsnackOpen({...snackOpen,open:false})}

         >
        <Alert
          // onClose={handleClose}
          severity={snackOpen.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackOpen.message}
          {/* Add user Successfully */}
        </Alert>
      </Snackbar>
    </Box>
      {/* Render the CountryFilter */}
      {/* <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}> */}
      {/* <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}> */}
      {drawerFilterOpen && (
        <Drawer
          anchor="right"
          open={drawerFilterOpen}
          onClose={() => setDrawerFilterOpen(false)}
          sx={{
            backgroundColor: "transparent",
            "& .MuiDrawer-paper": {
              // This targets the paper component within the Drawer
              backgroundColor: "transparent", // Make paper transparent
              boxShadow: "none", // Optional: Remove any shadow
              width: "50%",
            },
          }}
        >
          <Box
            display="flex"
            gap={3}
            sx={{
              width: "100%",
              display: "flex",
              overflowX: "hidden",
              maxWidth: "100%",
              justifyContent: "space-btween",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                height: "3%",
                padding: "13px",
                margin: "30px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "80%",
                width: "4%",
              }}
            >
              <IconButton
                onClick={() => setDrawerFilterOpen(false)}
                sx={{ color: "black" }}
              >
                <CloseIcon sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
              {/* Sidebar for selecting the filter */}
              <FilterSidebar
                {...{
                  setSelectedFilter,
                  selectedFilter,
                  filters,
                  appliedFilters,
                }}
              />

              {/* Filter content area */}
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundColor: "#F9F9F9",
                  padding: "20px",
                }}
              >
                {renderSelectedFilter()}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "3%",
                    position: "fixed",
                    bottom: "20px",
                    ml: 3,
                    mr: 3,
                  }}
                >
                  <ApplyButton handleApply={handleApplyFilters} />
                  <ResetButton handleReset={handleResetFilters} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}{" "}
      <AddUsers
        value={isDrawerOpen}
        setValue={setIsDrawerOpen}
        index={indexUser}
        setindexUser={setindexUser}
        fetchUsers={fetchUsers}
        setsnackOpen={setsnackOpen}
      />
      <ViewUser
        values={isDrawerOpenEdit}
        // values ={ViewInner}
        setValues={setIsDrawerOpenEdit}
        indexView={indexUserView}
      />
      {deleteDialog && (
        <Dialog 
          open={deleteDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => setDeleteDialog(false)}
            >
              Cancle
            </Button>
            <Button sx={{ textTransform: "none" }} onClick={handleDeleteAggree}>
            Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 0.5% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "18px", textDecoration: "underline" }}
            >
              Roles and Permission
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => setShowRoles(false)} // Show Users
                sx={{
                  fontSize: "14px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  backgroundColor: "#1c486b",
                }}
              >
                List of Users
              </Button>
              <Button
                variant="contained"
                onClick={() => setShowRoles(true)} // Show Roles
                sx={{
                  backgroundColor: "#e0e0e0",
                  fontSize: "14px",
                  color: "black",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                }}
              >
                List of Roles
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              // endIcon={<FilterListIcon />}
              sx={{
                margin: "38px 10px 0px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                "&:hover .dot-icon": {
                  color: "#FFFFFF", // Change dot color on button hover
                },
                // display:"flex"
              }}
            >
              Filter
              <FilterListIcon />
              {(selectedCountries.length > 0 ||
                selectedCompanies.length > 0 ||
                roleFilters.length > 0 ||
                startDate ||
                endDate) && (
                <FiberManualRecordIcon
                  className="dot-icon"
                  sx={{
                    fontSize: "10px",
                    color: "#488257",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Button>
            <Button
              variant="contained"
              onClick={()=>handleAddUser(0)}
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -29px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              Add New User
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "77.5%", margin: "0% 5% 0% 23%" }}
      >
        {/* <Table sx={{ tableLayout: "fixed", width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  textAlign: "left",
                  padding: "16px",
                }}
              >
                List of Users
              </TableCell>
            </TableRow>
            <TableRow>
              {[
                "date",
                "Country",
                "Company Name",
                "Name of Employee",
                "Name of Role",
                "action",
              ].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    padding: "12px",
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                    "&:first-of-type": {
                      marginLeft: "20px",
                      paddingLeft: "16px",
                    },
                    "&:not(:first-of-type)": {
                      paddingLeft: "16px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", marginRight: "10px" }}
                    >
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </Typography>
                    {header !== "action" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleSort(header, "asc")}
                        >
                          <ExpandLess
                            sx={{
                              color:
                                sortConfig.key === header &&
                                sortConfig.direction === "asc"
                                  ? "blue"
                                  : "inherit",
                              margin: "-10px",
                              fontSize: "20px",
                              color: "black",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleSort(header, "desc")}
                        >
                          <ExpandMore
                            sx={{
                              color:
                                sortConfig.key === header &&
                                sortConfig.direction === "desc"
                                  ? "blue"
                                  : "inherit",
                              margin: "-10px",
                              fontSize: "20px",
                              color: "black",
                            }}
                          />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user._id} sx={{ height: "40px" }} >
                <TableCell 
               
                sx={{ 
                  borderRight: "1px solid #ddd", 
                  width: "10%",
                  // width: "100px",  
                  padding: "2px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  }}>
                  {new Date(user.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell 
                sx={{ 
                  borderRight: "1px solid #ddd", 
                  width: "15%",
                  // width: "150px",   
                  padding: "2px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                   }}>
                  {user.country}
                </TableCell>
                <TableCell 
                sx={{ 
                  borderRight: "1px solid #ddd", 
                  width: "20%", 
                  // width: "200px",  
                  padding: "2px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                   }}>
                  {user.company ? user.company.companyName : "No Company"}
                </TableCell>
                <TableCell 
                sx={{ 
                  borderRight: "1px solid #ddd", 
                  width: "30%", 
                  // width: "50px",  
                  padding: "2px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                   }}>
                  {user.name}
                </TableCell>
                <TableCell 
                sx={{ 
                  borderRight: "1px solid #ddd", 
                  width: "20%",
                  // width: "550px",   
                  padding: "2px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  }}>
                  {user.role?.name || "N/A"}
                </TableCell>
                <TableCell sx={{padding: "2px 8px",  width: "5%"}}>
                  <IconButton
                    onClick={(event) => handleMenuClick(event, index)}
                  >
                    <MoreHorizIcon
                      sx={{
                        fontSize: "large",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open && selectedUserIndex === index}
                    onClose={handleMenuClose}
                    PaperProps={{
                      sx: {
                        width: "120px",
                        border: "1px solid #ddd",
                        marginLeft: "-45px",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      onClick={() => handleEdit(user._id)}
                    >
                      <EditIcon
                        sx={{ marginRight: "10px", fontSize: "large" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleDelete(user._id)}>
                      <DeleteIcon sx={{ marginRight: '10px', fontSize: 'large' }} />
                      Delete
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      onClick={() => handleView(user)}
                    >
                      <AssignmentIcon
                        sx={{ marginRight: "10px", fontSize: "large" }}
                      />
                      View Logs
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      onClick={() => handleEdit(user._id)}
                    >
                      <EditIcon
                        sx={{ marginRight: "10px", fontSize: "large" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      onClick={() => handleDelete(user._id)}
                    >
                      <DeleteIcon
                        sx={{ marginRight: "10px", fontSize: "large" }}
                      />
                      Delete
                    </MenuItem>
                    
                  </Menu>
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table> */}
     <Box sx={{ width: '100%'}}>
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "left",
          padding: "16px",
        }}
      >
      List of Users
    </Box>

    {/* Header Row */}
    <Box sx={{ display: 'grid', gridTemplateColumns: '12% 15% 20% 25% 18% 10%', border: '1px solid #ddd' }}>
      {/* <Box sx={{ padding: '12px', textAlign: 'center', borderRight: '1px solid #ddd' }}> */}
      <Box sx={{ display: "flex",  flexDirection: "row",padding: '12px', textAlign: 'left', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
        <Typography sx={{ fontWeight: 'bold' }}>Date</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2px",
            marginLeft: "10px"
          }}
        >
        <IconButton
          size="small"
          // onClick={() => handleSort( "asc")}
          onClick={() => handleSort("createdAt", "asc")}
        >
          <ExpandLess
            sx={{
              color: sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "blue" : "black",
              margin: "-10px",
              fontSize: "20px",
              color: "black",
            }}
          />
        </IconButton>
      <IconButton
        size="small"
        // onClick={() => handleSort("desc")}
        onClick={() => handleSort("createdAt", "desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Box>
    <Box sx={{ display: "flex",  flexDirection: "row",padding: '12px', textAlign: 'left', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Country</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("country", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "country" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("country","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "country" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Box>
    <Box sx={{ display: "flex",  flexDirection: "row",padding: '12px', textAlign: 'left', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Company Name</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("company", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "company" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("company","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "company" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Box>
    <Box sx={{display: "flex",  flexDirection: "row", padding: '12px', textAlign: 'left', borderRight: '1px solid #ddd' , borderBottom: '1px solid #ddd'}}>
      <Typography sx={{ fontWeight: 'bold' }}>Name of Employee</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort("name", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "name" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("name", "desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "name" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Box>
    <Box sx={{ display: "flex",  flexDirection: "row",padding: '12px', textAlign: 'left', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Name of Role</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "10px"
        }}
      >
      <IconButton
        size="small"
        onClick={() => handleSort( "role", "asc")}
      >
        <ExpandLess
          sx={{
            color: sortConfig.key === "role" && sortConfig.direction === "asc" ? "blue" : "black",
            margin: "-10px",
            fontSize: "20px",
            color: "black",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => handleSort("role","desc")}
      >
      <ExpandMore
        sx={{
          color: sortConfig.key === "role" && sortConfig.direction === "desc" ? "blue" : "black",
          margin: "-10px",
          fontSize: "20px",
          color: "black",
        }}
        />
      </IconButton>
      </Box>
    </Box>
    {/* <Box sx={{ padding: '12px', textAlign: 'left' }}> */}
    <Box sx={{ padding: '12px', textAlign: 'center', borderBottom: '1px solid #ddd' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Action</Typography>
    </Box>
  </Box>

  {/* Data Rows */}
  <Box>
    {users.map((user, index) => (
      <Box key={user._id} sx={{ display: 'grid', gridTemplateColumns: '12% 15% 20% 25% 18% 10%', borderBottom: '1px solid #ddd', height: '40px', alignItems: 'center' }}>
        <Box sx={{ padding: '9px',fontSize: "14px", color: "black", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderRight: '1px solid #ddd' }}>
          {/* {new Date(user.createdAt).toLocaleDateString()} */}
          {new Date(user.createdAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })}
        </Box>
        <Box sx={{ padding: '9px',fontSize: "14px", color: "black", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderRight: '1px solid #ddd',  }}>
          {user.country}
        </Box>
        <Box sx={{ padding: '9px',fontSize: "14px", color: "black", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderRight: '1px solid #ddd' }}>
          {user.company ? user.company.companyName : 'No Company'}
        </Box>
        <Box sx={{ padding: '9px',fontSize: "14px", color: "black", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderRight: '1px solid #ddd' }}>
          {user.name}
        </Box>
        <Box sx={{ padding: '9px',fontSize: "14px", color: "black", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', borderRight: '1px solid #ddd' }}>
          {user.role?.name || 'N/A'}
        </Box>
        <Box sx={{ textAlign: 'center' }}>
           <IconButton onClick={(event) => handleMenuClick(event, index)}>
          <MoreHorizIcon sx={{ fontWeight: 'bold', color: 'black' }} />
        </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open && selectedUserIndex === index}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                width: '120px',
                border: '1px solid #ddd',
                marginLeft: '-45px',
              },
            }}
          >
            <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleView(user)}>
              <AssignmentIcon sx={{ marginRight: '10px', fontSize: 'large' }} />
              View Logs
            </MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleEdit(user._id)}>
              <EditIcon sx={{ marginRight: '10px', fontSize: 'large' }} />
              Edit
            </MenuItem>
            <MenuItem sx={{ fontSize: '14px' }} onClick={() => handleDelete(user._id)}>
              <DeleteIcon sx={{ marginRight: '10px', fontSize: 'large' }} />
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    ))}
  </Box>
</Box>        
    </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% -0.5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <StyledPaginationItem
              // <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 27,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 27,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default User;
