import  React ,{useState,useEffect,useCallback,useContext} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton,InputAdornment,Link}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import contries from './Country.json';
import {companyId} from '../../../utils/companyId';
import axios from 'axios';
import {api} from '../../../utils/api';
import { ProgressContext } from "../../../Components/Quetionary/ContextAPI";
function Add(props) {
  //     const [state, setState] = useState({
  //   // top: false,
  //   // left: false,
  //   // bottom: false,
  //   right: false,
  // });

  // console.log("companyId",companyId,)
  const roleID = localStorage.getItem("roleId");
  const [facility,setFacility] = useState(["Tap to Select"]);
  const [roles,setRole] = useState(["Tap to Select"]);
  const [FunctionalityRole,setFunctinalityRole] = useState([]);
  const [alreadyUser,setAlreadyUser] = useState(false);
  const [selectedEmail , setEmail] = useState("");
  const [errors,setErrors]= useState({});
  const [verifybtn,setverifybtn] = useState({email:false,phone:false});
  const [verify,setVerify] = useState({
    phone:false,
    mail:false,
    emailMsg:"",
    phoneMsg:""
  });
  const [TaskAltIcons,setTaskAltIcons]=useState({
    email:false,
    phone:false
  });
 // Time state
const [timerActive, setTimerActive] = useState(false);
  const [otp,setOTP]=useState({
    phone:null,
    email:null
  });
  const [fromValue,setFromValue]= useState({
    company:companyId,
    country:"Select Country",
    facility:facility,
    createdBy: roleID,
    companyName:"",
    name:"",
    email:"",
    phone:"",
    role:["Tap to Select"],
    countryOperation:"",
    countryCode:"",
    functionlityOfRole:FunctionalityRole,
   remark:""
  })

  const [time, setTime] = useState(180); // Initialize time to 2 minutes (120 seconds)
  const [timePhone, setTimePhone] = useState(120);
  const { setViewInner,ViewInner } = useContext(ProgressContext);

  useEffect(() => {
    // Start the timer only if selectedEmail is not empty and timer is not already active
    if (verify.email===true && selectedEmail && time === 0) {
      setTime(120); // Start with 120 seconds
      setTimerActive(true); // Activate the timer
    }
    if (verify.phone===true && fromValue.phone !=="" && timePhone === 0) {
      setTimePhone(120); // Start with 120 seconds
      setTimerActive(true); // Activate the timer
    }
  }, [selectedEmail,fromValue.phone]);

  
  // Format time in minutes and seconds
  const formatTime = (time) => {
        const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer); // Clear the interval when the countdown reaches 0
          return 0; // Ensure the timer doesn't go below 0
        }
        return prevTime - 1; // Decrease time by 1 second
      });
    }, 1000); // Update every 1 second

    // Cleanup the interval when the component unmounts
    // return () => clearInterval(timer);
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  const formatTimePhone = (timePhone) => {
    const timer = setInterval(() => {
      setTimePhone((prevTime) => {
    if (prevTime <= 0) {
      clearInterval(timer); // Clear the interval when the countdown reaches 0
      return 0; // Ensure the timer doesn't go below 0
    }
    return prevTime - 1; // Decrease time by 1 second
  });
}, 1000); 
const minutes = Math.floor(timePhone / 60);
const seconds = timePhone % 60;
return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};


  const handleEmailChange = (e)=>{
    const {name,value}= e.target
    setEmail(value)
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    console.log(emailPattern.test(trimmedValue),trimmedValue);
    if (emailPattern.test(trimmedValue)) {
      setFromValue({
        ...fromValue,
        [name]: trimmedValue,
      });
      setverifybtn({...verifybtn,email:true});
      // setErrorMessage(""); // Clear the error message if the email is valid
    } else {
      // setErrorMessage("Invalid email format");
      console.log("Invalid email format")
      setverifybtn({...verifybtn,email:false});
    }
    
  }
  const handleChange =(e)=>{
    const {name,value} = e.target
    setFromValue({
      ...fromValue,
      [name]: value
    })
  }
  
  useEffect(()=>{
    // console.log("hiiii")
    if(companyId){
      axios.get(`${api}/company/companies/${companyId}`).then(response => {
        console.log(response.data.companyName)
        setFromValue({...fromValue,
           companyName:response.data.companyName,
       countryOperation:response.data.headquarters,
        })
      
      }
    
    )
      .catch(error => {
        console.error('Error details:', error);
        console.log('URL being requested:', );  // Log the URL to check if it's undefined
      });

     
    }else{

    }
    const fetchRole = async()=>{
      await  axios.get(`${api}/auth/FetchUserRoles`).then((res)=>{
         console.log(res.data.data,"datafacility")
        setFunctinalityRole(res.data.data)
        setRole(res.data.data)
      }).catch((err)=>{
        console.log(err)
      })

    }
    fetchRole();
     const Facility = async()=>{
     axios.get(`${api}/master/getAllFacility`).then((res)=>{
      console.log(res.data.data,"resfacility")
      setFacility(res.data.data)
     }).catch((err)=>{console.log(err,"err")})
     }
     Facility()
  },[props.index])


  useEffect(() => {
    console.log("propIndexValue", props.index);
  
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`${api}/auth/getUserById`, {
          params: {
            Id: props.index, // Pass as query parameter
          },
        });
  
        console.log("data after fetch roleId", res.data.data, res.data.data.role.name);
  
        // Update the form state with fetched data
        setFromValue({
          company: companyId,
          country: res.data.data.country ?? "Select Country",
          facility: res.data.data.facility.map((item) => item.facilityName).join("") ?? facility,
          createdBy: roleID,
          email: res.data.data.email ?? "",
          name: res.data.data.name ?? "",
          phone: res.data.data.phone ?? "",
          role: res.data.data.role._id ?? "",
          countryCode: res.data.data.countryCode ?? "",
          functionlityOfRole: FunctionalityRole ?? "",
          remark: res.data.data.remark ?? "",
        });
        //  setViewInner(false)
        setEmail(res.data.data.email);
        setAlreadyUser(true);
      } catch (err) {
        console.log(err);
        // setViewInner(true)
        setAlreadyUser(false);
      }
    };
  
    if (props.index !== 0) {
      // Fetch user data when props.index is not 0
      fetchUserData();
    } else {
      // Reset form values when index is 0
      setFromValue({
        company: companyId,
        country: "Select Country",
        facility: "",
        createdBy: roleID,
        email: "",
        name: "",
        phone: "",
        role: ["Tap to Select"],
        countryCode: "",
        functionlityOfRole: FunctionalityRole,
        remark: "",
      });
  
      setEmail("");
      setAlreadyUser(false);
    }
  }, [props.index, companyId, roleID, FunctionalityRole, roles]); // Add necessary dependencies
  


  const fetchCompanyData = useCallback(async () => {
    if (companyId) {
      try {
        const response = await axios.get(`${api}/company/companies/${companyId}`);
        setFromValue((prevValue) => ({
          ...prevValue,
          companyName: response.data.companyName,
          countryOperation: response.data.headquarters,
        }));
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    }
  }, [companyId]);

  const fetchRoles = useCallback(async () => {
    try {
      const res = await axios.get(`${api}/auth/getRoles`);
      setFunctinalityRole(res.data.data);
      setRole(res.data.data);
    } catch (err) {
      console.error('Error fetching roles:', err);
    }
  }, []);

  const fetchFacilities = useCallback(async () => {
    try {
      const res = await axios.get(`${api}/master/getAllFacility`);
      setFacility(res.data.data);
    } catch (err) {
      console.error('Error fetching facilities:', err);
    }
  }, []);

 


  // Ensure useEffect only runs when necessary
  
  

  // Use useEffect for side effects, call functions conditionally within the effect
  useEffect(() => {
    console.log("propIndexValue", props.index);

    if (props.index === 0) {
      // Skip data fetching and avoid re-triggering unnecessary functions
      setFromValue({
        company: companyId,
        country: "Select Country",
        facility: "",
        createdBy: roleID,
        email: "",
        name: "",
        phone: "",
        role: roles,
        countryCode: "",
        functionlityOfRole: FunctionalityRole,
        remark: "",
      });
      setEmail("");
      setAlreadyUser(false);
    } else {
      // Fetch necessary data when the index is not 0
      fetchCompanyData();
      fetchRoles();
      fetchFacilities();
      // fetchUserData();
    }
  }, [props.index, companyId, fetchCompanyData, fetchRoles, fetchFacilities]);

  // Rest of the component logic...

  console.log("fromValue++",fromValue,facility)
  const Submit =()=>{
    console.log("fromValue",fromValue)
    if (!validateForm()) {
      return;
    }
    // axios({method:alreadyUser?'put':'post',
    //   url:`${api}/governance/Board-composition${alreadyUser ? `/${form.companyId}` : ""}`,
    //   data:`${alreadyUser?"":fromValue}`,
    //   ${alreadyUser? params:
    //     Id: props.index :""}
    //   })
  // axios.post(`${api}/auth/createUser`,fromValue)
  axios({
    method: alreadyUser ? 'put' : 'post',
    url: alreadyUser?`${api}/auth/updateUser`:`${api}/auth/createUser`,
    data:  fromValue,  // Send data only if it's a POST request
    params: alreadyUser ? { Id: props.index } : null  // Send params only for PUT request
  })
  .then((res)=>{console.log(res,res.data.message)
    props.setValue(false)
    props.fetchUsers()
    props.setsnackOpen({open:true,message:res.data.message,severity:"success"})
    //  props.setindexUser(null)
  }).catch((err)=>{console.log(err)
    err?.response?.data?.message?.
     props.setsnackOpen({open:true,message:err?.response?.data?.message,severity:"error"})
  })
  }

const validateForm = () => {
  const newErrors = {};
  
  const { 
    company,
    country,
    facility,
    companyName,
    name,
    email,
    phone,
    countryOperation,
    role,
    functionlityOfRole,
    countryCode,
  } = fromValue;

  // Validate `company`
  if (!company || company.trim() === "") {
    newErrors.companyName = "Company is required.";
    newErrors.countryOperation = "countryOperation is required.";
  }

  // if (!company || company.trim() === "") {
  //   newErrors.countryOperation = "countryOperation is required.";
  // }
  // Validate `country`
  if (!country || country === "Select Country") {
    newErrors.country = "Please select a country.";
  }

  // Validate `facility`
 
  if (!facility || facility.length === 0 || facility[0] === "Tap to Select") {
    newErrors.facility = "Facility is required.";
  }

  // Validate `name`
  if (!name || name.trim() === "") {
    newErrors.name = "Name is required.";
  }

  // Validate `email`
  if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    newErrors.email = "A valid email is required.";
  }

  // Validate `phone` and `countryCode`
if (phone) {
  // If phone number is present but countryCode is missing
  if (!countryCode || countryCode.trim() === "") {
    newErrors.phone = "Country code is required,  select the country.";
  } else if (!/^\d{10,15}$/.test(phone)) {
    newErrors.phone = "A valid phone number is required."; // Validates phone number format
  }
} else {
  // If phone is missing but countryCode is provided
  if (countryCode && countryCode.trim() !== "") {
    newErrors.phone = "Phone number is required .";
  } else {
    newErrors.phone = "A valid phone number is required."; // Generic phone validation
  }
}

console.log(role,roles,"roleForTheData")
  // Validate `role`
  if (!role || role.length === 0 || role[0] === "Tap to Select") {
    newErrors.role = "Role is required.";
  }
  console.log(functionlityOfRole,"inside ValidatedFilled")
  // Validate `functionlityOfRole`
  // if (!functionlityOfRole || functionlityOfRole.length === 0) {
  //   newErrors.functionlityOfRole = "Functionality of role is required.";
  // }

  // Validate `countryCode`
  // if (!countryCode || countryCode.trim() === "") {
  //   newErrors.countryCode = "Country code is required.";
  // }

  // Set the errors and return the validation result
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

  const handleDropdownChange =(e)=>{
   const {name,value}=e.target
   console.log("nnn",value)
   setFromValue({
    ...fromValue,
    [name]:value
   })
  }
  const handleFunChange =(e)=>{
    
    const{name,value}=e.target
    console.log("vhh",e,name,value)
    setFromValue({
      ...fromValue,
      [name]:value
     })
  }
    const handleCountryChange = (event) => {
      console.log("event.target.value",event.target.value)
      const selectedCountryCode = event.target.value;
      const selectedCountry = contries.countries.find(country => country.name === selectedCountryCode); // Find the selected country object
  
  if (selectedCountry) {
    setFromValue({
      ...fromValue,
      country: selectedCountry.name, // Set country name
      countryCode: selectedCountry.dial_code // Set country code
    });
  }
      // setFromValue({
      //   ...fromValue,
      //   country : event.target.value.name,
      //   //  phone:event.target.value.dial_code
      //   countryCode:event.target.value.dial_code
      // });
    };
    const handlePhoneChange =(e)=>{
      const {name,value}=e.target
      // const numericValue = parseInt(value, /^[0-9]{10}$/) || 0;
      // const phoneNumberPattern = /^[0-9]{10}$/; 
      const phoneNumberPattern = /^[0-9]*$/;
      console.log("phoneNumberPattern.test(value)",
        phoneNumberPattern.test(value)
      )
      if (phoneNumberPattern.test(value) && value.length <= 10) {
       setFromValue({
        ...fromValue,
        [name]:value
       })
       setverifybtn({...verifybtn,phone:true});
      }
      else{
        setverifybtn({...verifybtn,phone:true});
      }
    }
console.log("otp.email",otp.email)
    const handleVerifyEmail = (time)=>{
      console.log(time,"timeforOTP")
      if(time>0){
        // setVerify({...verify,email:true})
        axios.post(`${api}/auth/sendOtpToEmail`,{email:fromValue.email}).then((res)=>{
          console.log("resForSendOtpEmail",res)
          // setOTP({...otp,})
          if(res.status===200){
            setVerify({...verify,email:true})
          }
         
        }).catch((err)=>{console.log(err)})
      }else{
        setVerify({...verify,email:false})
        setverifybtn({...verifybtn,email:false});
      }
    
      }
      const handleResend = () => {
        setTime(180); // Reset time to 180 seconds
        handleVerifyEmail(180); // Call the email verification function
      };
      const handleResendPhone = () => {
        setTimePhone(180); // Reset time to 180 seconds
        handleVerifyPhone(180); // Call the email verification function
      };
    const handleOTPVerify = (e) => {
      const { value } = e.target;
    
      // Only allow numeric values and ensure length does not exceed 4
      const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion
    
      // Update state only if the value is numeric and has a length <= 4
      if (isNumeric && value.length <= 4) {
        setOTP((prevOtp) => ({
          ...prevOtp,
          email: value, // Update the OTP value
        }));
        // Trigger API call when exactly 4 digits are entered
        if (value.length === 4) {
          axios
            .post(`${api}/auth/verifyOtpForEmail`, { email: fromValue.email, otp: value })
            .then((res) => {
              console.log("OTP verified successfully:", res,res.status);
              if(res.status===200){
              setVerify({...verify,email:false})
              setverifybtn({...verifybtn,email:false});
              setTaskAltIcons({...TaskAltIcons,email:true})
              }
            })
            .catch((err) => {
              console.error("Error verifying OTP:", err);
              setVerify({...verify,email:true,emailMsg:"Invalid OTP"})
              setverifybtn({...verifybtn,email:true});
              setTaskAltIcons({...TaskAltIcons,email:false})
            });
        }
      }
    };
    

    const handleVerifyPhone = (timePhone)=>{
      // setVerify({...verify,phone:true})
      if(timePhone>0){
        setVerify({...verify,phone:true})
        axios.post(`${api}/auth/sendOtpToPhone`,{phone:fromValue.phone,countryCode:fromValue.countryCode}).then((res)=>{
          console.log("res",res)
          // setOTP({...otp,})
         
        }).catch((err)=>{console.log(err)})
      }else{
        setVerify({...verify,phone:false})
        setverifybtn({...verifybtn,phone:false});
      }
    
      }

      const handleOTPVerifyPhone = (e) => {
        const { value } = e.target;
      
        // Only allow numeric values and ensure length does not exceed 4
        const isNumeric = /^\d+$/.test(value) || value === ""; // Allow empty string to support deletion
      
        // Update state only if the value is numeric and has a length <= 4
        if (isNumeric && value.length <= 4) {
          setOTP((prevOtp) => ({
            ...prevOtp,
            phone: value, // Update the OTP value
          }));
          // Trigger API call when exactly 4 digits are entered
          if (value.length === 4) {
            axios
              .post(`${api}/auth/verifyOtpForPhone`, {phone:fromValue.phone,countryCode:fromValue.countryCode,
                otp: value })
              .then((res) => {
                console.log("OTP verified successfully:", res,res.status);
                if(res.status===200){
                setVerify({...verify,phone:false})
                setverifybtn({...verifybtn,phone:false});
                setTaskAltIcons({...TaskAltIcons,phone:true})
                }
              })
              .catch((err) => {
                console.error("Error verifying OTP:", err);
                setVerify({...verify,phone:true,phoneMsg:"Invalid OTP"})
                setverifybtn({...verifybtn,phone:true});
                setTaskAltIcons({...TaskAltIcons,phone:false})
              });
          }
        }
      };
    
  const list = (anchor) => (
 
    <Box display="flex" gap={3} sx={{width:400,
      // maxWidth:"100%",
      height: '100vh',
      display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={() => props.setValue(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box  sx={{padding:'24px', backgroundColor:"#fff",height:"100%",   width:"100%",   overflowY:"scroll",
}}>
      <Typography   variant="h6" sx={{ textDecoration: 'underline',
          fontWeight: 'bold',   mb: 2 
       }}>{props.index !== 0?"Edit User":"Add New User"}</Typography>  
    
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 ,mt:1}}>
            Country
          </Typography>
         
          <CustomTextField
      variant="outlined"
       select
    //   label="Select Country"
      value={fromValue.country||"Select Country"}
      onChange={handleCountryChange}
      fullWidth
      name="country"
      placeholder="Select Country"
      error={!!errors.country} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.country ? '#D34040' : undefined },
      }}

    >

        <MenuItem value="Select Country" disabled>
        Select Country
  </MenuItem>
      {contries.countries.map((country) => (
        // console.log(country.name,country.code)
        <MenuItem key={country.dial_code} value={country.name}>
          {country.name}
        </MenuItem>
      ))}
       
    </CustomTextField>
    {errors.country && (
          <Typography variant="body2" color="#D34040">
            {errors.country}
          </Typography>
        )}
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 ,mt:1}}>
            Company Name
          </Typography>
         
          <CustomTextField
      variant="outlined"
      value={fromValue.companyName}
      fullWidth
      name="companyName"
      placeholder="Prefilled"
      InputProps={{
        readOnly: true,  // Makes the field read-only
      }}
      error={!!errors.companyName} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.companyName ? '#D34040' : undefined },
      }}

    />
    {errors.companyName && (
          <Typography variant="body2" color="#D34040">
            {errors.companyName}
          </Typography>
        )}
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 ,mt:1}}>
            Employee Name
          </Typography>
         
          <CustomTextField
      variant="outlined"
      value={fromValue.name}
      onChange={handleChange}
      fullWidth
      name="name"
      placeholder="Enter Name"
      error={!!errors.name} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.name ? '#D34040' : undefined },
      }}

    />
    {errors.name && (
          <Typography variant="body2" color="#D34040">
            {errors.name}
          </Typography>
        )}
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1,mt:1 }}>
             Company Email
          </Typography>
         <Box sx={{display:`${TaskAltIcons.email===true?"flex":"block"}`,width:`${TaskAltIcons.email===true?"105%":"100%"}`}}>
          <CustomTextField
      variant="outlined"
      value={selectedEmail}
      onChange={handleEmailChange}
      fullWidth
      name="email"
      placeholder="Enter Email"
      error={!!errors.email} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.email ? '#D34040' : undefined },
      }}

    />{errors.email && (
      <Typography variant="body2" color="#D34040">
        {errors.email}
      </Typography>
    )}

   {TaskAltIcons.email===true && (<TaskAltIcon sx={{color:"green",textAlign:"right"}}/>)} 
  {verifybtn.email&&(<Box   sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}
    onClick={()=>handleVerifyEmail(180)}
    >
  Verify
</Box>)}  
{ verifybtn.email===true && verify.email &&(
<Box>
<Typography>Enter OTP For Email</Typography>

<CustomTextField
      variant="outlined"
      value={otp.email}
      onChange={handleOTPVerify}
      fullWidth
      name="otpEmail"
      placeholder="Enter OTP"
      inputProps={{ maxLength: 4,  inputMode: "numeric", pattern: "[0-9]*"}}
      // error={!!errors.phone} // Show error styling if there's an error
           
      // InputLabelProps={{
      //   style: { color: errors.phone ? '#D34040' : undefined },
      // }}
    />
      <Typography variant="body2" color="#D34040">
            {verify.emailMsg}
          </Typography>
  {time>0?( <Box sx={{textAlign:"right"}}> 
     {formatTime(time)}

   </Box>)
   :(<Box   sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}
    onClick={()=>handleResend()}
    >
  Resend
</Box>)}
    </Box>)}
    </Box>
    {/* {errors.email && (
          <Typography variant="body2" color="#D34040">
            {errors.email}
          </Typography>
        )} */}
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1,mt:1 }}>
            Mobile No.
          </Typography>
          <Box sx={{display:`${TaskAltIcons.phone===true?"flex":"block"}`,width:`${TaskAltIcons.phone===true?"105%":"100%"}`}}>   
          <CustomTextField
      variant="outlined"
      value={fromValue.phone}
      onChange={handlePhoneChange}
      fullWidth
      name="phone"
      placeholder="Type Here"
      InputProps={{
        startAdornment:  <InputAdornment position="start" sx={{ marginRight: "10px" }}>
               <span style={{ marginLeft: "8px" }}>{fromValue.countryCode}</span> 
              <div
                style={{
                  borderLeft: "1px solid #000", // Color of the vertical line
                  height: "24px", // Height of the line
                  marginRight: "8px", // Space between the line and the percentage symbol
                }}
              />
        
            </InputAdornment>
      }}
      error={!!errors.phone} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.phone ? '#D34040' : undefined },
      }}

    />
     {TaskAltIcons.phone===true && (<TaskAltIcon sx={{color:"green",textAlign:"right"}}/>)} 
</Box> 
    {verifybtn.phone && (  <Box onClick={()=>handleVerifyPhone(180)}  sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}>
  Verify
</Box>)}
{ verifybtn.phone===true && verify.phone &&(
  <Box>
<Typography>Enter OTP For Phone</Typography>
<CustomTextField
      variant="outlined"
      value={otp.phone}
      onChange={handleOTPVerifyPhone}
      fullWidth
      name="otpPhone"
      placeholder="Enter OTP"
      inputProps={{ maxLength: 4,  inputMode: "numeric", pattern: "[0-9]*"}}
      // error={!!errors.phone} // Show error styling if there's an error
           
      // InputLabelProps={{
      //   style: { color: errors.phone ? '#D34040' : undefined },
      // }}
    />
     <Typography variant="body2" color="#D34040">
            {verify.phoneMsg}
          </Typography>
     {timePhone>0?( <Box sx={{textAlign:"right"}}> 
     {formatTimePhone(timePhone)}

   </Box>)
   :(<Box   sx={{fontSize:"14px",color:"green",textDecoration:"underline",textAlign: "right",cursor:"pointer"}}
    onClick={()=>handleResendPhone()}
    >
  Resend
</Box>)}
    </Box>)}
    
    {errors.phone && (
          <Typography variant="body2" color="#D34040">
            {errors.phone}
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 ,mt:1}}>
            Country of Operation
         </Typography>
         
          <CustomTextField
      variant="outlined"
      // select
      value={fromValue.countryOperation}
      // onChange={handleCountryChange}
      fullWidth
      name="countryOperation"
      placeholder="Tap to Select"
      InputProps={{
        readOnly: true,  // Makes the field read-only
      }}
      error={!!errors.countryOperation} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.countryOperation ? '#D34040' : undefined },
      }}
    />
      {errors.countryOperation && (
          <Typography variant="body2" color="#D34040">
            {errors.countryOperation}
          </Typography>
        )}
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1,mt:1 }}>
        Facility     
          </Typography>
         
          <CustomTextField
          select
      variant="outlined"
       value = {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}
      onChange={handleDropdownChange}
      fullWidth
      name="facility"
      placeholder="Tap to Select"
      error={!!errors.facility} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.facility ? '#D34040' : undefined },
      }}
    >
      {console.log("fromValue.facility",fromValue.facility)}
        <MenuItem value= {fromValue.facility === "" ? "Tap to Select" : fromValue.facility} disabled>
    {/* Tap to Select */}
   {fromValue.facility === "" ? "Tap to Select" : fromValue.facility}
  </MenuItem>
        {facility.map((item,i) => (
        <MenuItem key={i} value={item._id}>
          {item.facilityName}
        </MenuItem>
      ))}
    </CustomTextField>
    {errors.facility && (
          <Typography variant="body2" color="#D34040">
            {errors.facility}
          </Typography>
        )}
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1,mt:1 }}>
    Assign Role     
          </Typography>
         
          <CustomTextField
          select
      variant="outlined"
      value={fromValue.role}
      onChange={handleDropdownChange}
      fullWidth
      name="role"
      placeholder="Tap to Select"
      error={!!errors.role} // Show error styling if there's an error
           
      InputLabelProps={{
        style: { color: errors.role ? '#D34040' : undefined },
      }}
    >
       <MenuItem value={fromValue.role===""?"Tap to Select":fromValue.role} disabled>
    Tap to Select
  </MenuItem>
        {roles.map((item,i) => (
        <MenuItem key={i} value={item._id}>
          {item.name}
        </MenuItem>
      ))}
    </CustomTextField>
    {errors.role && (
          <Typography variant="body2" color="#D34040">
            {errors.role}
          </Typography>
        )}
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1,mt:1 }}>
    Assigned Permission     
          </Typography>
      <CustomTextField
      // select
      InputProps={{
        readOnly: true,  // Makes the field read-only
      }}
    
      variant="outlined"
      // value={{FunctionalityRole.length > 0 && FunctionalityRole.map((item) =>
      //   item._id === fromValue.role 
      //     ? item.allowed_permissions.map((permission) => (permission.name ))
      //     : [])}}
      value={
        FunctionalityRole.length > 0 
          ? FunctionalityRole.filter(item => item._id === fromValue.role)
            .map(item => item.allowed_permissions.map(permission => permission.name).join(', '))
          : ''
      }
      onChange={handleFunChange}  // Correctly passing selected value
      fullWidth
      name="functionlityOfRole"
      placeholder="Prefilled According to Role"
      // error={!!errors.functionlityOfRole} // Show error styling if there's an error
           
      // InputLabelProps={{
      //   style: { color: errors.functionlityOfRole ? '#D34040' : undefined },
      // }}
    />
     

     {/* {errors.functionlityOfRole && (
          <Typography variant="body2" color="#D34040">
            {errors.functionlityOfRole}
          </Typography>
        )} */}
  {/* {FunctionalityRole.length > 0 && FunctionalityRole.map((item, i) => (
    <CustomTextField
    select
    variant="outlined"
    value={fromValue.functionlityOfRole}
    // onChange={(event) => handleFunChange(event.target.value)}
    onChange={handleFunChange}  // Correct way to pass the function
    fullWidth
    name="functionlityOfRole"
    placeholder="Tap to Select"
  >
      {item.allowed_permissions.map((permission, index) => (
        <MenuItem key={permission._id} value={permission._id}>
          {permission.name}
        </MenuItem>
      ))}
 
    </CustomTextField>
  ))} */}


    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1,mt:1 }}>
        Remark  
          </Typography>
         
          <TextField
          // select
          rows={2}
      variant="outlined"
      value={fromValue.remark}
       onChange={handleDropdownChange}
      fullWidth
      name="remark"
      placeholder="Brief"
      multiline
    >
      
    </TextField>
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
            onClick={Submit}

            >
              Submit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
              <Drawer
               sx={{
                width:"100%",
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.value}
                onClose={() => props.setValue(false)}
              >
                {list()}
              </Drawer>
        </div>
      );

    }
export default Add;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
      width:"100%"
    },
  }));
  