import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import "../Scrollbar.css"

const CountryFilter = ({
  selectedCountries = [],
  setSelectedCountries,
  url,
  errors,
}) => {
  const [countries, setCountries] = useState([]);

 

  useEffect(() => {
    // Fetch all countries from the backend
    const fetchCountries = async () => {
      try {
        const response = await axios.get(url);
        console.log(response);
        // Extract unique country names from the user data
        const uniqueCountries = response.data.users
          ? [...new Set(response.data.users.map((user) => user.country))]
          : [...new Set(response.data.data.map((user) => user.country))];
        setCountries(uniqueCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
 
    fetchCountries();
  }, []);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    if (event.target.checked) {
      // Add country to selected list
      setSelectedCountries((prevSelected) => [
        ...prevSelected,
        selectedCountry,
      ]);
    } else {
      // Remove country from selected list
      setSelectedCountries((prevSelected) =>
        prevSelected.filter((country) => country !== selectedCountry)
      );
    }
  };

  return (
    <Box sx={{ m: "5%", mt: 0 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
          // m:"10%"
        }}
      >
        Country
      </Typography>
      <Box className="scrollable-container">
        {countries.length > 0 ? (
          countries.map((country, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  value={country}
                  checked={selectedCountries?.includes(country)} // Ensure selectedCountries is an array
                  onChange={handleCountryChange}
                />
              }
              label={country}
            />
          ))
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      {errors && (
        <Typography variant="body2" color="#D34040" sx={{ mt: 2 }}>
          {errors}
        </Typography>
        
      )}
    </Box>
  );
};

export default CountryFilter;
