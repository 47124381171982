
// import  React ,{useState,useEffect} from 'react';
// import { Box, TextField, Typography,MenuItem,Button ,IconButton, Collapse }  from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
// import Drawer from '@mui/material/Drawer';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import axios from 'axios';
// import { api } from '../../../../utils/api';
// import { companyId } from '../../../../utils/companyId';
// import { useMemo } from 'react';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// function AddMachinery(props) {
//       const [moduleName, setmoduleName] = useState([]);
//       const [alreadyRole,setAlreadyUser] = useState(false);
//       const [alreadyMachinery, setAlreadyMachinery] = useState(false);
//       const [facilities, setFacilities] = useState(["Tap to Select"]);
//       const [machineCategory, setMachineCategory] = useState([]);
//       const [machineTypes, setMachineTypes] = useState([]);
//       const [selectedCategory, setSelectedCategory] = useState('');
//       const [errors,setErrors]=useState({});
//       const [numberOfMachines, setNumberOfMachines] = useState(null);
//       const [equipments, setEquipments] = useState([]);
//       const [openMachines, setOpenMachines] = useState([]);
//       const [selectedMachineType, setSelectedMachineType] = useState("");
//       const initialState ={
//         company:companyId,
//         location: "",
//         facility: "",
//         machineCategory: "",
//         machineType: "",
//         name: "",
//         fuelType: "",
//         // noOfEquipment: "",
//         numberOfMachines: "",
//         equipments:[],
      
        
//       };
//       const [fromValue, setFromValue] = useState(initialState);
     
//   useEffect(() => {
//     // Fetch facilities data
//     axios.get(`${api}/master/getAllFacility`).then((res) => {
//       setFacilities(res.data.data); // Assuming the response contains facility data
//     }).catch((err) => {
//       console.log(err, "err");
//     });
//   }, []);


 

//   useEffect(() => {
//     // Fetch machinery types from the API
//     axios
//       .get(`${api}/master/getAllMachineryType`)
//       .then((res) => {
//         console.log(res.data);
//         if (res.data && Array.isArray(res.data.data)) {
//           // Update state with fetched machinery types, including _id and name
//           setMachineCategory(res.data.data.map(item => ({
//             id: item._id, // Assuming your API returns _id
//             name: item.type // Adjust according to your API response structure
//           })));
//           // setAlreadyMachinery(!!props.selectedEquipment); 
//         } else {
//           console.log('Unexpected response structure:', res.data);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);



// useEffect(() => {
//   if (props.selectedEquipment) {
//     setFromValue(props.selectedEquipment);
//   } else {
//     setFromValue(initialState);
//   }
// }, [props.selectedEquipment]);

// const handleCategoryChange = (e) => {
//   const selectedId = e.target.value;
//   const selectedCategory = machineCategory.find(category => category.id === selectedId);

//   setSelectedCategory(selectedId);
//   console.log("Selected Category:", selectedId);

//   // Clear the machine types when the category changes
//   setMachineTypes([]); // Clear previous machine types

//   if (selectedCategory) {
//       const categoryType = selectedCategory.name;
//       console.log("Category Type for fetching machine types:", categoryType);

//       axios.get(`${api}/master/getAllMachineryType`, {
//           params: { type: categoryType },
//       })
//       .then(res => {
//           if (res.data && Array.isArray(res.data.data)) {
//               const machineTypesWithIds = res.data.data.map(type => ({
//                   id: type._id,
//                   name: type.name
//               }));
//               console.log("Fetched Machine Types:", machineTypesWithIds);
//               setMachineTypes(machineTypesWithIds);
//           } else {
//               console.log('Unexpected response structure:', res.data);
//           }
//       })
//       .catch(err => {
//           console.error("Error fetching machine types:", err);
//       });
//   }

//   setFromValue(prevState => ({
//       ...prevState,
//       machineCategory: selectedId,
//       machineType: '' // Clear machineType to ensure it starts fresh
//   }));
// };



// const handleMachineTypeChange = (e) => {
//   const selectedMachineTypeId = e.target.value; // This should be the ID of the selected machine type
//   console.log("Selected Machine Type ID:", selectedMachineTypeId); // Log the selected ID

//   setFromValue(prevState => ({
//       ...prevState,
//       machineType: selectedMachineTypeId // Update with the ID
//   }));

//   console.log("Machine Types in State:", machineTypes); // Log machine types again after selection
// };


// const handleNoOfEquipmentChange = (e) => {
//   const value = parseInt(e.target.value, 10);
  
//   if (!isNaN(value) && value > 0) {
//     setNumberOfMachines(value);  // Only set if it's a valid number
//     console.log(value, "value for number of machines");

//     const equipmentList = Array.from({ length: value }, (_, index) => ({
//       id: `equipment-${index + 1}`,
//       uniqueID: '',
//       brand: '',
//       model: '',
//       ratedFuelConsumption: '', 
//       unitOfFuelConsumption: '',
//     }));

//     setEquipments(equipmentList);
//     setOpenMachines(Array(value).fill(false)); // For toggling visibility
//   } else {
//     setNumberOfMachines(null);  // Set to null if input is invalid
//   }
// };
  
// const handleFieldChange = (index, field, value) => {
//   console.log(`Updating equipment ${index}, field ${field}, with value ${value}`);
//   const updatedEquipments = [...equipments];
//   updatedEquipments[index][field] = value;
//   setEquipments(updatedEquipments);
// };

//   const toggleEquipment = (index) => {
//     const updatedOpen = [...openMachines];
//     updatedOpen[index] = !updatedOpen[index];
//     setOpenMachines(updatedOpen);
//   };


//   const validateFields = () => {
//     const newErrors = {};
//     // Check for empty fields
//     if (!fromValue.location) {
//       newErrors.location = "Location is required.";
//     }
//     if (!fromValue.facility || fromValue.facility === "Tap to Select") {
//       newErrors.facility = "Facility must be selected.";
//     }
//     if (!fromValue.machineCategory || fromValue.machineCategory === "Tap to Select") {
//       newErrors.machineCategory = "Machinery Category must be selected.";
//     }
//     if (!fromValue.machineType || fromValue.machineType === "Tap to Select") {
//       newErrors.machineType = "Machine Type must be selected.";
//     }
//     if (!fromValue.name) {
//       newErrors.name = "Machinery Name is required.";
//     }
//     if (!fromValue.fuelType || fromValue.fuelType === "Tap to Select") {
//       newErrors.fuelType = "Fuel Type must be selected.";
//     }
//     if (!numberOfMachines) {
//       newErrors.numberOfMachines = "Number of Machines is required.";
//     } else if (numberOfMachines <= 0) {
//       newErrors.numberOfMachines = "Number of Machines must be greater than 0.";
//     }
  
//     // Check equipment fields if number of machines is greater than 0
//     if (numberOfMachines > 0) {
//       equipments.forEach((equipment, index) => {
//         if (!equipment.uniqueID) {
//           newErrors[`uniqueID_${index}`] = `Unique ID for Machinery ${index + 1} is required.`;
//         }
//         if (!equipment.brand) {
//           newErrors[`brand_${index}`] = `Brand for Machinery ${index + 1} is required.`;
//         }
//         if (!equipment.model) {
//           newErrors[`model_${index}`] = `Model for Machinery ${index + 1} is required.`;
//         }
//         if (!equipment.ratedFuelConsumption) {
//           newErrors[`ratedFuelConsumption_${index}`] = `Rated Fuel Consumption for Machinery ${index + 1} is required.`;
//         } else if (isNaN(equipment.ratedFuelConsumption)) {
//           newErrors[`ratedFuelConsumption_${index}`] = `Please enter a valid numeric value for Machinery ${index + 1}.`;
//         } else if (equipment.ratedFuelConsumption < 0) {
//           newErrors[`ratedFuelConsumption_${index}`] = `Please enter valid numeric value for Machinery ${index + 1}.`;
//         }
//         if (!equipment.unitOfFuelConsumption) {
//           newErrors[`unitOfFuelConsumption_${index}`] = `Unit of Fuel Consumption for Machinery ${index + 1} is required.`;
//         }
//       });
//     }
  
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0; // Return true if no errors
//   };



// useEffect(()=>{
//   console.log("props.indexMachinery?._id",props.indexMachinery)
//   setFromValue({
//     company: companyId,
//     location: props.indexMachinery?.location ?? "",
//     facility: props.indexMachinery?.facility?._id ?? "",
//     fuelType: props.indexMachinery?.fuelType ?? "",
//     machineCategory: props.indexMachinery?.machineCategory?._id ?? "",
//     machineType: props.indexMachinery?.machineType?._id ?? "",
//     equipments: props.indexMachinery?.equipments ?? [],
 
//     numberOfMachines: props.indexMachinery?.numberOfMachines ?? "",
//     name: props.indexMachinery?.name ?? "",
    
//   })
//   // setFacilities(props.indexMachinery?.facility?._id ?? "")
//   setEquipments(props.indexMachinery?.equipments ?? [])
//   setNumberOfMachines(props.indexMachinery?.numberOfMachines ?? "")
//   setAlreadyMachinery(props.indexMachinery?._id===undefined?false:true)
// },[props.indexMachinery]);

// const resetForm = () => {
//   console.log("_idddddd",props.selectedEquipment?._id)
//   setFromValue(props.selectedEquipment?._id === undefined?initialState:props.selectedEquipment);
//   setErrors({});
// };


// const formTitle = props.indexMachinery?._id !==undefined ? "Edit Machinery" : "Add Machinery";


// const handleSubmit = async (e) => {
//   e.preventDefault();
  
//   if (validateFields()) {
//     const data = {
//       date: new Date(),
//       machineType: fromValue.machineType,
//       machineCategory: fromValue.machineCategory,
//       company: fromValue.company,
//       facility: fromValue.facility,
//       location: fromValue.location,
//       name: fromValue.name,
//       equipments,
//       fuelType: fromValue.fuelType,
//       numberOfMachines: numberOfMachines || 0,  // Default to 0 if null
//     };

//     axios({
//       method: alreadyMachinery ? 'put' : 'post',
//       url: alreadyMachinery ? `${api}/master/updateMachinery` : `${api}/master/addMachinery`,
//       data: data,  // Send data, with a valid numberOfMachines
//       params: alreadyMachinery ? { id: props.indexMachinery?._id } : null  // Send params only for PUT request
//     })
//     .then(response => {
//       console.log("Data saved successfully", response.data);
//       resetForm();
//       props.setValue(false);
//       props.fetchMachinery();
//       props.setsnackOpen({ open: true, message: response.data.message, severity: "success" });
//     })
//     .catch(error => {
//       console.error("Error saving data:", error);
//       props.setsnackOpen({ open: true, message: error?.response?.data?.message, severity: "error" });
//     });
//   }
// }



// const fuelTypes = [
//   "Diesel (average biofuel blend)",
//   "Diesel (100% mineral diesel)",
//   "Petrol (average biofuel blend)",
//   "Petrol (100% mineral petrol)",
//   "CNG",
//   "Electricity",
// ];



// const uniqueMachineCategories = useMemo(() => {
//   const seen = new Set();
//   return machineCategory.filter(category => {
//     const duplicate = seen.has(category.name);
//     seen.add(category.name);
//     return !duplicate;
//   });
// }, [machineCategory]);


// const handleTypeChange = (event) => {
//   const selectedTypeId = event.target.value;
//   setFromValue(prevState => ({
//       ...prevState,
//       machineType: selectedTypeId,
//   }));
//   console.log("Selected Machine Type ID:", selectedTypeId);
// };

// const list = () => (
//   <Box display="flex" gap={3} sx={{
//     width: 420,
//     height: "100vh", 
//     display: "flex",
//     justifyContent: "space-between",
//     backgroundColor: "transparent",
//     overflowY: "auto", 
    
// }}>
  
//       <Box sx={{
//           backgroundColor: "#fff",
//           height: "3%",
//           padding: "13px",
//           margin: "30px 0 0 0",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           borderRadius: "80%",
//           width: "5%"
//       }}>
//           <IconButton onClick={() => { props.setValue(false); resetForm(); }}
//           // onClick={() => {props.setValue(false); resetForm()}} 
//           sx={{ color: "black" }}>
//               <CloseIcon sx={{ fontSize: "1.2rem" }} />
//           </IconButton>
//       </Box>

//       <Box sx={{ width: "100%", height:"100%",  p: 3, backgroundColor: "#fff", overflowY:"scroll" }}>
//           <Typography variant="h6" sx={{ textDecoration: 'underline', fontWeight: 'bold', mb: 2 }}>
//               {/* {props.index !== 0 ? "Edit Role" : "Add Industrial Equipment Master"} */}
//               {/* {props.selectedEquipment ? "Edit Machinery" : "Add Machinery"} */}
//               {formTitle}
//           </Typography>  

//           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//               Location
//           </Typography>

//           <CustomTextField
//               variant="outlined"
//               value={fromValue.location}
//               fullWidth
//               name="location"
//               placeholder="Enter Location"
//               onChange={(e) => setFromValue({ ...fromValue, location: e.target.value })}
//               error={!!errors.location} 
//           />
//           {errors.location && (
//           <Typography variant="body2" color="#D34040">
//             {errors.location}
//             </Typography>
//             )}

//           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//               Facility
//           </Typography>
//           <CustomTextField
//               select
//               variant="outlined"
//               // value={fromValue.facility || ""}
//               value = {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}
//               fullWidth
//               name="facility"
//                placeholder="Tap to Select"
//               onChange={(e) => setFromValue({ ...fromValue, facility: e.target.value })}
//               error={!!errors.facility}
//               InputLabelProps={{ style: { color: errors.facility ? '#D34040' : undefined } }}
//           >
//               <MenuItem value = {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}disabled>
//                   {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}
//               </MenuItem>
//                 {facilities.map((facility) => (
//                 <MenuItem key={facility._id} value={facility._id}>
//                 {facility.facilityName}
//                 </MenuItem>
//                 ))}
//           </CustomTextField>
//           {errors.facility && (
//               <Typography variant="body2" color="#D34040">
//                   {errors.facility}
//               </Typography>
//           )}

       


//         <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//           Machinery Category
//         </Typography>
//         <CustomTextField
//           select
//           variant="outlined"
//           // value={fromValue.machineCategory}
//           value = {fromValue.machineCategory==="" ? "Tap to Select" : fromValue.machineCategory}
//           fullWidth
//           name="machineCategory"
//           onChange={handleCategoryChange}
//           error={!!errors.machineCategory}
//         >
//           <MenuItem value = {fromValue.machineCategory==="" ? "Tap to Select" : fromValue.machineCategory} disabled>
//           {fromValue.machineCategory==="" ? "Tap to Select" : fromValue.machineCategory}
//           </MenuItem>
//           {uniqueMachineCategories.map((category) => (
//             <MenuItem key={category.id} value={category.id}> 
//               {category.name}
//             </MenuItem>
//           ))}
//         </CustomTextField>
//         {errors.machineCategory && (
//               <Typography variant="body2" color="#D34040">
//                   {errors.machineCategory}
//               </Typography>
//           )}

        
//       <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//           Machine Type
//       </Typography>
//       <CustomTextField
//           select
//           variant="outlined"
//           // value={fromValue.machineType}
//           value = {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}
//           fullWidth
//           name="machineType"
//           onChange={handleMachineTypeChange}
//           error={!!errors.machineType}
//       >
//           <MenuItem value = {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}disabled>
//           {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}
//           </MenuItem>
//           {machineTypes.length === 0 ? (
//               <MenuItem disabled>No Machine Types Available</MenuItem>
//           ) : (
//               machineTypes.map((type) => (
//                   <MenuItem key={type.id} value={type.id}> 
//                       {type.name}  
//                   </MenuItem>
//               ))
//           )}
//       </CustomTextField>
//       {errors.machineType && (
//               <Typography variant="body2" color="#D34040">
//                   {errors.machineType}
//               </Typography>
//           )}
     
//       {console.log("Machine Types in State:", machineTypes)} 
//       {console.log("Selected Machine Type:", fromValue.machineType)}

//           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//               Machinery Name
//           </Typography>
//           <CustomTextField
//               variant="outlined"
//               value={fromValue.name}
//               fullWidth
//               name="name"
//               placeholder="Enter name"
//               onChange={(e) => setFromValue({ ...fromValue, name: e.target.value })}
//               error={!!errors.name}
//           />
//           {errors.name && (
//         <Typography variant="body2" color="#D34040">
//           {errors.name}
//           </Typography>
//           )}

//           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//               Fuel Type
//           </Typography>
//           <CustomTextField
//               select
//               variant="outlined"
//               // value={fromValue.fuelType}
//               value = {fromValue.fuelType==="" ? "Tap to Select" : fromValue.fuelType}
//               fullWidth
//               name="fuelType"
//               onChange={(e) => setFromValue({ ...fromValue, fuelType: e.target.value })}
//               placeholder="Tap to Select"
//               error={!!errors.fuelType}
//               InputLabelProps={{
//                   style: { color: errors.fuelType ? '#D34040' : undefined },
//               }}
//           >
//               <MenuItem value={fromValue.fuelType==="" ? "Tap to Select" : fromValue.fuelType} disabled>
//               {fromValue.fuelType==="" ? "Tap to Select" : fromValue.fuelType}
//               </MenuItem>
//               {fuelTypes.map((fuel, index) => (
//                   <MenuItem key={index} value={fuel}>
//                       {fuel}
//                   </MenuItem>
//               ))}
//           </CustomTextField>
//           {errors.fuelType && (
//               <Typography variant="body2" color="#D34040">
//                   {errors.fuelType}
//               </Typography>
//           )}

//         <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//               No of Machinery
//           </Typography>
//           <CustomTextField
//               variant="outlined"
//               value={numberOfMachines || ''}
//               placeholder="Enter number of equipment"
//               fullWidth
//               onChange={handleNoOfEquipmentChange}
//           />

//           {equipments.map((equipment, index) => (
//               <Box key={equipment.id} sx={{ mt: 2 }}>
//                   {/* Equipment Title with Expand/Collapse Button */}
//                   <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                       <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
//                           Machinery {index + 1}
//                       </Typography>
//                       <IconButton onClick={() => toggleEquipment(index)}>
//                           {openMachines[index] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
//                       </IconButton>
//                   </Box>

//                   {/* Collapse for Equipment Fields */}
//                   <Collapse in={openMachines[index]} timeout="auto" unmountOnExit>
//                       <Box sx={{ mt: 1 }}>
//                           {/* Unique ID */}
//                           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//                               Unique ID
//                           </Typography>
//                           <CustomTextField
//                               variant="outlined"
//                               // value={equipment.uniqueID}
//                               value={equipments[index].uniqueID}
//                               placeholder="Enter ID"
//                               fullWidth
//                               onChange={(e) => handleFieldChange(index, 'uniqueID', e.target.value)}
//                               error={!!errors[`uniqueID_${index}`]}  
//                           />
//                            {errors[`uniqueID_${index}`] && (
//                           <Typography variant="body2" color="#D34040">
//                           {errors[`uniqueID_${index}`]}
//                           </Typography>
//                         )}

//                           {/* Brand */}
//                           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//                               Brand
//                           </Typography>
//                           <CustomTextField
//                               variant="outlined"
//                               value={equipment.brand}
//                               placeholder="Enter Brand"
//                               fullWidth
//                               onChange={(e) => handleFieldChange(index, 'brand', e.target.value)}
//                               error={!!errors[`brand_${index}`]}
//                           />
//                           {errors[`brand_${index}`] && (
//                           <Typography variant="body2" color="#D34040">
//                             {errors[`brand_${index}`]}
//                           </Typography>
//                         )}

//                           {/* Model */}
//                           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//                               Model
//                           </Typography>
//                           <CustomTextField
//                               variant="outlined"
//                               value={equipment.model}
//                               placeholder="Enter Model"
//                               fullWidth
//                               onChange={(e) => handleFieldChange(index, 'model', e.target.value)}
//                               error={!!errors[`model_${index}`]}
//                           />
//                           {errors[`model_${index}`] && (
//                           <Typography variant="body2" color="#D34040">
//                             {errors[`model_${index}`]}
//                           </Typography>
//                         )}

//                           {/* Rated Fuel Consumption */}
//                           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//                               Rated Fuel Consumption
//                           </Typography>
//                           <CustomTextField
//                               variant="outlined"
//                               value={equipments[index].ratedFuelConsumption}
//                               placeholder="Amount"
//                               fullWidth
//                               onChange={(e) => handleFieldChange(index, 'ratedFuelConsumption', e.target.value)}
//                               error={!!errors[`ratedFuelConsumption_${index}`]}
//                           />
//                           {errors[`ratedFuelConsumption_${index}`] && (
//                           <Typography variant="body2" color="#D34040">
//                             {errors[`ratedFuelConsumption_${index}`]}
//                           </Typography>
//                         )}


//                           {/* Unit of Fuel Consumption */}
//                           <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
//                               Unit of Fuel Consumption
//                           </Typography>
//                           <CustomTextField
//                               variant="outlined"
//                               value={equipment.unitOfFuelConsumption}
//                               placeholder="ltr/kg per hour"
//                               fullWidth
//                               onChange={(e) => handleFieldChange(index, 'unitOfFuelConsumption', e.target.value)}
//                               error={!!errors[`unitOfFuelConsumption_${index}`]}
//                           />
//                           {errors[`unitOfFuelConsumption_${index}`] && (
//                         <Typography variant="body2" color="#D34040">
//                           {errors[`unitOfFuelConsumption_${index}`]}
//                         </Typography>
//                       )}
//                       </Box>
//                   </Collapse>
//               </Box>
//           ))}


//           <Box>
//               <Button variant="contained" sx={{ backgroundColor: "#0a3f73", textTransform: "none", marginTop: "10%" }} onClick={handleSubmit}>
//                   Submit
//               </Button>
//           </Box>
//       </Box>
//   </Box>
// );

//     return (
//         <div>
//               <Drawer
//                sx={{
//                 backgroundColor: "transparent",
//             '& .MuiDrawer-paper': { 
//                    backgroundColor: "transparent", 
//                    boxShadow: 'none', 
//                  }
//              }}
//                 anchor="right"
//                 open={props.value}
//                 onClose={()=>{props.setValue(false); resetForm()}}
//               >
//                 {list()}
//               </Drawer>
              
      
      
//         </div>
//       );
// }

// export default AddMachinery;




// //// custom textfield

// const CustomTextField = styled(TextField)(({ theme }) => ({
//     '& .MuiInputBase-root': {
//       height: '40px', // Adjust height as needed
//     },
//     '& .MuiSelect-select': {
//       height: '56px', // Adjust height as needed
//       display: 'flex',
//       alignItems: 'center',
//     },
//   }));
  


import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton, Collapse }  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import axios from 'axios';
import { api } from '../../../../utils/api';
import { companyId } from '../../../../utils/companyId';
import { useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from "@mui/icons-material/Delete";

function AddMachinery(props) {
      const [moduleName, setmoduleName] = useState([]);
      const [alreadyRole,setAlreadyUser] = useState(false);
      const [alreadyMachinery, setAlreadyMachinery] = useState(false);
      const [facilities, setFacilities] = useState(["Tap to Select"]);
      const [machineCategory, setMachineCategory] = useState([]);
      const [machineTypes, setMachineTypes] = useState([]);
      const [selectedCategory, setSelectedCategory] = useState('');
      const [errors,setErrors]=useState({});
      const [numberOfMachines, setNumberOfMachines] = useState(null);
      const [equipments, setEquipments] = useState([]);
      const [openMachines, setOpenMachines] = useState([]);
      const [selectedMachineType, setSelectedMachineType] = useState("");
      const initialState ={
        company:companyId,
        location: "",
        facility: "",
        machineCategory: "",
        machineType: "",
        name: "",
        fuelType: "",
        // noOfEquipment: "",
        numberOfMachines: "",
        equipments:[],
      
        
      };
      const [fromValue, setFromValue] = useState(initialState);
      const [isLoading, setIsLoading] = useState(false);
     
  useEffect(() => {
    // Fetch facilities data
    axios.get(`${api}/master/getAllFacility`).then((res) => {
      setFacilities(res.data.data); // Assuming the response contains facility data
    }).catch((err) => {
      console.log(err, "err");
    });
  }, []);


 

  useEffect(() => {
    // Fetch machinery types from the API
    axios
      .get(`${api}/master/getAllMachineryType`)
      .then((res) => {
        console.log(res.data);
        if (res.data && Array.isArray(res.data.data)) {
          // Update state with fetched machinery types, including _id and name
          setMachineCategory(res.data.data.map(item => ({
            id: item._id, // Assuming your API returns _id
            name: item.type // Adjust according to your API response structure
          })));
          // setAlreadyMachinery(!!props.selectedEquipment); 
        } else {
          console.log('Unexpected response structure:', res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



useEffect(() => {
  if (props.selectedEquipment) {
    setFromValue(props.selectedEquipment);
  } else {
    setFromValue(initialState);
  }
}, [props.selectedEquipment]);

const handleCategoryChange = (e) => {
  const selectedId = e.target.value;
  const selectedCategory = machineCategory.find(category => category.id === selectedId);

  setSelectedCategory(selectedId);
  console.log("Selected Category:", selectedId);

  // Clear the machine types when the category changes
  setMachineTypes([]); // Clear previous machine types

  if (selectedCategory) {
      const categoryType = selectedCategory.name;
      console.log("Category Type for fetching machine types:", categoryType);

      axios.get(`${api}/master/getAllMachineryType`, {
          params: { type: categoryType },
      })
      .then(res => {
          if (res.data && Array.isArray(res.data.data)) {
              const machineTypesWithIds = res.data.data.map(type => ({
                  id: type._id,
                  name: type.name
              }));
              console.log("Fetched Machine Types:", machineTypesWithIds);
              setMachineTypes(machineTypesWithIds);
          } else {
              console.log('Unexpected response structure:', res.data);
          }
      })
      .catch(err => {
          console.error("Error fetching machine types:", err);
      });
  }

  setFromValue(prevState => ({
      ...prevState,
      machineCategory: selectedId,
      machineType: '' // Clear machineType to ensure it starts fresh
  }));
};



const handleMachineTypeChange = (e) => {
  const selectedMachineTypeId = e.target.value; // This should be the ID of the selected machine type
  console.log("Selected Machine Type ID:", selectedMachineTypeId); // Log the selected ID

  setFromValue(prevState => ({
      ...prevState,
      machineType: selectedMachineTypeId // Update with the ID
  }));

  console.log("Machine Types in State:", machineTypes); // Log machine types again after selection
};


const handleNoOfEquipmentChange = (e) => {
  const value = parseInt(e.target.value, 10);
  
  if (!isNaN(value) && value > 0) {
    setNumberOfMachines(value);  // Only set if it's a valid number
    console.log(value, "value for number of machines");

    const equipmentList = Array.from({ length: value }, (_, index) => ({
      id: `equipment-${index + 1}`,
      uniqueID: '',
      brand: '',
      model: '',
      ratedFuelConsumption: '', 
      unitOfFuelConsumption: '',
    }));

    setEquipments(equipmentList);
    setOpenMachines(Array(value).fill(false)); // For toggling visibility
  } else {
    setNumberOfMachines(null);  // Set to null if input is invalid
  }
};
  
const handleFieldChange = (index, field, value) => {
  console.log(`Updating equipment ${index}, field ${field}, with value ${value}`);
  const updatedEquipments = [...equipments];
  updatedEquipments[index][field] = value;
  setEquipments(updatedEquipments);
};

  const toggleEquipment = (index) => {
    const updatedOpen = [...openMachines];
    updatedOpen[index] = !updatedOpen[index];
    setOpenMachines(updatedOpen);
  };


  const validateFields = () => {
    const newErrors = {};
    // Check for empty fields
    if (!fromValue.location) {
      newErrors.location = "Location is required.";
    }
    if (!fromValue.facility || fromValue.facility === "Tap to Select") {
      newErrors.facility = "Facility must be selected.";
    }
    if (!fromValue.machineCategory || fromValue.machineCategory === "Tap to Select") {
      newErrors.machineCategory = "Machinery Category must be selected.";
    }
    if (!fromValue.machineType || fromValue.machineType === "Tap to Select") {
      newErrors.machineType = "Machine Type must be selected.";
    }
    if (!fromValue.name) {
      newErrors.name = "Machinery Name is required.";
    }
    if (!fromValue.fuelType || fromValue.fuelType === "Tap to Select") {
      newErrors.fuelType = "Fuel Type must be selected.";
    }
    if (!numberOfMachines) {
      newErrors.numberOfMachines = "Number of Machines is required.";
    } else if (numberOfMachines <= 0) {
      newErrors.numberOfMachines = "Number of Machines must be greater than 0.";
    }
  
    // Check equipment fields if number of machines is greater than 0
    if (numberOfMachines > 0) {
      equipments.forEach((equipment, index) => {
        if (!equipment.uniqueID) {
          newErrors[`uniqueID_${index}`] = `Unique ID for Machinery ${index + 1} is required.`;
        }
        if (!equipment.brand) {
          newErrors[`brand_${index}`] = `Brand for Machinery ${index + 1} is required.`;
        }
        if (!equipment.model) {
          newErrors[`model_${index}`] = `Model for Machinery ${index + 1} is required.`;
        }
        if (!equipment.ratedFuelConsumption) {
          newErrors[`ratedFuelConsumption_${index}`] = `Rated Fuel Consumption for Machinery ${index + 1} is required.`;
        } else if (isNaN(equipment.ratedFuelConsumption)) {
          newErrors[`ratedFuelConsumption_${index}`] = `Please enter a valid numeric value for Machinery ${index + 1}.`;
        } else if (equipment.ratedFuelConsumption < 0) {
          newErrors[`ratedFuelConsumption_${index}`] = `Please enter valid numeric value for Machinery ${index + 1}.`;
        }
        if (!equipment.unitOfFuelConsumption) {
          newErrors[`unitOfFuelConsumption_${index}`] = `Unit of Fuel Consumption for Machinery ${index + 1} is required.`;
        }
      });
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };



useEffect(()=>{
  console.log("props.indexMachinery?._id",props.indexMachinery)
  setFromValue({
    company: companyId,
    location: props.indexMachinery?.location ?? "",
    facility: props.indexMachinery?.facility?._id ?? "",
    fuelType: props.indexMachinery?.fuelType ?? "",
    machineCategory: props.indexMachinery?.machineCategory?._id ?? "",
    machineType: props.indexMachinery?.machineType?._id ?? "",
    equipments: props.indexMachinery?.equipments ?? [],
 
    numberOfMachines: props.indexMachinery?.numberOfMachines ?? "",
    name: props.indexMachinery?.name ?? "",
    
  })
  // setFacilities(props.indexMachinery?.facility?._id ?? "")
  setEquipments(props.indexMachinery?.equipments ?? [])
  setNumberOfMachines(props.indexMachinery?.numberOfMachines ?? "")
  setAlreadyMachinery(props.indexMachinery?._id===undefined?false:true)
},[props.indexMachinery]);

const resetForm = () => {
  console.log("_idddddd",props.selectedEquipment?._id)
  setFromValue(props.selectedEquipment?._id === undefined?initialState:props.selectedEquipment);
  setErrors({});
};


const formTitle = props.indexMachinery?._id !==undefined ? "Edit Machinery" : "Add Machinery";


const handleSubmit = async (e) => {
  e.preventDefault();
  
  if (validateFields()) {
    const data = {
      date: new Date(),
      machineType: fromValue.machineType,
      machineCategory: fromValue.machineCategory,
      company: fromValue.company,
      facility: fromValue.facility,
      location: fromValue.location,
      name: fromValue.name,
      equipments,
      fuelType: fromValue.fuelType,
      numberOfMachines: numberOfMachines || 0,  // Default to 0 if null
    };

    axios({
      method: alreadyMachinery ? 'put' : 'post',
      url: alreadyMachinery ? `${api}/master/updateMachinery` : `${api}/master/addMachinery`,
      data: data,  // Send data, with a valid numberOfMachines
      params: alreadyMachinery ? { id: props.indexMachinery?._id } : null  // Send params only for PUT request
    })
    .then(response => {
      console.log("Data saved successfully", response.data);
      resetForm();
      props.setValue(false);
      props.fetchMachinery();
      props.setsnackOpen({ open: true, message: response.data.message, severity: "success" });
    })
    .catch(error => {
      console.error("Error saving data:", error);
      props.setsnackOpen({ open: true, message: error?.response?.data?.message, severity: "error" });
    });
  }
}



const fuelTypes = [
  "Diesel (average biofuel blend)",
  "Diesel (100% mineral diesel)",
  "Petrol (average biofuel blend)",
  "Petrol (100% mineral petrol)",
  "CNG",
  "Electricity",
];



const uniqueMachineCategories = useMemo(() => {
  const seen = new Set();
  return machineCategory.filter(category => {
    const duplicate = seen.has(category.name);
    seen.add(category.name);
    return !duplicate;
  });
}, [machineCategory]);


const handleTypeChange = (event) => {
  const selectedTypeId = event.target.value;
  setFromValue(prevState => ({
      ...prevState,
      machineType: selectedTypeId,
  }));
  console.log("Selected Machine Type ID:", selectedTypeId);
};

//adding dyanamic

// const addEquipment = () => {
//   setEquipments(prev => [
//       ...prev,
//       {
//           id: `equipment-${prev.length + 1}`,
//           uniqueID: '',
//           brand: '',
//           model: '',
//           ratedFuelConsumption: '',
//           unitOfFuelConsumption: '',
//       }
//   ]);
//   setOpenMachines(prev => [...prev, false]); // Add a new toggle state
// };
// const addEquipment = (newEquipment) => {
//   setEquipments(prev => [...prev, newEquipment]);
//   setNumberOfMachines(prev => prev + 1); // Increment the count
// };

const addEquipment = () => {
  const newEquipment = {
    // uniqueID: generateUniqueID(),
    // name: 'New Equipment',
    uniqueID: '',
          brand: '',
          model: '',
          ratedFuelConsumption: '',
          unitOfFuelConsumption: '',
    // Add other fields as necessary
  };

  setEquipments(prev => [...prev, newEquipment]);
  setNumberOfMachines(prev => prev + 1);
};

// const removeEquipment = (index) => {
//   const updatedEquipments = equipments.filter((_, idx) => idx !== index);
//   setEquipments(updatedEquipments);
//   setOpenMachines(updatedEquipments.map(() => false)); // Reset toggle states
// };
const removeEquipment = (index) => {
  setEquipments(prev => {
    const updatedEquipments = prev.filter((_, i) => i !== index);
    setNumberOfMachines(updatedEquipments.length); // Update the count
    return updatedEquipments;
  });
};

const list = () => (
  <Box display="flex" gap={3} sx={{
    width: 420,
    height: "100vh", 
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    overflowY: "auto", 
    
}}>
  
      <Box sx={{
          backgroundColor: "#fff",
          height: "3%",
          padding: "13px",
          margin: "30px 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "80%",
          width: "5%"
      }}>
          <IconButton onClick={() => { props.setValue(false); resetForm(); }}
          // onClick={() => {props.setValue(false); resetForm()}} 
          sx={{ color: "black" }}>
              <CloseIcon sx={{ fontSize: "1.2rem" }} />
          </IconButton>
      </Box>

      <Box sx={{ width: "100%", height:"100%",  p: 3, backgroundColor: "#fff", overflowY:"scroll" }}>
          <Typography variant="h6" sx={{ textDecoration: 'underline', fontWeight: 'bold', mb: 2 }}>
              {/* {props.index !== 0 ? "Edit Role" : "Add Industrial Equipment Master"} */}
              {/* {props.selectedEquipment ? "Edit Machinery" : "Add Machinery"} */}
              {formTitle}
          </Typography>  

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
              Location
          </Typography>

          <CustomTextField
              variant="outlined"
              value={fromValue.location}
              fullWidth
              name="location"
              placeholder="Enter Location"
              onChange={(e) => setFromValue({ ...fromValue, location: e.target.value })}
              error={!!errors.location} 
          />
          {errors.location && (
          <Typography variant="body2" color="#D34040">
            {errors.location}
            </Typography>
            )}

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
              Facility
          </Typography>
          <CustomTextField
              select
              variant="outlined"
              // value={fromValue.facility || ""}
              value = {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}
              fullWidth
              name="facility"
               placeholder="Tap to Select"
              onChange={(e) => setFromValue({ ...fromValue, facility: e.target.value })}
              error={!!errors.facility}
              InputLabelProps={{ style: { color: errors.facility ? '#D34040' : undefined } }}
          >
              <MenuItem value = {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}disabled>
                  {fromValue.facility==="" ? "Tap to Select" : fromValue.facility}
              </MenuItem>
                {facilities.map((facility) => (
                <MenuItem key={facility._id} value={facility._id}>
                {facility.facilityName}
                </MenuItem>
                ))}
          </CustomTextField>
          {errors.facility && (
              <Typography variant="body2" color="#D34040">
                  {errors.facility}
              </Typography>
          )}

       


        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
          Machinery Category
        </Typography>
        <CustomTextField
          select
          variant="outlined"
          // value={fromValue.machineCategory}
          value = {fromValue.machineCategory==="" ? "Tap to Select" : fromValue.machineCategory}
          fullWidth
          name="machineCategory"
          onChange={handleCategoryChange}
          error={!!errors.machineCategory}
        >
          <MenuItem value = {fromValue.machineCategory==="" ? "Tap to Select" : fromValue.machineCategory} disabled>
          {fromValue.machineCategory==="" ? "Tap to Select" : fromValue.machineCategory}
          </MenuItem>
          {uniqueMachineCategories.map((category) => (
            <MenuItem key={category.id} value={category.id}> 
              {category.name}
            </MenuItem>
          ))}
        </CustomTextField>
        {errors.machineCategory && (
              <Typography variant="body2" color="#D34040">
                  {errors.machineCategory}
              </Typography>
          )}

        
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
          Machine Type
      </Typography>
      <CustomTextField
          select
          variant="outlined"
          // value={fromValue.machineType}
          value = {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}
          fullWidth
          name="machineType"
          onChange={handleMachineTypeChange}
          error={!!errors.machineType}
      >
          <MenuItem value = {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}disabled>
          {fromValue.machineType==="" ? "Tap to Select" : fromValue.machineType}
          </MenuItem>
          
          {machineTypes.length === 0 ? (
              <MenuItem disabled>No Machine Types Available</MenuItem>
          ) : (
              machineTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}> 
                      {type.name}  
                  </MenuItem>
              ))
          )}
      </CustomTextField>
      {errors.machineType && (
              <Typography variant="body2" color="#D34040">
                  {errors.machineType}
              </Typography>
          )}
     
      {console.log("Machine Types in State:", machineTypes)} 
      {console.log("Selected Machine Type:", fromValue.machineType)}

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
              Machinery Name
          </Typography>
          <CustomTextField
              variant="outlined"
              value={fromValue.name}
              fullWidth
              name="name"
              placeholder="Enter name"
              onChange={(e) => setFromValue({ ...fromValue, name: e.target.value })}
              error={!!errors.name}
          />
          {errors.name && (
        <Typography variant="body2" color="#D34040">
          {errors.name}
          </Typography>
          )}

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
              Fuel Type
          </Typography>
          <CustomTextField
              select
              variant="outlined"
              // value={fromValue.fuelType}
              value = {fromValue.fuelType==="" ? "Tap to Select" : fromValue.fuelType}
              fullWidth
              name="fuelType"
              onChange={(e) => setFromValue({ ...fromValue, fuelType: e.target.value })}
              placeholder="Tap to Select"
              error={!!errors.fuelType}
              InputLabelProps={{
                  style: { color: errors.fuelType ? '#D34040' : undefined },
              }}
          >
              <MenuItem value={fromValue.fuelType==="" ? "Tap to Select" : fromValue.fuelType} disabled>
              {fromValue.fuelType==="" ? "Tap to Select" : fromValue.fuelType}
              </MenuItem>
              {fuelTypes.map((fuel, index) => (
                  <MenuItem key={index} value={fuel}>
                      {fuel}
                  </MenuItem>
              ))}
          </CustomTextField>
          {errors.fuelType && (
              <Typography variant="body2" color="#D34040">
                  {errors.fuelType}
              </Typography>
          )}

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
              No of Machinery
          </Typography>
          <CustomTextField
              variant="outlined"
              value={numberOfMachines || ''}
              placeholder="Enter number of equipment"
              fullWidth
              onChange={handleNoOfEquipmentChange}
          />
         
          {/* {alreadyMachinery && (
      <Button onClick={addEquipment} variant="contained">
        Add Equipment
      </Button>
    )} */}

          {equipments.map((equipment, index) => (
              <Box key={equipment.id} sx={{ mt: 2 }}>
                  {/* Equipment Title with Expand/Collapse Button */}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                          Machinery {index + 1}
                      </Typography>
                      <Box>
                      <IconButton onClick={() => toggleEquipment(index)}>
                          {openMachines[index] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </IconButton>
                      {/* <button onClick={() => removeEquipment(index)}>Remove</button> */}
                       {/* Delete button */}
                <IconButton
                  onClick={() => removeEquipment(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
                </Box>
                  </Box>

                  {/* Collapse for Equipment Fields */}
                  <Collapse in={openMachines[index]} timeout="auto" unmountOnExit>
                      <Box sx={{ mt: 1 }}>
                          {/* Unique ID */}
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                              Unique ID
                          </Typography>
                          <CustomTextField
                              variant="outlined"
                              // value={equipment.uniqueID}
                              value={equipments[index].uniqueID}
                              placeholder="Enter ID"
                              fullWidth
                              onChange={(e) => handleFieldChange(index, 'uniqueID', e.target.value)}
                              error={!!errors[`uniqueID_${index}`]}  
                          />
                           {errors[`uniqueID_${index}`] && (
                          <Typography variant="body2" color="#D34040">
                          {errors[`uniqueID_${index}`]}
                          </Typography>
                        )}

                          {/* Brand */}
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                              Brand
                          </Typography>
                          <CustomTextField
                              variant="outlined"
                              value={equipment.brand}
                              placeholder="Enter Brand"
                              fullWidth
                              onChange={(e) => handleFieldChange(index, 'brand', e.target.value)}
                              error={!!errors[`brand_${index}`]}
                          />
                          {errors[`brand_${index}`] && (
                          <Typography variant="body2" color="#D34040">
                            {errors[`brand_${index}`]}
                          </Typography>
                        )}

                          {/* Model */}
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                              Model
                          </Typography>
                          <CustomTextField
                              variant="outlined"
                              value={equipment.model}
                              placeholder="Enter Model"
                              fullWidth
                              onChange={(e) => handleFieldChange(index, 'model', e.target.value)}
                              error={!!errors[`model_${index}`]}
                          />
                          {errors[`model_${index}`] && (
                          <Typography variant="body2" color="#D34040">
                            {errors[`model_${index}`]}
                          </Typography>
                        )}

                          {/* Rated Fuel Consumption */}
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                              Rated Fuel Consumption
                          </Typography>
                          <CustomTextField
                              variant="outlined"
                              value={equipments[index].ratedFuelConsumption}
                              placeholder="Amount"
                              fullWidth
                              onChange={(e) => handleFieldChange(index, 'ratedFuelConsumption', e.target.value)}
                              error={!!errors[`ratedFuelConsumption_${index}`]}
                          />
                          {errors[`ratedFuelConsumption_${index}`] && (
                          <Typography variant="body2" color="#D34040">
                            {errors[`ratedFuelConsumption_${index}`]}
                          </Typography>
                        )}


                          {/* Unit of Fuel Consumption */}
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                              Unit of Fuel Consumption
                          </Typography>
                          <CustomTextField
                              variant="outlined"
                              value={equipment.unitOfFuelConsumption}
                              placeholder="ltr/kg per hour"
                              fullWidth
                              onChange={(e) => handleFieldChange(index, 'unitOfFuelConsumption', e.target.value)}
                              error={!!errors[`unitOfFuelConsumption_${index}`]}
                          />
                          {errors[`unitOfFuelConsumption_${index}`] && (
                        <Typography variant="body2" color="#D34040">
                          {errors[`unitOfFuelConsumption_${index}`]}
                        </Typography>
                      )}
                      </Box>
                  </Collapse>
                  
              </Box>
              
          ))}
          {alreadyMachinery && (
      <Button sx={{mt:2, backgroundColor: "#0a3f73"}}
      onClick={addEquipment} variant="contained">
        Add Equipment
      </Button>
    )}

          <Box>
              <Button variant="contained" sx={{ backgroundColor: "#0a3f73", textTransform: "none", marginTop: "10%" }} onClick={handleSubmit}>
                  Submit
              </Button>
          </Box>
      </Box>
  </Box>
);

    return (
        <div>
              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { 
                   backgroundColor: "transparent", 
                   boxShadow: 'none', 
                 }
             }}
                anchor="right"
                open={props.value}
                onClose={()=>{props.setValue(false); resetForm()}}
              >
                {list()}
              </Drawer>
              
      
      
        </div>
      );
}

export default AddMachinery;




//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  

